import { connect } from 'react-redux';
import utils from 'utils';
import { MODAL } from 'constants';
import {
  selectedExtras,
  selectedBundleWithExtrasSelector,
  extrasWithoutBundlesSelector,
  numberOfExtrasSelector,
  vehicleRateSelector,
  oppositeVehicleRateSelector,
  breakpointSelector,
} from 'selectors';
import { isDestinationCurrencySelected } from 'selectors/vehicleSelectSelectors';
import {
  selectedCarClassCoupons,
  isSkipTheCounterCompletedReservationSelector,
} from 'selectors/reservationFlowSelectors';
import { updateVehicleRatesSelectors } from 'selectors/currencyToggleSelectors';
import { prepayFlopCurrentSession } from 'actions/reservation/prepayFlopCurrentSession';
import { openModal } from 'actions/modal/openModal';
import { openModalWithProps } from 'actions/modal/openModalWithProps';
import ProgressBarTotal from './ProgressBarTotal';

const mapStateToProps = (state) => {
  let modifyVehicleRates = vehicleRateSelector(state);
  let oppositeVehicleRate = oppositeVehicleRateSelector(state);
  const isDestinationCurrency = isDestinationCurrencySelected(state);
  if (isDestinationCurrency) {
    modifyVehicleRates = modifyVehicleRates && updateVehicleRatesSelectors(modifyVehicleRates.toJS());
    oppositeVehicleRate = oppositeVehicleRate && updateVehicleRatesSelectors(oppositeVehicleRate.toJS());
  }

  return {
    reservation: state.getIn(['gmi', 'session', 'gbo', 'reservation']),
    vehicleRate: modifyVehicleRates,
    oppositeVehicleRate,
    extras: selectedExtras(state),
    selectedBundleWithExtras: selectedBundleWithExtrasSelector(state),
    extrasWithoutBundles: extrasWithoutBundlesSelector(state),
    numberOfExtrasSelected: numberOfExtrasSelector(state),
    coupons: selectedCarClassCoupons(state),
    breakpoint: breakpointSelector(state),
    isSkipTheCounterCompleted: isSkipTheCounterCompletedReservationSelector(state),
  };
};

const mapDispatchToProps = {
  showViewDetailsModal: () => openModal(MODAL.RENTAL_DETAILS_TAXES_FEES_MODAL),
  showLearnMoreModal: () => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL),
  showCouponDoesNotApplyModal: (continueWithoutCoupon) =>
    openModalWithProps(MODAL.COUPON_DOES_NOT_APPLY, { continueWithoutCoupon }),
  prepayFlopCurrentSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ProgressBarTotal));
