import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MODAL from 'constants/modals';
import RESERVATIONS from 'constants/reservations';
import COUNTRY_CODE from 'constants/countryCode';
import useActions from 'hooks/useActions';
import utils from 'utils';
import { getMaskedString } from 'utils/profile';
import {
  onlineCheckInProfileSelector,
  onlineCheckInStatusSelector,
  isLastStepSelector,
  isUnauthPartialProfileSelector,
  isResFirstNameDifferentThanOciProfileSelector,
  partialProfileSelector,
} from 'selectors/checkinSelectors';
import { isLoyaltyReservationSelector, reservationDriverInfoSelector } from 'selectors/reservationSelectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { authenticatedSelector, isNonLoyaltyProfileSelector } from 'selectors/profileSelectors';
import { updateProfile } from 'actions/checkin';
import useCountryFieldsConfig from 'hooks/useCountryFieldsConfig';
import useOCIProfileInitialValues from 'hooks/useOCIProfileInitialValues';
import ANALYTICS from 'constants/analytics';
import CHECKIN from 'constants/checkin';
import Form from 'components/Form/Form';
import SignInModal from 'components/modals/SignInModal';
import CheckInFirstNameConflictModal from 'components/modals/CheckInFirstNameConflictModal';
import PartialProfileFirstNameConflictModal from 'components/modals/PartialProfileFirstNameConflictModal';
import { openModal } from 'actions/modal/openModal';
import CheckInTemplate from '../CheckInTemplate';
import CheckInDriverLicenseForm from './CheckInDriverLicenseForm';
import CheckInContactInformationForm from './CheckInContactInformationForm';
import CheckInLoginCTA from './CheckInLoginCTA';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * CheckInProfile
 * Form that handles Profile info display + update during the Check-in process
 *
 * @param {object} props - React Props
 *
 * @return {JSX} CheckInProfile jsx component
 */
const CheckInProfile = () => {
  const [submittedForm, setSubmittedForm] = useState(false);
  const isFetchingSession = useSelector((state) => isFetchingEndpointSelector(state, { endpoint: 'session/current' }));
  const isAuth = useSelector(authenticatedSelector);
  const profile = useSelector(onlineCheckInProfileSelector);
  const driverInfo = useSelector(reservationDriverInfoSelector);
  const { driverProfileComplete, contactProfileComplete, ociComplete } = useSelector(onlineCheckInStatusSelector);
  const isLoyaltyReservation = useSelector(isLoyaltyReservationSelector);
  const isLastStep = useSelector(isLastStepSelector);
  const isResFirstNameDifferentThanOciProfile = useSelector(isResFirstNameDifferentThanOciProfileSelector);
  const updateProfileAction = useActions(updateProfile);
  const openFirstNameConflictModal = useActions(() => openModal(MODAL.CHECKIN_FIRST_NAME_CONFLICT_MODAL));
  const openFirstNameConflictSignInModal = useActions(() => openModal(MODAL.PARTIAL_PROFILE_FIRST_NAME_CONFLICT_MODAL));
  const openSignInModal = useActions(() => openModal(MODAL.SIGN_IN_MODAL));
  const isClearingProfile = useSelector((state) =>
    isFetchingEndpointSelector(state, { endpoint: 'reservations/oci/clearProfile' })
  );
  const history = useHistory();

  const driversLicenseCountryCode = profile.license_profile.country_code || domainCountryCode;
  const { countryConfig } = useCountryFieldsConfig(driversLicenseCountryCode);

  const { shouldShowBothIssueAndExpirationDate, shouldShowIssueDate, shouldShowExpirationDate } = countryConfig;

  const [isEditingLicenseInfo, setEditingLicenseInfo] = useState(!driverProfileComplete);
  const [isEditingContactInfo, setEditingContactInfo] = useState(!contactProfileComplete);

  const isUnauthPartialProfile = useSelector(isUnauthPartialProfileSelector);

  const foundCompleteProfile = driverProfileComplete && contactProfileComplete;
  const isEditing = isEditingLicenseInfo || isEditingContactInfo;

  const isUnauthPartialProfileFound = useSelector(partialProfileSelector);

  const isNonLoyaltyProfileFound = useSelector(isNonLoyaltyProfileSelector);

  // We need this effect because the component renders before the profile is properly in place
  useEffect(() => {
    setEditingLicenseInfo(!driverProfileComplete);
    setEditingContactInfo(!contactProfileComplete);
  }, [driverProfileComplete, contactProfileComplete]);

  // Should show first name conflict modal if there is an unauth complete profile found
  useEffect(() => {
    if (!isAuth && isResFirstNameDifferentThanOciProfile && !isClearingProfile && foundCompleteProfile) {
      openFirstNameConflictModal();
    }
  }, [isAuth, isResFirstNameDifferentThanOciProfile, foundCompleteProfile]);

  // Should show sign in modal if there is an unauth partial profile found
  useEffect(() => {
    const { loyalty_data } = profile;
    if (isUnauthPartialProfile && !isFetchingSession && !submittedForm) {
      // Check if first name on the reservation is different than profile first name
      if (isResFirstNameDifferentThanOciProfile) {
        openFirstNameConflictSignInModal();
      } else if (loyalty_data && loyalty_data.loyalty_program !== 'Non-Loyalty') {
        openSignInModal();
      }
    }
  }, [isAuth, isUnauthPartialProfile, isFetchingSession, isResFirstNameDifferentThanOciProfile, submittedForm]);

  const toggleEditingLicenseInfo = () => setEditingLicenseInfo(!isEditingLicenseInfo);
  const toggleEditingContactInfo = () => setEditingContactInfo(!isEditingContactInfo);

  const onSubmit = (formData) => {
    const { license_profile } = profile;
    const newFormData = { ...formData };

    // .CO.UK only - In the OCI flow when a NL driver profile is found during Driver Lookup,
    // sending the 'issuing authority' value as masked in the Update Profile call to GBO.
    if (
      newFormData?.license_info?.issuing_authority?.length &&
      license_profile?.country_code === COUNTRY_CODE.GB &&
      isNonLoyaltyProfileFound &&
      isUnauthPartialProfileFound
    ) {
      newFormData.license_info.issuing_authority = getMaskedString(newFormData.license_info.issuing_authority);
    }

    // handling Phone number if user adds country code, Checks for country of residence, gets dialing code, phone number format,
    // if dialing code matches strip them off and update phoe number without knowing user
    const phoneData = utils.profile.contactInfoPayloadParser(formData);
    newFormData.contact_info.phone_set = phoneData.phones;
    const payload = utils.checkin.parseProfileUpdatePayload(newFormData, isEditingLicenseInfo, isEditingContactInfo);
    updateProfileAction(payload, { isEditingLicenseInfo, isEditingContactInfo });
  };

  /*  not passing driverInfo.email_address as secondary email when found NL profile, 
      profile?.contact_info?.email is masked or no email address is present  */
  const isNonLoyaltyDataFound = profile?.loyalty_data?.loyalty_program === RESERVATIONS.PROFILE.LOYALTY_PROGRAM;
  const emailAddressForNLProfile =
    isNonLoyaltyDataFound &&
    (utils.fieldValidation.isMasked(profile?.contact_profile?.email) || profile?.contact_profile?.email.length) <= 0
      ? ''
      : driverInfo?.email_address;

  const initialValues = useOCIProfileInitialValues(profile, emailAddressForNLProfile, driverInfo?.phone?.phone_number);

  const goToDriverLookup = () => history.push(CHECKIN.CHECKIN_PATH_CONFIG.driverLookup[0]);

  let nextButtonLabel = utils.i18n('common_next');
  if (isLoyaltyReservation && ociComplete) {
    nextButtonLabel = utils.i18n('common_save');
  } else if (isLastStep || !isEditing || foundCompleteProfile) {
    nextButtonLabel = utils.i18n('common_confirm');
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(values) =>
          utils.date.validateIssueAndExpiration(
            values,
            isEditingLicenseInfo && shouldShowBothIssueAndExpirationDate,
            isEditingLicenseInfo && shouldShowIssueDate,
            isEditingLicenseInfo && shouldShowExpirationDate
          )
        }
        subscription={{ submitFailed: true, values: true }}
      >
        {({ form, handleSubmit }) => (
          <CheckInTemplate
            nextButton={{
              label: nextButtonLabel,
              action: () => {
                isLastStep && setSubmittedForm(true);
                handleSubmit();
              },
              dtmTrack: `check_in|renter_info|${ANALYTICS.CONFIRM}`,
            }}
          >
            <form onSubmit={handleSubmit} className='check-in-flow__form component-theme--light' noValidate>
              <CheckInDriverLicenseForm
                form={form}
                onEditLicense={!driverProfileComplete ? goToDriverLookup : toggleEditingLicenseInfo}
                hideEdit={isAuth && !driverProfileComplete}
              />
              <CheckInContactInformationForm
                form={form}
                initialValues={initialValues}
                editContactInfo={toggleEditingContactInfo}
              />
              <CheckInLoginCTA />
            </form>
          </CheckInTemplate>
        )}
      </Form>
      <SignInModal isPartialProfileFound={isUnauthPartialProfile} />
      <PartialProfileFirstNameConflictModal />
      <CheckInFirstNameConflictModal />
    </>
  );
};

export default CheckInProfile;
