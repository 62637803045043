import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import { analyticsClearErrors } from 'utils/analytics';
import Storage from 'utils/storageManager';
import ANALYTICS from 'constants/analytics';
import THEMES from 'constants/themes';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import GMI_SERVICE_PATHS from 'constants/gmiServicePaths';
import { login } from 'actions/authentication';
import { SESSION_STORAGE } from 'constants/session';
import useActions from 'hooks/useActions';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { shouldAssociateProfileSelector } from 'selectors/authenticationSelectors';
import Form from 'components/Form/Form';
import TextField from 'components/Form/TextField';
import PasswordField from 'components/Form/PasswordField';
import Checkbox from 'components/Form/Checkbox';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import ServiceErrors from 'components/ServiceErrors';

/**
 * SignIn Description
 *
 * standalone authentication form component for a user to enter their information
 * username/email
 * password
 * stay signed-in
 *
 * @param {object} props - React Props
 * @param {function} props.onSuccess - Function called after a successful login.
 *  - If ommited, the default action is to redirect the user to the Auth Home Page (Members page)
 * @param {function} props.onCancel - If provided, will display a "Cancel" button with this function as its' callback.
 *  i.e. used on the SignInModal to show a button set instead of only a single "Sign In" button
 *
 * @return {JSX}
 */
const SignIn = ({ onSuccess, onCancel, unAuthenticatedFlyout = false, stashAnalytics = true }) => {
  const { redirectBack } = utils.url.queryObject();
  const [submitted, setSubmitted] = useState(false);
  const loginAction = useActions(login);
  const isFetching = useSelector((state) =>
    isFetchingEndpointSelector(state, { endpoint: 'profile/login', exactMatch: true })
  );
  // Passed to the loginAction in order to associate a profile to a reservation
  const associate_profile = useSelector(shouldAssociateProfileSelector);

  useEffect(() => {
    submitted && !isFetching && onSuccess?.();
  }, [submitted, isFetching]);

  const onSubmit = (values) => {
    const { username, password, remember_credentials } = values;
    analyticsClearErrors();
    unAuthenticatedFlyout &&
      utils.analytics.interaction(
        'menu',
        'nav',
        utils.i18n('signin_flyout_toggle').toLowerCase(),
        utils.i18n('signin_flyout_toggle').toLowerCase()
      );

    /* Checks for specific windowObjectKeys  for navigating to specific after login.
    Else it will by default redirect to home page/ go back to previous page. */
    const goToSpecificPagePath = localStorage.getItem(utils.config.goToSpecificPagePath);

    // make the login call and then redirect to the authenticated homepage
    return loginAction({
      username,
      password,
      remember_credentials,
      associate_profile,
    }).then((res) => {
      // if the response contains no error messages, redirect
      // error messages will already be printed inside the component at ServiceErrors
      if (!res?.messages) {
        // Analytics
        utils.analytics.interaction(
          ANALYTICS.LOGIN,
          ANALYTICS.LOGIN_SUCCESSFUL,
          remember_credentials ? ANALYTICS.LOGIN_REMEMBER_ME : ANALYTICS.LOGIN_ORIGINAL,
          null,
          stashAnalytics // this sets the interaction to be stashed.
        );
        Storage.SessionStorage.set(SESSION_STORAGE.SHOW_EMAIL_UPDATE_MODAL, true);
        if (onSuccess) {
          setSubmitted(true);
        } else if (goToSpecificPagePath) {
          // Currently checking only viewModifyCancel details / Confirmation
          window.location.assign(utils.config.getRedirectUrl(goToSpecificPagePath));
        } else if (redirectBack === 'true') {
          window.history.back();
        } else {
          window.location.assign(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.AUTH_HOME_PAGE));
        }
      }
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <div className='signin'>
          <ServiceErrors statePath={GMI_SERVICE_PATHS.PROFILE_LOGIN} />
          <form onSubmit={handleSubmit} className='signin__form' noValidate>
            <TextField
              name='username'
              label={utils.i18n('signin_email_or_username')}
              fill
              required
              disabled={isFetching}
              autoFocus
              autoComplete='username'
            />
            <PasswordField
              name='password'
              label={utils.i18n('field_password_label')}
              fill
              required
              disabled={isFetching}
              autoComplete='current-password'
            />
            <Checkbox
              label={utils.i18n('signin_stay_signed_in')}
              name='remember_credentials'
              className='global-gateway-modal__checkbox'
              theme={THEMES.LIGHT}
              disabled={isFetching}
            />
            {onCancel ? (
              <div className='signin__button-row'>
                <Button className='signin__button' theme={THEMES.DARK} ghosted loading={isFetching} onClick={onCancel}>
                  {utils.i18n('common_cancel')}
                </Button>
                <Button className='signin__button' type='submit' loading={isFetching}>
                  {utils.i18n('signin_submit')}
                </Button>
              </div>
            ) : (
              <Button className='signin__button' type='submit' loading={isFetching}>
                {utils.i18n('signin_submit')}
              </Button>
            )}
          </form>
          <p>
            {utils.i18n(
              'signin_forgot_username_or_password',
              [
                <Anchor
                  className='signin__link'
                  key={0}
                  href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.FORGOT_USERNAME_PAGE)}
                >
                  {utils.i18n('signin_forgot_username_text')}
                </Anchor>,
                <Anchor
                  className='signin__link'
                  key={1}
                  href={utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.FORGOT_PASSWORD_PAGE)}
                >
                  {utils.i18n('signin_forgot_password_text')}
                </Anchor>,
              ],
              { jsx: true }
            )}
          </p>
        </div>
      )}
    </Form>
  );
};

SignIn.defaultProps = {};

SignIn.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SignIn;
