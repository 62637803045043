import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from '../../helpers/spreadModelProps';
import LocationSearch from './LocationSearchContainer';

const LocationSearchEditableConfig = {
  emptyLabel: 'Location Search Component',
  isEmpty: (props) => !props,
};
export default MapTo('aweb/components/content/location-finder')(
  spreadModelProps(LocationSearch),
  LocationSearchEditableConfig
);
