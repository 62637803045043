import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';
import Breadcrumb from './Breadcrumb';

const BreadcrumbEditableConfig = {
  emptyLabel: 'Breadcrumb',
  isEmpty: (props) => !props || !props.title,
};

export default MapTo('aweb/components/content/breadcrumb')(spreadModelProps(Breadcrumb), BreadcrumbEditableConfig);
