import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { isDuplicateEmailError } from 'utils/profile';
import { analyticsClearErrors } from 'utils/analytics';
import { Field } from 'react-final-form';
import Form from 'components/Form/Form';
import TextField from 'components/Form/TextField';
import CheckboxField from 'components/Form/Checkbox';
import PasswordField from 'components/Form/PasswordField';
import PasswordRequirements, { customPasswordValidation } from 'components/Form/PasswordRequirements';
import Modal from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import Anchor from 'components/Anchor';
import Button from 'components/Button';
import ToolTip from 'components/ToolTip';
import PhoneNumberField from 'components/Form/PhoneNumberField';
import ServiceErrors from 'components/ServiceErrors';
import { ANALYTICS, MODAL_THEMES, MODAL_BUTTON_TYPES, THEMES, WINDOW_OBJECT_KEYS, PROFILE } from 'constants';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;
const { PREFERENCE_SOURCE_CODE } = PROFILE;

/**
 * EditAccountModal
 * in this modal the user can update their username, email address, special offers subscription, and password
 *
 * @param {object} props - React Props
 * @param {function} props.cleanMessages - clears the redux gmi error messaging
 * @param {function} props.closeModal
 * @param {boolean} props.isOpen - whether or not the modal is visible
 * @param {string} props.modalKey - string identifier for the modal
 * @param {function} props.modifyProfile - makes the request with new form values
 * @param {object} props.profileData
 * @param {string} props.supportPhone - country specific support number
 * @return {JSX}
 */
const initialState = {
  isPasswordValid: false,
  showModifyPassword: false,
};

class EditAccountModal extends Component {
  state = { ...initialState };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      // this.props.cleanMessages(); // clears gma service errors from redux
      this.resetState();
    }
  }

  handleSave = (values) => {
    analyticsClearErrors();
    // build a payload object to pass to our request
    // if the payload object is empty, we don't make the request
    const { profileData, openEmailInUseModal } = this.props;
    const { email, password, special_offers, user_name, phones } = values;

    const payload = {};
    const emailChanged = email !== profileData.email;
    const userNameChanged = user_name !== profileData.user_name;
    const phoneNumberChanged = phones?.[0]?.phone_number !== profileData.phones?.[0]?.phone_number;

    if (emailChanged || userNameChanged || phoneNumberChanged) {
      // if original email and original user_name were the same, keep them the same
      // when both values are the same, the user_name field does not even show to the user
      // only a legacy user will potentially see both fields
      const keepEmailUsernameEqual = profileData.email === profileData.user_name;

      if (keepEmailUsernameEqual) {
        payload.contact = { email };
        payload.user_name = email;
      } else {
        if (emailChanged) {
          payload.contact = { email };
        }
        if (userNameChanged) {
          payload.user_name = user_name;
        }
      }
      if (phoneNumberChanged) {
        const phoneData = utils.profile.contactInfoPayloadParser(values);
        payload.contact = {
          phones: phoneData.phones,
        };
      }
    }

    // indicates that the user is changing their password
    if (password) {
      payload.password = {
        new_password: password,
      };
    }

    // indicates that the user has changed their email preference for special offers
    // emailChange - if user updated the email address , we should send previously selected preferences (updating only if email is changed and not the email perferences)
    if (
      special_offers !== profileData.special_offers ||
      (profileData?.special_offers && special_offers === profileData.special_offers && emailChanged)
    ) {
      payload.preference = { email_preference: { special_offers } };
      // if user does select to receive special offers, we should also send the preference source code (AWR-2618)
      if (special_offers) {
        payload.preference.source_code = PREFERENCE_SOURCE_CODE.AI_PROFILE;
      }
    }

    if (!utils.gmi.isObjectEmpty(payload)) {
      this.props.modifyProfile(payload).then((res) => {
        if (res.messages) {
          const isEmailAlreadyInUse = res.messages.some((msg) => isDuplicateEmailError(msg.code));
          if (isEmailAlreadyInUse) {
            this.props.closeEditAccountModal({ skipAnalytics: true });
            openEmailInUseModal();
          }
        } else {
          this.handleClose(); // on success close modal
          this.handleCleanMessages(); // clears gma service errors from redux
        }
      });
    }
  };

  handleClose = () => {
    this.props.closeEditAccountModal({ analyticsValue: ANALYTICS.SAVE });
    this.props.cleanMessages(); // clears gma service errors from redux
  };

  resetState = () => {
    this.setState({ ...initialState });
  };

  checkboxLabel = (fieldState) => {
    let { value } = fieldState || {};

    // this addresses cases where fieldState is not yet defined when you open the modal but the initial value is set to true
    if (!utils.gmi.types(value).isDefined) {
      value = this.props.profileData?.special_offers;
    }

    return (
      <p>
        <span>{utils.i18n('edit_account_modal_label_checkbox')}</span>
        <span className='edit-account-modal__sign-up__success'>
          {' '}
          {utils.i18n(
            value ? 'edit_account_modal_label_checkbox_selected' : 'edit_account_modal_label_checkbox_unselected'
          )}
        </span>
      </p>
    );
  };

  handleToggleShowModifyPassword = (form) => () => {
    this.setState(
      ({ showModifyPassword }) => ({
        showModifyPassword: !showModifyPassword,
      }),
      () => form.change('password', '')
    ); // clear the password value on toggle
  };

  setPasswordValid = (bool) => {
    this.setState({ isPasswordValid: bool });
  };

  handleCleanMessages = () => {
    this.props.cleanMessages(); // clears gma service errors from redux
    analyticsClearErrors();
  };

  render() {
    const { modalKey, profileData, supportPhone, hasServiceError } = this.props;
    const { first_name, last_name, email, special_offers, user_name, phones, email_update_required } = profileData;
    const { isPasswordValid, showModifyPassword } = this.state;
    const showUserName = user_name && !email_update_required && user_name !== email;

    if (email_update_required) {
      profileData.email = '';
    }

    return (
      <Modal
        modalKey={modalKey}
        theme={MODAL_THEMES.WHITE}
        header={utils.i18n('edit_account_modal_title')}
        className='edit-account-modal'
        customActionOnClose={this.handleCleanMessages}
      >
        <Form
          onSubmit={this.handleSave}
          initialValues={profileData}
          validate={showModifyPassword ? customPasswordValidation(isPasswordValid) : null}
          subscription={{ submitting: true, pristine: true, submitFailed: true }}
        >
          {({ form, handleSubmit }) => (
            <form className='edit-account-modal__form' onSubmit={handleSubmit}>
              <ServiceErrors />
              <div className='edit-account-modal__section-header'>
                <span className='edit-account-modal__required-note'>{utils.i18n('header_note_required_field')}</span>
              </div>
              <div>
                <p className='edit-account-modal__display-label'>
                  {utils.i18n('edit_account_modal_label_name')}
                  <ToolTip
                    placement='top-start'
                    triggerTitle={utils.i18n('discount_code_tooltip_trigger_label')}
                    analyticsKey='name'
                  >
                    <label>
                      {utils.i18n('edit_account_modal_label_name_tooltip', [<strong key='0'>{supportPhone}</strong>], {
                        jsx: true,
                      })}
                    </label>
                  </ToolTip>
                </p>

                <p>{`${first_name} ${last_name}`}</p>
              </div>
              {showUserName && (
                <div className='edit-account-modal__field-row'>
                  <TextField
                    initialValueButton={user_name}
                    name='user_name'
                    label={utils.i18n('edit_account_modal_label_username')}
                    required
                  />
                </div>
              )}
              <div className='edit-account-modal__field-row'>
                <TextField
                  initialValueButton={email_update_required ? '' : email}
                  name='email'
                  id='email'
                  label={utils.i18n('edit_account_modal_label_email')}
                  validations={[utils.fieldValidation.email]}
                  required
                  forceError={hasServiceError}
                />
                <PhoneNumberField
                  initialValueButton={phones?.[0]?.phone_number}
                  name='phones[0].phone_number'
                  label={utils.i18n('common_phone_number')}
                  fill
                  required
                  validations={[utils.fieldValidation.phone, utils.fieldValidation.checkPhoneCode]}
                  autoComplete='phone'
                />
              </div>

              <div className='edit-account-modal__field-row'>
                <CheckboxField
                  name='special_offers'
                  id='special_offers'
                  label={this.checkboxLabel(form.getFieldState('special_offers'))}
                  theme={THEMES.LIGHT}
                  initialValue={special_offers}
                />
              </div>
              <span className='edit-account-modal__sign-up__legal'>
                {utils.i18n(
                  'edit_account_modal_label_checkbox_details',
                  {
                    0: (
                      <Anchor href={utils.config.getUrl(WINDOW_OBJECT_KEYS.PRIVACY_POLICY)} key={0} isExternal>
                        {utils.i18n('edit_account_modal_privacy_policy')}
                      </Anchor>
                    ),
                    1: (
                      <Anchor href={utils.config.getUrl(WINDOW_OBJECT_KEYS.COOKIE_POLICY)} key={1} isExternal>
                        {utils.i18n('edit_account_modal_cookie_policy')}
                      </Anchor>
                    ),
                  },
                  { jsx: true }
                )}
              </span>

              {showModifyPassword ? (
                <>
                  <p className='edit-account-modal__display-label'>
                    {utils.i18n('edit_account_modal_label_password_modify')}
                    <Button
                      link
                      linkText
                      className='edit-account-modal__password-cancel'
                      onClick={this.handleToggleShowModifyPassword(form)}
                      data-dtm-track={utils.analytics.dtm(
                        ANALYTICS.PROFILE,
                        'account_information',
                        'cancel_modify_password'
                      )}
                    >
                      {utils.i18n('edit_account_modal_label_password_cancel')}
                    </Button>
                  </p>

                  {/* hidden fields exist so that their values are in the form state and therefore visible to PasswordRequirements  */}
                  <Field component='input' type='hidden' name='first_name' id='first_name' />
                  <Field component='input' type='hidden' name='last_name' id='last_name' />

                  {/* there really is no way to verify the current password */}
                  {/* <div className='edit-account-modal__field-row'><PasswordField name='currentPassword' label={utils.i18n('edit_account_modal_current_password')} required /></div> */}
                  <div className='edit-account-modal__field-row'>
                    <PasswordField
                      name='password'
                      label={utils.i18n('edit_account_modal_new_password')}
                      required
                      autoComplete='new-password'
                    />
                  </div>

                  {/* do not compare email here, the email is masked in redux */}
                  <PasswordRequirements
                    password='password'
                    firstName='first_name'
                    lastName='last_name'
                    setPasswordValid={this.setPasswordValid}
                  />
                </>
              ) : (
                <>
                  <p className='edit-account-modal__display-label'>{utils.i18n('edit_account_modal_label_password')}</p>

                  <Button
                    link
                    linkText
                    onClick={this.handleToggleShowModifyPassword(form)}
                    data-dtm-track={utils.analytics.dtm(ANALYTICS.PROFILE, 'account_information', 'modify_password')}
                  >
                    {utils.i18n('edit_account_modal_label_password_modify')}
                  </Button>
                </>
              )}
              <ModalFooter
                buttons={[
                  {
                    type: DECLINE,
                    label: utils.i18n('edit_account_modal_cancel'),
                    analyticsValue: ANALYTICS.CANCEL,
                    handler: () => {
                      this.handleCleanMessages();
                      this.props.closeEditAccountModal();
                    },
                  },
                  {
                    type: ACCEPT,
                    label: utils.i18n('edit_account_modal_save'),
                    handler: handleSubmit,
                  },
                ]}
              />
            </form>
          )}
        </Form>
      </Modal>
    );
  }
}

EditAccountModal.propTypes = {
  cleanMessages: PropTypes.func,
  closeEditAccountModal: PropTypes.func,
  isOpen: PropTypes.bool,
  modalKey: PropTypes.string,
  modifyProfile: PropTypes.func,
  profileData: PropTypes.object,
  supportPhone: PropTypes.string,
};

export default EditAccountModal;
