import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import cn from 'classnames';
import { analyticsInteraction } from 'utils/analytics';
import Storage from 'utils/storageManager';
import { ANALYTICS } from 'constants';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import TripCardAcceleratedCheckin from 'components/TripCard/TripCardAcceleratedCheckin';
import Title from 'components/Title';
import carKeys from 'images/car-keys.svg';
import VisHiddenText from 'components/VisHiddenText';
import THEMES from 'constants/themes';
import { SESSION_STORAGE } from 'constants/session';

/**
 * TripCard Component - Responsible to show a card containing the basic information for a trip
 * This component can be used both in current and upcoming sessions
 *
 * @param {object} props - React Props
 * @param {boolean} props.currentTripCard - true if this tripCard component is being loaded on current Trips loop
 * @param {boolean} props.showAfterHoursCta - If true, user will be able to see after hours info CTA
 * @param {string} props.icon - Icon displayed at left/top side of the card
 * @param {boolean} props.isDetailsPage - true if this tripCard is used in ConfirmationHeader component
 * @param {string} props.remainingDaysCopy - Copy that should be used for remaining days. This is a result of formatRemainingDays function, which returns a i18n key with all the rules already applied
 * @param {string} props.firstName - Driver's first name
 * @param {string} props.lastName - Driver's last name
 * @param {string} props.confirmation_number - Confirmation number of the trip, if this is empty, check Agreement number
 * @param {string} props.agreement_number - Agreement number of the trip, if this is empty, check Confirmation number
 * @param {string} props.location - Location that should show in the card. Pickup location for upcoming trips and Return location for current trips
 * @param {array} props.locationWayfindings - Location Wayfindings for STC pickup instructions
 * @param {string} props.dateOfRentInteraction - Already parsed Pickup Date or Return Date to use the following pattern (Thur, Dec 5, 9:00am)
 * @param {string} props.ctaLabel - Label for CTA present in the bottom of component
 * @param {object} props.vehicleDetails - Vehicle details for current trip
 * @param {object} props.carClassImage - Car class image for upcoming trip
 * @param {object} props.carClassImageAlt - Car class image alternative text for upcoming trip
 * @param {object} props.breakpoint - Identifies mobile/tablet/desktop breakpoint status
 * @param {object} props.return_location - return address to be used on google maps location and return after hours modal
 * @param {function} props.ctaAction - Action for CTA present in the bottom of component
 * @param {Boolean} props.userAlreadyCheckedIn - if true, means user already checked in for that particular trip - GBO reservation_sub_status === CHECKED_IN
 * @param {Boolean} props.skipTheCounterCompleted - if true, means user already can print the ticket for skip the counter - GBO reservation_sub_status === COUNTER_BYPASS
 * @param {Boolean} props.userCanActiveSkipTheCounter - if true, means the reservation is eligible to skip the counter and go to it's flow - GBO counter_bypass_eligible === true
 * @param {function} props.resolveBranchURL - Will get the branch location redirect based on location ID
 * @param {function} props.openAfterHoursModal - func to open after hours modal with return address
 * @param {function} props.openReturnDirectionsModal - func to open return directions modal with return address
 * @param {function} props.afterHoursPolicies - policies to be shown on after hours intructions modal
 * @param {function} props.openExtendRentalModal - function to open modal with a phone number that user can call in case wants to extend the rental
 * @param {function} props.redirectToLocationDetailsPage - function to redirect user to the location page
 * @param {function} props.openLearnMoreAboutRoadsideAssistance - action dispatcher that will open a modal with road side assistance info
 * @param {boolean} props.isPickupStatusAddedAutomatically - Is OCI auto activated by profile data?
 *
 * @return {JSX} Returns TripCard component
 */

const TripCard = ({
  isDetailsPage,
  icon,
  currentTripCard,
  showAfterHoursCta,
  remainingDaysCopy,
  firstName,
  lastName,
  confirmation_number,
  agreement_number,
  location,
  locationWayfindings,
  dateOfRentInteraction,
  vehicleDetails,
  carClassImage,
  carClassImageAlt,
  ctaLabel,
  ctaAction,
  breakpoint,
  userAlreadyCheckedIn,
  skipTheCounterCompleted,
  userCanActiveSkipTheCounter,
  return_location,
  openAfterHoursModal,
  openReturnDirectionsModal,
  redirectToLocationDetailsPage,
  openExtendRentalModal,
  afterHoursPolicies,
  openLearnMoreAboutRoadsideAssistance,
  isPickupStatusAddedAutomatically,
  within24hrsReturnTime,
  ticketNumber,
  retrieveAndCheckEligibility,
  rentalReturnConfirmed,
  rentalRetrieveTicketNumber,
  currentRentalDetails,
  hasConfirmModify,
  rentalRetrieveAndConfirmAction,
  index,
}) => {
  // If it's used on current trips page: current_trips|current_rental|location_details
  // If it's used on my trips page AND it's current trip: my_trips|current_trips|location_details
  // If its used on my trips page AND it's upcoming trip: my_trips|upcoming_trips|location_details
  const dtmPageName = isDetailsPage ? ANALYTICS.CURRENT_TRIPS : ANALYTICS.MY_TRIPS;

  const reservationIDNumberWithLabel = currentTripCard
    ? utils.i18n('trip_card_agreement_number', { 0: agreement_number })
    : utils.i18n('trip_card_confirmation_number', { 0: confirmation_number });

  let dtmPlacement;
  if (isDetailsPage) {
    dtmPlacement = ANALYTICS.CURRENT_RENTAL;
  } else {
    dtmPlacement = currentTripCard ? ANALYTICS.CURRENT_TRIPS : ANALYTICS.UPCOMING_TRIPS;
  }

  const enableConfirmModifyReturn = utils.config.getEnableConfirmModifyReturn();
  const dtmDescLocationDetails = ANALYTICS.LOCATION_DETAILS;
  const dtmDescModify = currentTripCard ? ANALYTICS.NEED_TO_MODIFY : ANALYTICS.VIEW_MODIFY_CANCEL;
  const dtmAttributeLocationDetails = utils.analytics.dtm(dtmPageName, dtmPlacement, dtmDescLocationDetails);
  const dtmAttribute = utils.analytics.dtm(dtmPageName, dtmPlacement, dtmDescModify);

  const showOldTripCardDetailsUI = !currentTripCard || !enableConfirmModifyReturn;

  const showNewTripCardDetailsUI = currentTripCard && enableConfirmModifyReturn;

  const backgroundImage = index % 2 === 0 ? '__odd-img' : '__even-img';

  useEffect(() => {
    // TODO below is the logic to  make a additional service call to get return_confirmed state and then showing CONFIRM_MY_RETURN CTA on trip cards page if it is true
    // but recently GBO has made changes and they are returning a new flag  rental_return_confirmed flag, we should start using that flag instead of return_confirmed flag
    // gma-shared doesn't have  GBO new flag changes due to which we are checking for either rental_return_confirmed flag  or  we will be continue using return_confirmed flag .

    if (hasConfirmModify && within24hrsReturnTime && !rentalReturnConfirmed) {
      Promise.all([rentalRetrieveTicketNumber(ticketNumber, false)]).then((responses) => {
        if (!responses[0].message) {
          const storedRentalInfo = Storage.SessionStorage.get(SESSION_STORAGE.RENTAL_TICKET_DETAILS, true) || {};
          const stringify = true;
          const returnConfirmedState = responses[0]?.session?.rental?.ticket?.return_confirmed;
          const ticketNum = responses[0]?.session?.rental?.ticket.ticket_number;
          storedRentalInfo[ticketNum] = returnConfirmedState;
          Storage.SessionStorage.set(SESSION_STORAGE.RENTAL_TICKET_DETAILS, storedRentalInfo, stringify);
        }
      });
    }
  }, [hasConfirmModify]);

  const handleModifyReturn = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.MODIFY_RETURN);
    sessionStorage.removeItem(SESSION_STORAGE.MODIFY_RENTAL_ORIGINAL_DATE);
    retrieveAndCheckEligibility(ticketNumber).then((res) => {
      if (!res.messages) {
        sessionStorage.setItem(SESSION_STORAGE.MODIFY_RENTAL_ORIGINAL_DATE, dateOfRentInteraction);
      }
    });
  };

  // handleConfirmMyReturn - redirects to confirm-modify page , generates url based on the windowObjectKeys
  const handleConfirmMyReturn = () => {
    analyticsInteraction(ANALYTICS.UI_BUTTON, ANALYTICS.CONFIRM_MODIFY, ANALYTICS.CONFIRM_RETURN);
    rentalRetrieveAndConfirmAction();
  };

  const pickupStatusSection = (
    <div className='trip-card__accelerated-checkin'>
      <TripCardAcceleratedCheckin
        firstName={firstName}
        lastName={lastName}
        confirmationNumber={confirmation_number}
        userAlreadyCheckedIn={userAlreadyCheckedIn}
        skipTheCounterCompleted={skipTheCounterCompleted}
        userCanActiveSkipTheCounter={userCanActiveSkipTheCounter}
        wayfindings={locationWayfindings}
        isPickupStatusAddedAutomatically={isPickupStatusAddedAutomatically}
      />
    </div>
  );

  const ModifyAndConfirmReturn = () => (
    <div className='trip-card__modify-ctas'>
      <Button ghosted theme={THEMES.MEDIUM} onClick={handleModifyReturn}>
        {utils.i18n('modify_my_return')}
      </Button>
      {within24hrsReturnTime && !rentalReturnConfirmed && !currentRentalDetails?.[ticketNumber] && (
        <Button onClick={handleConfirmMyReturn}>{utils.i18n('confirm_my_return')}</Button>
      )}
    </div>
  );

  const ResourcesList = () => (
    <ul className='trip-card__renter-resources-list'>
      {showAfterHoursCta && (
        <li>
          <Button
            link
            onClick={() => openAfterHoursModal(return_location.address, afterHoursPolicies)}
            className='link--arrow trip-card__resources-link trip-card__resources-link--after-hours'
          >
            {utils.i18n('trip_card_after_hours_return_cta')}
          </Button>
        </li>
      )}
      <li>
        <Button
          link
          onClick={() => openReturnDirectionsModal(return_location.address)}
          className='link--arrow trip-card__resources-link trip-card__resources-link--return-directions'
        >
          {utils.i18n('trip_card_return_directions_cta')}
        </Button>
      </li>
      <li>
        <Button
          link
          onClick={openLearnMoreAboutRoadsideAssistance}
          className='link--arrow trip-card__resources-link trip-card__resources-link--roadside-assistance'
        >
          {utils.i18n('trip_card_roadside_assistance_cta')}
        </Button>
      </li>
      <li>
        <Anchor
          isExternal
          hideExternalIcon
          href={utils.locations.getGasStationURL(
            utils.locations.addressObjToSingleLine(return_location.address),
            return_location.gps
          )}
          className='link link--external trip-card__resources-link trip-card__resources-link--gas-stations'
        >
          {utils.i18n('trip_card_nearby_gas_stations_cta')}
          <VisHiddenText
            message={`${utils.i18n('map_locations_between_time')} ${return_location.name} Opens in a new window`}
          />
        </Anchor>
      </li>
    </ul>
  );

  const LocationDetailsButton = () => (
    <Button
      onClick={redirectToLocationDetailsPage}
      link
      className='link--arrow trip-card__location-details'
      data-dtm-track={dtmAttributeLocationDetails}
    >
      {utils.i18n('trip_card_location_details_button')}
    </Button>
  );

  const DefaultTripCard = () => (
    <div
      className={cn('trip-card', {
        'trip-card--margin-top': !isDetailsPage,
        'trip-card__current': showNewTripCardDetailsUI,
      })}
    >
      {!currentTripCard && !breakpoint.isDesktop && pickupStatusSection}
      <div className={`trip-card__icon trip-card__icon--${icon}`} />
      <div className='trip-card__details'>
        {remainingDaysCopy && <span className='trip-card__remaining-time'>{remainingDaysCopy}</span>}
        <span className='trip-card__confirmation-agreement-number'>{reservationIDNumberWithLabel}</span>
        <Title type='h3' text={!currentTripCard ? location : dateOfRentInteraction} className='trip-card__location' />
        {currentTripCard && <p className='trip-card__location-small'>{location}</p>}
        <LocationDetailsButton />

        {showOldTripCardDetailsUI && (
          <>
            <span className='trip-card__pickup-return-date'>{dateOfRentInteraction}</span>
            {!breakpoint.isMobile && (
              <Button
                link
                className='link--arrow trip-card__cta'
                data-dtm-track={dtmAttribute}
                onClick={
                  currentTripCard ? () => openExtendRentalModal(return_location.address.country_code) : ctaAction
                }
              >
                {ctaLabel}
              </Button>
            )}
          </>
        )}

        {vehicleDetails && showNewTripCardDetailsUI && (
          <>
            <p className='trip-card__vehicle-details-small'>
              <strong>{utils.i18n('trip_card_vehicle_label')}</strong> {vehicleDetails.color} {vehicleDetails.model}{' '}
              {utils.i18n('trip_card_plate', { 0: vehicleDetails.license_plate })}
            </p>
            <ModifyAndConfirmReturn />
          </>
        )}
      </div>
      {vehicleDetails && showOldTripCardDetailsUI && (
        <div className='trip-card__vehicle-details'>
          <div className='trip-card__keys-icon'>
            <img className='guaranteed-res-add-card-content__card-icon' src={carKeys} alt='' />
          </div>
          <p className='trip-card__vehicle-information'>
            <span>
              <strong>{utils.i18n('trip_card_vehicle_label')}</strong> {vehicleDetails.color} {vehicleDetails.model}
            </span>
            <span>{utils.i18n('trip_card_plate', { 0: vehicleDetails.license_plate })}</span>
          </p>
          {breakpoint.isMobile && (
            <Button
              link
              className='link--arrow trip-card__cta'
              data-dtm-track={dtmAttribute}
              onClick={currentTripCard ? () => openExtendRentalModal(return_location.address.country_code) : ctaAction}
            >
              {ctaLabel}
            </Button>
          )}
        </div>
      )}

      {carClassImage && showOldTripCardDetailsUI && (
        <div className='trip-card__car-class'>
          <img src={carClassImage} alt={carClassImageAlt} />
          {breakpoint.isMobile && (
            <Button link className='link--arrow trip-card__cta' onClick={ctaAction} data-dtm-track={dtmAttribute}>
              {ctaLabel}
            </Button>
          )}
        </div>
      )}
      {currentTripCard && (
        <div
          className={cn('trip-card__renter-resources', {
            'tripcard-right-margin': showNewTripCardDetailsUI,
          })}
        >
          <Title
            type='h4'
            text={utils.i18n('trip_card_renter_resources_title')}
            className='trip-card__renter-resources-title'
          />
          <ResourcesList />
        </div>
      )}
      {!currentTripCard && breakpoint.isDesktop && pickupStatusSection}
    </div>
  );

  const RenderCurrentTripCardUI = () => (
    <section className={cn('current-trip-card', `current-trip-card${[backgroundImage]}`)}>
      <div className='current-trip-card__container'>
        <div
          className={cn('current-trip-card__header-details', {
            'current-trip-card__image-details_no-image': !carClassImage,
          })}
        >
          <div className='current-trip-card__agreement'>
            <p>{utils.i18n('trip_card_agreement_number', { 0: agreement_number })}</p>
          </div>
          <div className='current-trip-card__image-details'>
            {carClassImage ? (
              <img src={carClassImage} alt={carClassImageAlt} />
            ) : (
              <div className='current-trip-card__image-details__purple-keys' />
            )}
            <div className='current-trip-card__image-details__text'>
              {(vehicleDetails?.year || vehicleDetails?.make || vehicleDetails.model) && (
                <h3>
                  {vehicleDetails.year} {vehicleDetails.make} {vehicleDetails.model}
                </h3>
              )}
              {vehicleDetails?.license_plate && (
                <p>{utils.i18n('trip_card_plate', { 0: vehicleDetails.license_plate })}</p>
              )}
              {vehicleDetails?.color && (
                <p>
                  {utils.i18n('trip_card_vehicle_color_label')} {vehicleDetails.color}
                </p>
              )}
            </div>
          </div>
        </div>

        <div
          className={cn('trip-card-details', {
            'trip-card__current': showNewTripCardDetailsUI,
          })}
        >
          <div className='trip-card-details__content'>
            <Title
              type='h3'
              text={utils.i18n('trip_card_agreement_number', { 0: agreement_number })}
              className='trip-card-details__rental-title'
            />

            <hr />

            <div className='trip-card-details__container'>
              <div className='trip-card-details__text'>
                <div className='trip-card-details__text__block'>
                  <Title type='h4' text={utils.i18n('rental_details_location_return_label')} />
                  <p>
                    {location} ({return_location.airport_code})
                  </p>
                  <LocationDetailsButton />
                </div>

                <div className='trip-card-details__text__block'>
                  <Title type='h4' text={utils.i18n('rental_details_return_location_date_time')} />
                  <p>{dateOfRentInteraction}</p>
                </div>
              </div>

              <div className='trip-card-details__text trip-card-details__resources-list'>
                <Title type='h4' text={utils.i18n('trip_card_renter_resources_title')} />
                <ResourcesList />
              </div>
            </div>

            <hr />
            <div className='trip-card-details__container-buttons trip-card-details__modifyreturn'>
              <ModifyAndConfirmReturn />
            </div>
          </div>

          <div className='trip-card-details__container-divisor'>
            <hr className='trip-card-details__divisor-mobile' />
          </div>

          <div className='trip-card-details__content trip-card-details__mobile'>
            <div className='trip-card-details__text trip-card-details__resources-list'>
              <Title type='h4' text={utils.i18n('trip_card_renter_resources_title')} />
              <ResourcesList />
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  return currentTripCard ? <RenderCurrentTripCardUI /> : <DefaultTripCard />;
};

TripCard.propTypes = {
  isDetailsPage: PropTypes.bool,
  icon: PropTypes.string,
  currentTripCard: PropTypes.bool,
  showAfterHoursCta: PropTypes.bool,
  remainingDaysCopy: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  confirmation_number: PropTypes.string,
  agreement_number: PropTypes.string,
  location: PropTypes.string,
  locationWayfindings: PropTypes.array,
  carClassImage: PropTypes.string,
  carClassImageAlt: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaAction: PropTypes.func,
  breakpoint: PropTypes.object.isRequired,
  userAlreadyCheckedIn: PropTypes.bool,
  skipTheCounterCompleted: PropTypes.bool,
  userCanActiveSkipTheCounter: PropTypes.bool,
  resolveBranchURL: PropTypes.func,
  return_location: PropTypes.object,
  openAfterHoursModal: PropTypes.func,
  openReturnDirectionsModal: PropTypes.func,
  dateOfRentInteraction: PropTypes.string,
  afterHoursPolicies: PropTypes.string,
  openExtendRentalModal: PropTypes.func,
  vehicleDetails: PropTypes.shape({
    model: PropTypes.string,
    color: PropTypes.string,
    license_plate: PropTypes.string,
  }),
  redirectToLocationDetailsPage: PropTypes.func,
  openLearnMoreAboutRoadsideAssistance: PropTypes.func,
  isPickupStatusAddedAutomatically: PropTypes.bool,
  within24hrsReturnTime: PropTypes.bool,
  ticketNumber: PropTypes.string,
};

export default TripCard;
