import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import utils from 'utils';
import GLOBAL from 'constants/global';
import ANALYTICS from 'constants/analytics';
import {
  getReservationPickupLocation,
  getReservationPickupTime,
  getReservationReturnLocation,
  getReservationReturnTime,
  reservationVehicleSummarySelector,
  getOneWayRentalFlag,
  isTourReservationSelector,
} from 'selectors/reservationSelectors';
import { modifyNotAllowedReasonsSelector } from 'selectors/reservationFlowSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import Button from 'components/Button';
import Picture from 'components/Picture';
import DriverDetailsRow from 'components/PostRes/DriverDetailsRow';
import guaranteedResSvg from 'images/guaranteed-res-2C.svg';
/**
 * PostResTripSummary
 *
 * @param {object} props - React Props
 * @param {function} props.handleModifyReservation - Function for "Modify Reservation" CTA
 * @param {string} props.editProfileLinkTo - Route string to redirect user for "Edit Details" CTA in Profile section (optional)
 *
 * @return {JSX}
 */
const PostResTripSummary = ({ handleModifyReservation, editProfileLinkTo }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const pickupLocation = useSelector(getReservationPickupLocation) || {};
  const pickupTime = useSelector(getReservationPickupTime);
  const returnLocation = useSelector(getReservationReturnLocation);
  const returnTime = useSelector(getReservationReturnTime);
  const vehicleSummary = useSelector(reservationVehicleSummarySelector);
  const isOneWayRental = useSelector(getOneWayRentalFlag);
  const modifyNotAllowedReasons = useSelector(modifyNotAllowedReasonsSelector);
  const isTourReservation = useSelector(isTourReservationSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const isDriveHappyDeal = GLOBAL.HAPPY_DEAL_CODE === vehicleSummary?.code;

  const formatDate = (dateValue) => (dateValue ? utils.gmi.getDateTimeObjFromTs(dateValue).format('llll') : '');

  return (
    <div
      className={cn('post-res__content theme--light', {
        'post-res__content--no-bottom-padding': !isExpanded,
      })}
    >
      <div className='post-res__section-header'>
        <h3 className='post-res__section-title'>{utils.i18n('check_in_review_trip_summary')}</h3>
        {!modifyNotAllowedReasons && !isTourReservation && (
          <Button
            onClick={handleModifyReservation}
            className='post-res__link-text post-res__link-text--inline'
            linkText
            data-dtm-track='post_res|action|modify'
          >
            {utils.i18n('check_in_review_modify_reservation')}
          </Button>
        )}
      </div>

      {/* Reservation Top Details */}
      <p className='post-res__paragraph post-res__paragraph--margin-bottom post-res-trip-summary__location'>
        <span>{utils.locations.getLocationCompleteName(pickupLocation)}</span>
        {isOneWayRental && (
          <span className='post-res-trip-summary__return-location'>
            {utils.locations.getLocationCompleteName(returnLocation)}
          </span>
        )}
      </p>
      <p className='post-res__paragraph'>{`${formatDate(pickupTime)} - ${formatDate(returnTime)}`}</p>

      {/* Vehicle Details */}
      <div className='post-res-trip-summary__vehicle-details'>
        <div className='post-res-trip-summary__vehicle-info'>
          {vehicleSummary?.isGuaranteedRes && (
            <strong className='rental-details__guaranteed-res-label'>
              <img src={guaranteedResSvg} alt={utils.i18n('guaranteed_res_icon_alt')} />
              {utils.i18n('guaranteed_res_label')}
            </strong>
          )}
          <p className='post-res__subheading'>{vehicleSummary?.name}</p>
          <p className='post-res__paragraph post-res__paragraph--margin-bottom'>
            {isDriveHappyDeal
              ? vehicleSummary?.similarText // Display without '* or similar'
              : utils.i18n('vehicle_details_model_or_similar', [vehicleSummary?.similarText])}
          </p>
        </div>
        {vehicleSummary?.images && (
          <Picture
            src={utils.getImageFromImagesObject(vehicleSummary?.images)}
            alt={vehicleSummary?.similarText}
            className='post-res-trip-summary__vehicle-image'
          />
        )}
      </div>

      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        className={cn('post-res-trip-summary__expand-button', {
          'post-res-trip-summary__expand-button--expanded': isExpanded,
        })}
        button={false}
        ariaExpanded={isExpanded.toString()}
        data-dtm-track={utils.analytics.dtm(
          isCustomPathReservation ? ANALYTICS.CUSTOM_PATH : ANALYTICS.CHECK_IN,
          ANALYTICS.REVIEW,
          'driver_license',
          isExpanded ? 'collapse' : 'expand'
        )}
      >
        {utils.i18n('check_in_review_license_contact_button')}
      </Button>

      {/* DL & Contact Details */}
      <div className={cn('post-res-trip-summary__drawer', { 'post-res-trip-summary__drawer--open': isExpanded })}>
        <DriverDetailsRow />

        {editProfileLinkTo && (
          <div className='post-res-trip-summary__drawer-footer'>
            <Link to={editProfileLinkTo} className='link--text link--arrow'>
              {utils.i18n('check_in_review_summary_edit_details')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

PostResTripSummary.propTypes = {
  handleModifyReservation: PropTypes.func,
  editProfileLinkTo: PropTypes.string,
};

export default PostResTripSummary;
