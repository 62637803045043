import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';
import ReservationFlowContainer from './ReservationFlowContainer';

const ReservationFlowEditableConfig = {
  emptyLabel: 'ReservationFlow Component',
  isEmpty: (props) => !props || (!props.heading && !props.description),
};
export default MapTo('aweb/components/content/reservationFlow')(
  spreadModelProps(ReservationFlowContainer),
  ReservationFlowEditableConfig
);
