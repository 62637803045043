import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import utils from 'utils';
import { getImageFromImagesObject } from 'utils/vehicles';
import RESERVATIONS from 'constants/reservations';
import PROFILE from 'constants/profile';
import Title from 'components/Title';
import TripCard from 'components/TripCard';
import UpcomingTripsEmpty from 'components/UpcomingTrips/UpcomingTripsEmpty';
import Anchor from 'components/Anchor';
import LoadingWidget from 'components/LoadingWidget';
// modals
import LearnMoreAboutSkipTheCounterModal from 'components/modals/LearnMoreAboutSkipTheCounterModal';
import LearnMoreAboutSkipTheCounterEligibleModal from 'components/modals/LearnMoreAboutSkipTheCounterEligibleModal';
import LearnMoreAboutSkipTheCounterIneligibleModal from 'components/modals/LearnMoreAboutSkipTheCounterIneligibleModal';
import LearnMoreAboutOCIInactiveModal from 'components/modals/LearnMoreAboutOCIInactiveModal/LearnMoreAboutOCIInactiveModal';
import ExtendRentalInformationModal from 'components/modals/ExtendRentalInformationModal';
import AfterHoursReturnInstructionsModal from 'components/modals/AfterHoursReturnInstructionsModal';
import ReturnDirectionsModal from 'components/modals/ReturnDirectionsModal';
import LearnMoreAboutRoadsideAssistanceModal from 'components/modals/LearnMoreAboutRoadsideAssistanceModal';
import WayfindingsModal from 'components/modals/WayfindingsModal';
import STCIneligibleModal from 'components/modals/STCIneligibleModal';
import RentalNotEligibleForModificationsModal from 'components/modals/RentalNotEligibleForModificationsModal';

/**
 * UserCurrentNextTrip
 * Component containing users first item from Current or Upcoming Trips
 *
 * @param {object} props           - React Props
 * @param {object} props.profileData - Authenticated user profile data
 * @param {object} props.currentTrip - Authenticated user (first) current trip
 * @param {number} props.totalCountTrips - Count of the total number for Trips (Past, Current and Upcoming)
 * @param {object} props.upcomingTrip - Authenticated user (first) upcoming trip
 * @param {function} props.getUpcomingTrips - Function to fetch user's upcoming trips
 * @param {function} props.getCurrentTrips - Function to fetch user's current trips
 * @param {function} props.getPastTrips - Function to fetch user's past trips
 * @param {function} props.retrieveReservation - redirect to trip details
 * @param {function} props.onFetch - Outside function to run after all trips fetchings are over
 *
 * @return {JSX}
 */
const UserCurrentNextTrip = ({
  currentTrip,
  upcomingTrip,
  totalCountTrips,
  profileData,
  getUpcomingTrips,
  getCurrentTrips,
  getPastTrips,
  retrieveReservation,
  onFetch,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  // On component load, fetch all trips trips (Past, Current and Upcoming)
  // Past trips are required for the total count in "View All Trips" CTA.
  useEffect(() => {
    const { memberNumber } = profileData || {};
    // This is to avoid that getPastTrips is called before profileData is loaded
    if (memberNumber) {
      Promise.all([getPastTrips({ membership_number: memberNumber }), getCurrentTrips(), getUpcomingTrips()]).then(
        () => {
          setIsLoading(false);
          onFetch?.();
        }
      );
    }
  }, [profileData]);

  const isCurrentTrip = !!currentTrip;
  const trip = isCurrentTrip ? currentTrip : upcomingTrip;

  const { first_name, last_name } = profileData || {};
  const {
    reservation_sub_status,
    remaining_days_for_drop_off,
    remaining_days_for_pick_up,
    return_time,
    pickup_time,
    pickup_location,
    return_location,
    confirmation_number,
    agreement_number,
    vehicle_details,
    counter_bypass_eligible,
    auto_check_in,
    within24_hrs_of_return_time,
    ticket_number,
    confirm_modify,
  } = trip || {};

  // Trip logics
  const userAlreadyCheckedIn = reservation_sub_status === RESERVATIONS.RESERVATION_SUB_STATUS.CHECKED_IN;
  const skipTheCounterCompleted = reservation_sub_status === RESERVATIONS.RESERVATION_SUB_STATUS.COUNTER_BYPASS;

  const upcomingTripIcon = userAlreadyCheckedIn ? 'calendar-checkmark' : 'calendar-star';
  const cardIcon = isCurrentTrip ? 'car-front' : upcomingTripIcon;

  const remainingDaysCopy = utils.date.formatRemainingDays(
    isCurrentTrip ? remaining_days_for_drop_off : remaining_days_for_pick_up,
    isCurrentTrip ? 'return' : 'pickup'
  );
  const dateOfRentObject = utils.gmi.getDateTimeObjFromTs(isCurrentTrip ? return_time : pickup_time);
  const ctaLabel = utils.i18n(isCurrentTrip ? 'trip_card_call_to_modify_cta' : 'trip_card_view_modify_cancel_cta');
  // If trip is current trip -> "Current Trip" title
  // else -> "Next Trip" Title
  const notEmptyTitleKey = `my_profile_${isCurrentTrip ? 'current_trip' : 'next_trips'}_heading`;
  // If loading or 0 upcmonig trips -> "Upcoming Trips" title
  // else -> title for available trip type
  const titleTextKey = isLoading || !trip ? 'upcoming_trips_heading' : notEmptyTitleKey;

  // Trip Card Props logic
  const tripCardProps = {
    icon: cardIcon,
    firstName: first_name,
    lastName: last_name,
    confirmation_number,
    agreement_number: ticket_number || agreement_number,
    remainingDaysCopy,
    ctaLabel,
    dateOfRentInteraction: dateOfRentObject.format('llll'),
  };

  Object.assign(
    tripCardProps,
    isCurrentTrip
      ? {
          // Current Trip specific props
          currentTripCard: true,
          vehicleDetails: vehicle_details,
          showAfterHoursCta: return_location?.after_hours_return,
          location: return_location?.name,
          locationDetailsID: return_location?.id,
          return_location,
          showRenterResources: true,
          within24hrsReturnTime: within24_hrs_of_return_time,
          ticketNumber: ticket_number,
          rentalReturnConfirmed: Object.hasOwn(trip, 'rental_return_confirmed')
            ? trip.rental_return_confirmed
            : trip?.return_confirmed,
          hasConfirmModify: confirm_modify,
          carClassImage:
            vehicle_details?.images && getImageFromImagesObject(vehicle_details.images, { type: '0', width: 352 }),
          carClassImageAlt: vehicle_details?.make_model_or_similar_text,
        }
      : {
          // Upcoming/Next Trip specific props
          skipTheCounterCompleted,
          userAlreadyCheckedIn,
          location: pickup_location?.name,
          locationWayfindings: pickup_location?.wayfindings,
          locationDetailsID: pickup_location?.id,
          carClassImage: getImageFromImagesObject(vehicle_details?.images, { type: '0', width: 295 }),
          carClassImageAlt: vehicle_details?.make_model_or_similar_text,
          isPickupStatusAddedAutomatically: auto_check_in,
          userCanActiveSkipTheCounter: counter_bypass_eligible,
          ctaAction: () =>
            retrieveReservation({
              firstName: first_name,
              lastName: last_name,
              confirmationNumber: confirmation_number,
            }),
        }
  );

  const renderMyTripsCta = () => {
    // if the UI is loading hide the CTA.
    if (isLoading) {
      return '';
    }

    // On the SPA pages, use a `Link` to carry forward React Router functionality, otherwise use a vanilla `a`.
    return utils.url.isPathCurrentUrl(utils.gmi.get(window, 'ehi.aem.redirects.profile-page')) ? (
      <Link className='link title__link link--arrow' to={{ pathname: PROFILE.PROFILE_PATHS_CONFIG.myTrips[0] }}>
        {utils.i18n('upcoming_trips_view_my_trips', [totalCountTrips])}
      </Link>
    ) : (
      <Anchor
        className='link title__link link--arrow'
        href={utils.url.createUrl({
          base: utils.gmi.get(window, 'ehi.aem.redirects.profile-page'),
          hash: PROFILE.PROFILE_PATHS_CONFIG.myTrips[0],
        })}
      >
        {utils.i18n('upcoming_trips_view_my_trips', [totalCountTrips])}
      </Anchor>
    );
  };

  const renderTripCard = () => (trip ? <TripCard {...tripCardProps} /> : <UpcomingTripsEmpty showProfileEmptyState />);

  return (
    <div className='row padding-top--condensed padding-bottom--regular'>
      <Title customCtaRender={renderMyTripsCta} text={utils.i18n(titleTextKey)} type='h2' />
      {isLoading ? <LoadingWidget darkTheme /> : renderTripCard()}
      <LearnMoreAboutSkipTheCounterModal />
      <LearnMoreAboutSkipTheCounterEligibleModal />
      <LearnMoreAboutSkipTheCounterIneligibleModal />
      <LearnMoreAboutOCIInactiveModal />
      <ReturnDirectionsModal />
      <AfterHoursReturnInstructionsModal />
      <ExtendRentalInformationModal />
      <LearnMoreAboutRoadsideAssistanceModal />
      <WayfindingsModal />
      <STCIneligibleModal />
      <RentalNotEligibleForModificationsModal />
    </div>
  );
};

UserCurrentNextTrip.propTypes = {
  profileData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    memberNumber: PropTypes.string,
  }),
  currentTrip: PropTypes.object,
  upcomingTrip: PropTypes.object,
  totalCountTrips: PropTypes.number,
  getUpcomingTrips: PropTypes.func,
  getCurrentTrips: PropTypes.func,
  getPastTrips: PropTypes.func,
  retrieveReservation: PropTypes.func,
  onFetch: PropTypes.func,
};

export default UserCurrentNextTrip;
