import { MapTo, Container } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';

const ParsysEditableConfig = {
  emptyLabel: 'Parsys Container',
  isEmpty: (props) => !props[':itemsOrder'] || props[':itemsOrder'].length < 1,
};

// Parsys is simple and common enough to be statically imported
export default MapTo('aweb/components/content/responsivegrid')(spreadModelProps(Container), ParsysEditableConfig);
