import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import MODAL from 'constants/modals';
import THEMES from 'constants/themes';
import useActions from 'hooks/useActions';
import Checkbox from 'components/Form/Checkbox';
import Anchor from 'components/Anchor';
import RentalTermsAndConditionsModal from 'components/modals/RentalTermsAndConditionsModal';
import { openModal } from 'actions/modal/openModal';
import STCFormCard from './STCFormCard';

/**
 * STCTermsAndConditions
 *
 * @param {object} props - React props
 * @param {boolean} props.haveErrors - Is there any error in the form for Protection Options inputs?
 * @param {boolean} props.submitFailed - Did submit failed? (Used to show error message only after any submit)
 * @return {JSX}
 */
const STCTermsAndConditions = ({ haveErrors, submitFailed }) => {
  const openTermConditionsModal = useActions(() => openModal(MODAL.KEY_RENTAL_FACTS_TERMS_AND_CONDITIONS));

  // As Rental T&C should be an anchor to line break with the rest of the text, we prevent the event
  // default effect so it will only open the modal without any navigation.
  const anchorOpenModal = (e) => {
    e.preventDefault();
    openTermConditionsModal();
  };

  // As the checkbox is the only field in this tile and it is required, `haveErrors` is also a way to
  // get if the field is checked or not.
  const dtmTrackValue = `check_in|counter|${haveErrors ? 'check' : 'uncheck'}`;

  return (
    <STCFormCard title={utils.i18n('stc_terms_conditions_title')} isComplete={!haveErrors}>
      {haveErrors && submitFailed && (
        <p className='skip-the-counter-flow__paragraph skip-the-counter-flow__paragraph--error'>
          {utils.i18n('stc_terms_conditions_error_message')}
        </p>
      )}

      <p className='skip-the-counter-flow__paragraph skip-the-counter-flow__legal-copy skip-the-counter-flow__legal-copy--terms-conditions'>
        {utils.i18n('stc_terms_conditions_legal_copy')}
      </p>

      <Checkbox
        name='accept_terms_and_conditions'
        label={utils.i18n(
          'stc_terms_conditions_checkbox_text',
          [
            <Anchor
              href='#'
              onClick={anchorOpenModal}
              key={0}
              className='skip-the-counter-flow__link-text skip-the-counter-flow__checkbox-link'
            >
              {utils.i18n('stc_terms_conditions_checkbox_link')}
            </Anchor>,
          ],
          {
            jsx: true,
          }
        )}
        theme={THEMES.LIGHT}
        validate={utils.fieldValidation.isTrueBool}
        dataDtmAnalytics={dtmTrackValue}
      />

      <RentalTermsAndConditionsModal />
    </STCFormCard>
  );
};

STCTermsAndConditions.propTypes = {
  haveErrors: PropTypes.bool,
  submitFailed: PropTypes.bool,
};

export default STCTermsAndConditions;
