import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import utils from 'utils';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import { checkinStart } from 'actions/checkin';
import { clearProfile } from 'actions/profile';
import { reservationDriverInfoSelector } from 'selectors/reservationSelectors';
import Modal from 'components/Modal';
import SignIn from 'components/SignIn';

/**
 * SignInModal
 * Modal that displays the Sign In component as its' content.
 * After a successful login, the modal closes.
 *
 * @param {object} props - React Props
 * @param {boolean} props.isPartialProfileFound - specific scenario validation for when partial profile is found in OCI
 *
 * @return {JSX} SignInModal component
 */
const SignInModal = ({ isPartialProfileFound }) => {
  const checkinStartAction = useActions(checkinStart);
  const clearProfileAction = useActions(clearProfile);
  const { first_name, last_name, email_address } = useSelector(reservationDriverInfoSelector) || {};

  const renderRequiredForCheckinContent = () => (
    <div>
      <p className='signin-modal__info'>{utils.i18n('signin_modal_checkin_required')}</p>
      <p className='signin-modal__info'>{utils.i18n('signin_modal_checkin_associated_account')}</p>
      <p className='signin-modal__driver-info'>{`${first_name} ${last_name}`}</p>
      {email_address && (
        <p className='signin-modal__driver-info'>{`${utils.i18n('common_email')}: ${email_address}`}</p>
      )}
    </div>
  );

  const renderRequiredForPartialProfileFound = () => (
    <div>
      <p className='signin-modal__info'>
        {utils.i18n('check_in_step_profile_loyalty_found_sign_in_modal_description')}
      </p>
    </div>
  );

  const onSuccessHandler =
    (handleClose, checkinOrigin = '') =>
    () => {
      utils.analytics.interaction(ANALYTICS.MODAL, ANALYTICS.SIGN_IN_MODAL, ANALYTICS.SIGN_IN);
      handleClose();
      if (checkinOrigin) {
        checkinStartAction({ origin: checkinOrigin });
      }
    };

  const onCloseModal = (handleClose) => () => {
    const analyticsObj = {
      analyticsHandler: () =>
        utils.analytics.interaction(ANALYTICS.MODAL, utils.analytics.prepareKey(MODAL.SIGN_IN_MODAL), ANALYTICS.CANCEL),
    };
    if (isPartialProfileFound) {
      clearProfileAction().then(() => {
        handleClose(analyticsObj);
      });
      return;
    }
    handleClose(analyticsObj);
  };

  return (
    <Modal
      modalKey={MODAL.SIGN_IN_MODAL}
      customClass='signin-modal'
      header={utils.i18n('signin_modal_title')}
      customActionOnClose={isPartialProfileFound ? clearProfileAction : null}
      theme={MODAL_THEMES.WHITE}
    >
      {({ handleClose, checkinOrigin, stashAnalytics }) => (
        <>
          {!!checkinOrigin && renderRequiredForCheckinContent()}
          {renderRequiredForPartialProfileFound()}
          <SignIn
            onSuccess={onSuccessHandler(handleClose, checkinOrigin)}
            onCancel={onCloseModal(handleClose)}
            stashAnalytics={stashAnalytics}
          />
        </>
      )}
    </Modal>
  );
};

SignInModal.propTypes = {
  isPartialProfileFound: PropTypes.bool,
};

SignInModal.defaultProps = {
  isPartialProfileFound: false,
};

export default SignInModal;
