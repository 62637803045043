import React, { Fragment } from 'react';
import utils from 'utils';
import Button from 'components/Button';
import FormattedPrice from 'components/FormattedPrice';
import FieldGroupCard from '../FieldGroupCard';
import { propTypes } from './payLaterConfrimationCardPropTypes';

// TODO: See if we can refactor to not use two separate layouts for mobile vs desktop, flex styling should be able to
// handle the differences between comp layouts.

const PayLaterConfirmationCard = ({
  breakpoint,
  isForeignCurrency,
  estimatedTotal,
  estimatedTotalPayment,
  showLearnMoreModal,
  oppositeEstimatedTotal,
  handleTogglePrepay,
  isTrueModify,
  prepayTotal,
  disablePrepayPaylaterButton,
  isSkipTheCounterCompleted,
}) => {
  const { isTabletOrMobile, isDesktop } = breakpoint;

  const foreignCurrencyKeyVariables = {
    0: <Fragment key='0'>{estimatedTotalPayment?.code}</Fragment>,
    1: <FormattedPrice key='1'>{utils.getFormattedPrice(estimatedTotalPayment)}</FormattedPrice>,
  };

  const getTitle = () => {
    let key;
    if (isForeignCurrency) {
      key = prepayTotal
        ? 'true_modify_switch_to_pay_later_with_foreign_currency_total'
        : 'review_complete_reservation_pay_later_foreign_currency_total_due_message';
    } else {
      key = 'review_complete_reservation_pay_later_total_due_message';
    }

    return utils.i18n(key);
  };

  const prepayToggleLabelKey = isTabletOrMobile
    ? 'review_complete_reservation_switch_to_prepay_label_mobile'
    : 'review_complete_reservation_switch_to_prepay_label';

  const togglePrepayButton = (
    <Button link className='link--text' onClick={handleTogglePrepay} data-dtm-track='review|pre_pay|select'>
      {utils.i18n(prepayToggleLabelKey, {
        0: utils.getFormattedPrice(oppositeEstimatedTotal),
      })}
    </Button>
  );

  const foreignCurrencyContent = (
    <>
      <h4 className='review__complete-reservation__total-title'>
        {utils.i18n(
          'review_complete_reservation_pay_later_foreign_currency_total_charge_message',
          foreignCurrencyKeyVariables,
          { jsx: true }
        )}
      </h4>
      <div className='review__complete-reservation__total-margin-section'>
        <a onClick={showLearnMoreModal} className='link link--text'>
          {utils.i18n('rental_details_view_currency_conversion')}
        </a>
      </div>
    </>
  );

  const prepayTotalRefundCopy = (
    <div className='review__complete-reservation__total-margin-section'>
      <p className='review__complete-reservation__total-description'>
        {utils.i18n(
          'true_modify_switch_to_pay_later_refund_copy',
          { 0: <FormattedPrice key='0'>{prepayTotal}</FormattedPrice> },
          { jsx: true }
        )}
      </p>
    </div>
  );

  return (
    <FieldGroupCard
      title={utils.i18n(
        isTrueModify
          ? 'true_modify_review_form_complete_reservation_title'
          : 'review_information_complete_reservation_title'
      )}
      headerBorder={!isTrueModify}
    >
      <div className='review__complete-reservation__total-row'>
        {isTabletOrMobile && (!isTrueModify || prepayTotal) && (
          <>
            <div className='review__complete-reservation__total-description-column'>
              <h4 className='review__complete-reservation__total-title'>{getTitle()}</h4>
            </div>
            <div className='review__complete-reservation__total-price-column'>
              <FormattedPrice classNames='review__complete-reservation__total-price'>
                {utils.getFormattedPrice(estimatedTotal)}
              </FormattedPrice>
              {oppositeEstimatedTotal && togglePrepayButton}
            </div>

            {prepayTotal && prepayTotalRefundCopy}

            {isForeignCurrency && foreignCurrencyContent}
          </>
        )}

        {isDesktop && (!isTrueModify || prepayTotal) && (
          <>
            <div className='review__complete-reservation__total-description-column'>
              <h4 className='review__complete-reservation__total-title'>{getTitle()}</h4>
              {isForeignCurrency && foreignCurrencyContent}

              {prepayTotal && prepayTotalRefundCopy}
            </div>

            <div className='review__complete-reservation__total-price-column'>
              <FormattedPrice classNames='review__complete-reservation__total-price'>
                {utils.getFormattedPrice(estimatedTotal)}
              </FormattedPrice>
              {oppositeEstimatedTotal && togglePrepayButton}
            </div>
          </>
        )}
      </div>
      <div className={!isTrueModify ? 'review__form__submit-button-container' : ''}>
        <Button
          className='review__form__submit-button'
          type='submit'
          disabled={disablePrepayPaylaterButton}
          data-dtm-track={isTrueModify ? 'review|modify|update_reservation' : 'review|reservation_commit|reserve_now'}
        >
          {utils.i18n(isTrueModify ? 'true_modify_review_form_submit_button_label' : 'review_form_submit_button_label')}
        </Button>
      </div>

      {isTrueModify && oppositeEstimatedTotal && !prepayTotal && !isSkipTheCounterCompleted && togglePrepayButton}
    </FieldGroupCard>
  );
};

PayLaterConfirmationCard.propTypes = propTypes;

export default PayLaterConfirmationCard;
