import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from '../../helpers/spreadModelProps';
import LinkModal from './LinkModal';

const LinkModalEditableConfig = {
  emptyLabel: 'Link Modal',
  isEmpty: (props) => !props || !props.title,
};

export default MapTo('aweb/components/content/link-modal')(spreadModelProps(LinkModal), LinkModalEditableConfig);
