import React from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import FormattedPrice from 'components/FormattedPrice';

/**
 * UpgradeDescription - Reused description copy across InPathUpgrade banners
 *
 * @param {object}    props - React Props
 * @param {string}    props.name - upgrade vehicle name
 * @param {string}    props.makeModelOrSimilarText - upgrade vehicle make model or similar text
 * @param {string}    props.priceDifference - formatted price indicating difference of upgrade, can be incremental or total
 * @param {string}    props.total - formatted price indicating total price for upgrade
 * @return {JSX}      button jsx element with common button and InPathUpgrade styles and functionality
 */
const UpgradeDescription = ({ name, makeModelOrSimilarText, priceDifference, total }) => {
  const upgradeNowCopy = <strong key={0}>{utils.i18n('in_path_vehicle_upgrade_upgrade_now_bold')}</strong>;
  const priceDiffWithMake = <FormattedPrice key={3}>{priceDifference}</FormattedPrice>;
  const priceDiffNow = <FormattedPrice key={2}>{priceDifference}</FormattedPrice>;

  return (
    <div className='in-path-upgrade__description'>
      {total && <h4>{utils.i18n('in_path_vehicle_upgrade_eligible')}</h4>}
      <p>
        {makeModelOrSimilarText
          ? utils.i18n(
              'in_path_vehicle_upgrade_upgrade_now_with_make',
              [
                upgradeNowCopy,
                name,
                utils.i18n('vehicle_details_model_or_similar', [makeModelOrSimilarText]),
                priceDiffWithMake,
              ],
              { jsx: true }
            )
          : utils.i18n('in_path_vehicle_upgrade_upgrade_now', [upgradeNowCopy, name, priceDiffNow], {
              jsx: true,
            })}

        {total && (
          <span className='in-path-upgrade__new-total'> {utils.i18n('in_path_vehicle_upgrade_total', [total])}</span>
        )}
      </p>
    </div>
  );
};

UpgradeDescription.proptypes = {
  name: PropTypes.string,
  makeModelOrSimilarText: PropTypes.string,
  priceDifference: PropTypes.string,
  total: PropTypes.string,
};

export default UpgradeDescription;
