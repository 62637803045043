import React, { Component, createRef } from 'react';
import cn from 'classnames';
import utils from 'utils';
import { HashRouter as Router, Route } from 'react-router-dom';
import Button from 'components/Button';
import SoldOutModal from 'components/modals/SoldOutModal';
import RESERVATIONS from 'constants/reservations';
import ANALYTICS from 'constants/analytics';
import { ProgressBarTab } from './ProgressBarTab';
import { propTypes, defaultProps } from './progressBarPropTypes';
import { PROGRESS_BAR_CONFIG, MOBILE_PROGRESS_BAR_CONFIG } from '../reservationFlowRouteUtils';
import ProgressBarDrawerContainer from './ProgressBarDrawerContainer';
import ProgressBarHeaderContainer from './ProgressBarTab/ProgressBarHeaderIndicator';

const ORDERED_PATHS = Object.values(RESERVATIONS.RESFLOW_PATHS_CONFIG).flat();

const { EXCHANGE_TYPE_PICKUP, EXCHANGE_TYPE_RETURN, RESFLOW_PATHS_CONFIG } = RESERVATIONS;
class ProgressBar extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  state = {
    stickyBar: false,
  };

  progressBarLayout = createRef();

  componentDidMount() {
    document.addEventListener('scroll', this.handlePageScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handlePageScroll);
  }

  componentDidUpdate(prevProps) {
    const { mobileExpanded } = this.props;
    const mobileExpandedToggledOn = !prevProps.mobileExpanded && mobileExpanded;
    const mobileExpandedToggledOff = prevProps.mobileExpanded && !mobileExpanded;
    if (mobileExpandedToggledOn) {
      utils.scrollLock({ toggleValue: true });
    }
    if (mobileExpandedToggledOff) {
      utils.scrollLock({ toggleValue: false });
    }
  }

  handlePageScroll = () => {
    const { isMobile } = this.props.breakpoint;
    if (!isMobile) {
      const { stickyBar } = this.state;
      const { top } = this.progressBarLayout.current.getBoundingClientRect();
      if (top <= 0 && !stickyBar) {
        this.setState({ stickyBar: true });
      } else if (top > 0 && stickyBar) {
        this.setState({ stickyBar: false });
      }
    }
  };

  handleExpandTab = (tabId) => {
    this.props.dispatchExpandedTab(tabId || null);

    if (!this.props.didProgressBarLocationInitiate) {
      this.resetPickuplocationToMatchWithGBO();
    }
  };

  handleCollapseProgressBar = () => {
    const { mobileExpanded, expandedTab } = this.props;

    if (mobileExpanded) {
      this.resetPickuplocationToMatchWithGBO();
      this.props.dispatchMobileExpanded(false);
    }
    if (expandedTab) {
      this.resetPickuplocationToMatchWithGBO();
      this.props.dispatchExpandedTab(null);
    }
  };

  handleClickOutside = () => {
    const {
      openModals,
      gmaPickupLocation,
      gmaReturnLocation,
      setLocation,
      initiateReservation,
      gboPickupLocation,
      gboReturnLocation,
      expandedTab,
      mobileExpanded,
      lastValidSearchedLocationId,
      adjustLocationId,
      dispatchSetAdjustLocation,
      clearVehiclesFilterData,
      setSelectedCurrencyToggleData,
      getVehiclesList,
    } = this.props;
    const locationSelectPage = RESFLOW_PATHS_CONFIG.location[0];
    const windowHash = window.location.hash.replace('#', '');

    if (openModals.length === 0) {
      expandedTab && this.props.dispatchExpandedTab(null);
      mobileExpanded && this.props.dispatchMobileExpanded(false);
      utils.scrollLock({ toggleValue: false });

      // If progress bar is expanded
      // AND if location from GMA is different than the one in GBO
      // AND last valid location/pickup_location_id is different than pickup location from GBO, should update with GBO info
      // reset gmi > ui, to match what's in gbo
      // not call this if on location select page
      if (
        windowHash !== locationSelectPage &&
        (!!expandedTab || mobileExpanded) &&
        ((gboPickupLocation?.name &&
          gmaPickupLocation?.name !== gboPickupLocation.name &&
          gmaPickupLocation?.id !== gboPickupLocation.id) ||
          (gboReturnLocation.name &&
            gmaReturnLocation?.name !== gboReturnLocation.name &&
            gmaReturnLocation?.id !== gboReturnLocation.id)) &&
        lastValidSearchedLocationId !== gboPickupLocation.id
      ) {
        setLocation(gboPickupLocation, EXCHANGE_TYPE_PICKUP);
        setLocation(gboReturnLocation, EXCHANGE_TYPE_RETURN).then(() => {
          initiateReservation();
        });
        clearVehiclesFilterData();
        setSelectedCurrencyToggleData();
        getVehiclesList(gboPickupLocation.address.country_code);
      }

      // If there's adjustLocationId it means that used gave up on changing Location Date Time for that sold out location
      if ((!!expandedTab || mobileExpanded) && adjustLocationId) {
        dispatchSetAdjustLocation(null);
      }
    }
  };

  // function to reset gmi>ui to match with gbo
  resetPickuplocationToMatchWithGBO = () => {
    const {
      gmaPickupLocation,
      gmaReturnLocation,
      setLocation,
      gboPickupLocation,
      gboReturnLocation,
      lastValidSearchedLocationId,
      didProgressBarLocationInitiate,
    } = this.props;
    const locationSelectPage = RESFLOW_PATHS_CONFIG.location[0];
    const windowHash = window.location.hash.replace('#', '');

    // if location from GMA is different than the one in GBO
    // AND last valid location/pickup_location_id is different than pickup location from GBO, should update with GBO info
    // reset gmi > ui, to match what's in gbo
    // not call this if on location select page
    if (
      windowHash !== locationSelectPage &&
      !didProgressBarLocationInitiate &&
      ((gboPickupLocation?.name &&
        gmaPickupLocation?.name !== gboPickupLocation.name &&
        gmaPickupLocation?.id !== gboPickupLocation.id) ||
        (gboReturnLocation.name &&
          gmaReturnLocation?.name !== gboReturnLocation.name &&
          gmaReturnLocation?.id !== gboReturnLocation.id)) &&
      lastValidSearchedLocationId !== gboPickupLocation.id
    ) {
      setLocation(gboPickupLocation, EXCHANGE_TYPE_PICKUP);
      setLocation(gboReturnLocation, EXCHANGE_TYPE_RETURN);
    }
  };

  handleCloseDrawer = () => {
    const { expandedTab, didProgressBarLocationInitiate } = this.props;
    expandedTab && this.props.dispatchExpandedTab(null);

    if (!didProgressBarLocationInitiate) {
      this.resetPickuplocationToMatchWithGBO();
    }
  };

  handleBackClick = () => {
    const { expandedTab } = this.props;
    expandedTab && this.props.dispatchExpandedTab(null);
    let key = '';
    if (expandedTab === 'datesTimes') {
      key = ANALYTICS.DATE_TIME;
    }

    if (expandedTab === 'location') {
      key = ANALYTICS.LOCATION_PAGE;
    }
    utils.analytics.interaction(utils.analytics.prepareKey(ANALYTICS.PROGRESS_BAR), key, ANALYTICS.BACK);
  };

  toggleExpandMobile = () => {
    const { mobileExpanded, expandedTab, didProgressBarLocationInitiate } = this.props;
    expandedTab && this.props.dispatchExpandedTab(null);
    this.props.dispatchMobileExpanded(!mobileExpanded);

    if (!didProgressBarLocationInitiate) {
      this.resetPickuplocationToMatchWithGBO();
    }

    utils.analytics.interaction(
      ANALYTICS.DRAWER,
      'mobile progress bar',
      !mobileExpanded ? ANALYTICS.OPEN : ANALYTICS.CLOSE
    );
  };

  handleRouteUpdates = () => this.handleCollapseProgressBar();

  renderExpandedComponent = (path) => {
    const { expandedTab, isFetchingSession, expandedTotalDropdown } = this.props;
    return (
      <ProgressBarDrawerContainer
        onCloseDrawer={this.handleCloseDrawer}
        handleCollapseProgressBar={this.handleCollapseProgressBar}
        isFetchingReservation={isFetchingSession}
        currentPage={path}
        expandedTab={expandedTab}
        expandedTotalDropdown={expandedTotalDropdown}
      />
    );
  };

  getActiveProgressBarName = (match) => {
    const reservationFlowRoutes = MOBILE_PROGRESS_BAR_CONFIG.filter(
      (item) => item.paths.includes(match?.path) || item.disabledOnPaths?.includes(match?.path)
    );
    let progressTitle = utils.i18n(reservationFlowRoutes[0]?.progressBarLabel);
    progressTitle =
      progressTitle === utils.i18n('location_tiles_header_singular')
        ? 'location_tiles_header_plural'
        : reservationFlowRoutes[0]?.progressBarLabel;
    return progressTitle;
  };

  render() {
    const {
      breakpoint,
      gmiReservationReady,
      haveInitiateRequest,
      isTrueModify,
      coupons,
      expandedTab,
      mobileExpanded,
      expandedTotalDropdown,
    } = this.props;
    const { stickyBar } = this.state;
    const { isMobile } = breakpoint;
    const isAtStartOfStandAloneBookingWidgetPage = utils.dom.isAtStartOfStandAloneBookingWidgetPage();
    const shouldRenderTabs = gmiReservationReady && (!isAtStartOfStandAloneBookingWidgetPage || haveInitiateRequest);
    const freeUpgradeApplied = utils.isFreeUpgradeAvailable(coupons);

    return (
      <Router basename={'/'}>
        <Route path={ORDERED_PATHS} exact>
          {({ match, location }) => (
            <utils.OnUpdateWrapper pathname={location.pathname} onUpdate={this.handleRouteUpdates}>
              <>
                {!isMobile && (
                  <>
                    {stickyBar && <div className='progress-bar__sticky-layout-saver' ref={this.progressBarLayout} />}
                    <div
                      className={cn('progress-bar', { sticky: stickyBar })}
                      ref={!stickyBar ? this.progressBarLayout : null}
                    >
                      <div className={cn('progress-bar__overlay', { visible: expandedTab })} />
                      <div className='progress-bar__container' ref={this.clickOutsideRef}>
                        <div className='progress-bar__tabs'>
                          {shouldRenderTabs &&
                            PROGRESS_BAR_CONFIG.map((config) => (
                              <React.Fragment key={config.id}>
                                <ProgressBarTab
                                  config={config}
                                  expanded={config.id === expandedTab}
                                  handleExpandTab={this.handleExpandTab}
                                  key={config.id}
                                  match={match}
                                />
                                {config.id === expandedTab &&
                                  expandedTab &&
                                  this.renderExpandedComponent(location.pathname)}
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {isMobile && shouldRenderTabs && (
                  <div
                    className={cn('progress-bar-mobile', {
                      'true-modify': isTrueModify,
                      'total-dropdown-bar-height': expandedTotalDropdown,
                    })}
                    ref={this.clickOutsideRef}
                  >
                    {gmiReservationReady && (
                      <>
                        {!expandedTotalDropdown && (
                          <div className='progress-bar-mobile__header-wrapper' onClick={this.toggleExpandMobile}>
                            <div className='mobile-progress-bar__header-row-text-conent'>
                              <Button
                                button={false}
                                className={cn('progress-bar-mobile__header-expand-button', {
                                  expanded: mobileExpanded,
                                })}
                                ariaExpanded={mobileExpanded.toString()}
                                ariaText={utils.i18n(
                                  mobileExpanded
                                    ? 'progress_bar_collapse_accessibility'
                                    : 'progress_bar_expand_accessibility'
                                )}
                              >
                                <span className='mobile-progress-bar__button-label'>
                                  {utils.i18n(this.getActiveProgressBarName(match))}
                                </span>
                              </Button>
                            </div>
                            <ol className='mobile-progress-bar__header-row'>
                              {MOBILE_PROGRESS_BAR_CONFIG.map((config, index) => (
                                <ProgressBarHeaderContainer
                                  key={index}
                                  value={index + 1}
                                  config={config}
                                  match={match}
                                  progressBarConfig={MOBILE_PROGRESS_BAR_CONFIG}
                                />
                              ))}
                            </ol>
                          </div>
                        )}
                        {mobileExpanded && (
                          <div className='progress-bar-mobile__expanded-drop-down-overlay'>
                            <div className='progress-bar-mobile__expanded-drop-down'>
                              {!expandedTab &&
                                MOBILE_PROGRESS_BAR_CONFIG.map((config, index) => (
                                  <ProgressBarTab
                                    config={config}
                                    expanded={config.id === expandedTab}
                                    handleExpandTab={this.handleExpandTab}
                                    key={config.id}
                                    match={match}
                                    listNumber={index + 1}
                                    coupons={index === 0 ? coupons : null}
                                    mobile
                                    freeUpgradeApplied={freeUpgradeApplied}
                                  />
                                ))}
                              {expandedTab && (
                                <div className='progress-bar-mobile__expanded-tab'>
                                  <Button
                                    link={true}
                                    className='expanded-tab__back-button'
                                    onClick={this.handleBackClick}
                                  >
                                    {utils.i18n('progress_bar_expanded_tab_back_button')}
                                  </Button>
                                  {this.renderExpandedComponent(location.pathname)}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {/* handling totaldropdown expanded view ui */}
                        {expandedTotalDropdown && (
                          <div className='progress-bar-mobile__expanded-drop-down-overlay'>
                            <div className='progress-bar-mobile__expanded-drop-down'>
                              <div className='progress-bar-mobile__expanded-tab'>
                                {!expandedTotalDropdown && (
                                  <Button
                                    link={true}
                                    className='expanded-tab__back-button'
                                    onClick={this.handleBackClick}
                                  >
                                    {utils.i18n('progress_bar_expanded_tab_back_button')}
                                  </Button>
                                )}
                                {this.renderExpandedComponent(location.pathname)}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                {isMobile && <div className='progress-bar-mobile__layout-saver' />}
                <SoldOutModal
                  currentPage={location.pathname}
                  resetPickuplocationToMatchWithGBO={this.resetPickuplocationToMatchWithGBO}
                />
              </>
            </utils.OnUpdateWrapper>
          )}
        </Route>
      </Router>
    );
  }
}

export default utils.withClickOutside(ProgressBar);
