import { connect } from 'react-redux';
import utils from 'utils';
import { MODAL } from 'constants';
import { openModal } from 'actions/modal/openModal';
import {
  getReturnDateAndTime,
  getRentalReturnLocation,
  getRentalInfoFirstName,
  getTicketNumber,
} from 'selectors/rentalTicketSelectors';

import ConfirmReturn from './ConfirmReturn';

const mapStateToProps = (state) => ({
  firstName: getRentalInfoFirstName(state),
  returnLocation: getRentalReturnLocation(state),
  ticketNumber: getTicketNumber(state),
  returnTime: getReturnDateAndTime(state),
});

const mapDispatchToProps = {
  showRentalNotEligibleModal: () => openModal(MODAL.RENTAL_NOT_ELIGIBLE_FOR_MODIFICATION_MODAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(ConfirmReturn));
