import React from 'react';
import TextField from 'components/Form/TextField';
import { GLOBAL } from 'constants';
import utils from 'utils';
import ResponsiveListbox from 'components/Form/ResponsiveListbox';
import additionalInfoPropTypes from './additionalInfoQuestionsPropTypes';

const AdditionalInfo = ({ contractRequirements, additionalInformation, handleAdditionalInfoUpdates, formValues }) => {
  const handleOnChange = (id, value, name) => {
    if (value != null && formValues?.[name.toLowerCase()] !== value) {
      handleAdditionalInfoUpdates({ id, value });
    }
  };

  // function to handle input change event for additional_information
  const handleInputChange = (event, id, name) => {
    const { value } = event.target;
    event?.preventDefault?.();
    if (value != null && formValues?.[name.toLowerCase()] !== value) {
      handleAdditionalInfoUpdates({ id, value });
    }
  };

  return (
    <>
      {contractRequirements &&
        contractRequirements.map((field) => {
          const { id, name, required, type, supported_values, helper_text } = field;
          const nameKey = utils.string.stringUnderscorer(name);
          if (type === GLOBAL.TEXT) {
            return (
              <TextField
                key={id}
                id={id}
                name={nameKey}
                required={required}
                className='field-control__input-field'
                label={name}
                helperText={helper_text}
                initialValueButton={additionalInformation?.find((item) => item.id === id)?.value}
                onChange={(event) => handleInputChange(event, id, nameKey)}
              />
            );
          }

          if (type === GLOBAL.DROPDOWN) {
            const options = supported_values.map(({ value, display_text }) => ({ label: display_text, value }));

            return (
              <ResponsiveListbox
                key={id}
                id={id}
                label={name}
                name={nameKey}
                className='field-control__input-field'
                required={required}
                options={options}
                helperText={helper_text}
                onChange={(value) => handleOnChange(id, value, nameKey)}
              />
            );
          }

          if (type === GLOBAL.DATE) {
            return (
              <TextField
                key={id}
                id={id}
                name={nameKey}
                required={required}
                className='field-control__input-field'
                label={name}
                helperText={helper_text}
                placeHolder={utils.i18n('prerate_add_additional_info_date_placeholder')}
                maxLength={10}
                onChange={(event) => handleInputChange(event, id, nameKey)}
              />
            );
          }
          return null;
        })}
    </>
  );
};

AdditionalInfo.propTypes = additionalInfoPropTypes;

export default AdditionalInfo;
