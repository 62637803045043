import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';
import TitleList from './TitleList';

const TitleListEditableConfig = {
  emptyLabel: 'Title List',
  isEmpty: (props) => !props || !props.title,
};

export default MapTo('aweb/components/content/title-list')(spreadModelProps(TitleList), TitleListEditableConfig);
