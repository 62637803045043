import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';
import BookingWidget from './BookingWidgetContainer';

const BookingWidgetEditableConfig = {
  emptyLabel: 'Booking Widget',
  isEmpty: (props) => !props || !props.title,
};

export default MapTo('aweb/components/content/bookingwidget')(
  spreadModelProps(BookingWidget),
  BookingWidgetEditableConfig
);
