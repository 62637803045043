import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';
import CustomPathCheckInLookup from './CustomPathCheckInLookup';

const CustomPathCheckInLookupEditableConfig = {
  emptyLabel: 'Custom Path Check In Lookup Component',
  isEmpty: (props) => !props,
};

export default MapTo('aweb/components/content/custom-path-res-lookup-form')(
  spreadModelProps(CustomPathCheckInLookup),
  CustomPathCheckInLookupEditableConfig
);
