import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import FieldControl from 'components/Form/FieldControl';
import FieldState from 'components/Form/FieldsState';
import CountryList from 'components/Form/CountryList';

const RadioGroupPhoneNumber = ({
  name,
  buttons,
  onChange,
  errorMessage,
  theme,
  isRequired = true,
  showCountrylist,
  showList,
  setShowList,
  countries,
  selectedCountry,
  handleSelectCountry,
  flagsDropdownRef,
}) => (
  <FieldState names={[name]}>
    {(fieldsState) => {
      const { error, submitFailed } = fieldsState[name].meta;
      const showError = error && submitFailed;

      return (
        <div className={cn('field-control__radio-container', { 'radio-group-error': showError })}>
          {showError && <span className='field-control__error-message'>{errorMessage}</span>}

          {buttons.map(({ value, label, description, type = 'radio', showDescriptionInField }) => (
            <div className='field-control__radio-phone-container' key={`${name}--${value}`}>
              <FieldControl
                name={name}
                type={type}
                value={value}
                label={label}
                description={showDescriptionInField && showCountrylist ? null : description}
                id={`${name}--${value}`}
                onChange={onChange}
                required={isRequired}
                asterisk={false}
                dontShowError
                theme={theme}
              >
                <input />
              </FieldControl>

              {showDescriptionInField && showCountrylist && (
                <div className='field-control__phone-section'>
                  <div className='field-control__country-box'>
                    <FieldControl id={`${name}--${value}`} name='phone_country_code' label='Country'>
                      <CountryList
                        showList={showList}
                        setShowList={setShowList}
                        countries={countries}
                        selectedCountry={selectedCountry}
                        handleSelectCountry={handleSelectCountry}
                        ref={flagsDropdownRef}
                      />
                      <span className='field-control__span-value'>
                        {selectedCountry?.country_content?.dialing_code}
                      </span>
                    </FieldControl>
                  </div>
                  <div className='field-control__masked-initial-value'>
                    <FieldControl
                      id={`${name}--${value}`}
                      name='masked_phone_number'
                      label={label}
                      readOnly
                      type='text'
                      initialValueButton={description}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }}
  </FieldState>
);

RadioGroupPhoneNumber.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  errorMessage: PropTypes.string,
};

export default RadioGroupPhoneNumber;
