import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EditAccountModal from 'components/EditAccountModal';
import EmailAlreadyInUseModal from 'components/modals/EmailAlreadyInUseModal';
import utils from 'utils';
import { ASSETS } from 'constants';
import Title from 'components/Title';
import Picture from 'components/Picture';
import UserCurrentNextTrip from 'components/UserCurrentNextTrip';
import ProfileAccountHeader from './ProfileAccountHeader';
import ProfileAccountMemberBadge from './ProfileAccountMemberBadge';
import ProfileProgressSection from './ProfileProgressSection';
import ProfileAccountSupportCTA from './ProfileAccountSupportCTA';
import MyProfile from './MyProfile';

const domainCountryCode = utils.config.getDomainCountry();

/**
 * ProfileAccount
 *
 * @param {object} props - React Props
 * @param {object} props.profileData - Authenticated user profile data
 * @param {string} props.profileData.first_name - Authenticated user first name
 * @param {string} props.profileData.memberNumber - Authenticated user Alamo Insider member number
 * @param {number} props.profileData.profile_completion - Authenticated user's profile completion in integer percentage
 * @param {object} props.breakpoint - Object with viewport breakpoints checks
 * @param {object} props.getSupportContactInfo - Function to fetch current domain's support contact info
 *
 * @return {JSX} ProfileAccount component page
 */
class ProfileAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileProgress: true,
    };
  }

  componentDidMount() {
    const { getSupportContactInfo } = this.props;
    // Get Support Contact infos on page load.
    getSupportContactInfo(domainCountryCode);

    // If page loads with user's profile complete, profile progress section should not be rendered.
    // Also the setTimeout garantees that the profileData is available before checking the value.
    setTimeout(() => {
      if (this.props.profileData?.profile_completion === 100) {
        this.setState({ showProfileProgress: false });
      }
    }, 50);
  }

  renderAlamoInsiderContent = () => (
    <div className='profile-account-insiders-content'>
      <Picture
        className='profile-account-insiders-content__image'
        srcMobile={ASSETS.INSIDERS_VEHICLE_1}
        srcTablet={ASSETS.INSIDERS_VEHICLE_2}
        src={ASSETS.INSIDERS_VEHICLE_3}
        alt={utils.i18n('alamo_insiders_logo_alt')}
      />
      <Title
        type='h2'
        text={utils.i18n('my_profile_insiders_content_title')}
        className='profile-account-insiders-content__title'
      />
      <p className='profile-account-insiders-content__text'>{utils.i18n('my_profile_insiders_content_text')}</p>
    </div>
  );

  renderProfileProgressSection = () => {
    const { breakpoint } = this.props;

    return (
      <>
        <ProfileProgressSection />
        {breakpoint.isDesktop && <ProfileAccountSupportCTA />}
      </>
    );
  };

  renderMiddleSection = () => {
    const { profileData, breakpoint } = this.props;
    const { showProfileProgress } = this.state;
    const title = <Title type='h3' text={utils.i18n('my_profile_title')} className='profile-account__title' />;

    /**
     * PHONE LAYOUT
     * - Header: Welcome Back title with Members # below
     * - Middle section:
     *   - IF 100%:
     *     - Insiders content
     *     - My Profile title
     *   - ELSE:
     *     - My Profile title
     *     - Profile Progress
     * - User profile block
     */
    if (breakpoint.isMobile) {
      return (
        <>
          {!showProfileProgress && this.renderAlamoInsiderContent()}
          {title}
          {showProfileProgress && this.renderProfileProgressSection()}
        </>
      );
    }

    /**
     * TABLET LAYOUT
     *
     * - Header: Welcome Back title
     * - Middle section:
     *   - IF 100%:
     *     - Insiders Content and My Profile title left
     *   - ELSE:
     *     - My Profile itle and Profile Progress left
     *   - Members # right
     * - User profile block
     */
    if (breakpoint.isTablet) {
      return (
        <>
          <div>
            {!showProfileProgress && this.renderAlamoInsiderContent()}
            {showProfileProgress && this.renderProfileProgressSection()}
            {title}
          </div>
          <div>
            <ProfileAccountMemberBadge memberNumber={profileData?.memberNumber} />
          </div>
        </>
      );
    }

    /**
     * DESKTOP LAYOUT
     *
     * - Header: Welcome Back title and Members #
     * - My Profile title
     * - Content:
     *   - IF 100%:
     *     - User Profile block left
     *     - Insiders content right
     *   - ELSE:
     *     - Profile Progress left
     *     - User Profile block right
     */
    return (
      <>
        {title}
        {showProfileProgress && (
          <div className='profile-account__content profile-account__content--aside'>
            {this.renderProfileProgressSection()}
          </div>
        )}
      </>
    );
  };

  render() {
    const { profileData, breakpoint, openEditModal, openEmailInUseModal } = this.props;
    const { showProfileProgress } = this.state;
    const { first_name, memberNumber } = profileData || {};

    return (
      <>
        <div className='profile-account theme--dark-blue padding-top--condensed padding-bottom--condensed'>
          <div className='profile-account__row row'>
            <ProfileAccountHeader first_name={first_name} memberNumber={memberNumber} breakpoint={breakpoint} />

            {this.renderMiddleSection()}

            <div className='profile-account__content'>
              <MyProfile />
              {breakpoint.isTabletOrMobile && <ProfileAccountSupportCTA />}
            </div>

            {!showProfileProgress && breakpoint.isDesktop && (
              <div className='profile-account__content profile-account__content--aside'>
                {this.renderAlamoInsiderContent()}
              </div>
            )}
          </div>
        </div>
        <section className='theme--light-blue padding-top--regular padding-bottom--condensed auth-current-next-trip auth-pro-account '>
          <UserCurrentNextTrip />
        </section>
        <EditAccountModal openEmailInUseModal={openEmailInUseModal} />
        <EmailAlreadyInUseModal openEditModal={openEditModal} isInsideProfileModifyModal />
      </>
    );
  }
}

ProfileAccount.propTypes = {
  profileData: PropTypes.shape({
    first_name: PropTypes.string,
    memberNumber: PropTypes.string,
    profile_completion: PropTypes.number,
  }),
  breakpoint: PropTypes.object,
  getSupportContactInfo: PropTypes.func,
};

export default ProfileAccount;
