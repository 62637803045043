import React, { Component } from 'react';
import utils from 'utils';
import {
  Vehicle,
  DatesTimes,
  Extras,
  Location,
  Mileage,
  TaxesFees,
  TotalPrice,
  AdditionalDrivers,
  CouponContract,
} from 'components/RentalDetails';
import { analyticsInteraction } from 'utils/analytics';
import { isDomainCodeLanguageCodeEqual } from 'utils/locations';
import { MODAL, MODAL_THEMES, RESERVATIONS, COMPONENTS, ANALYTICS } from 'constants';
import COUNTRY_CODE from 'constants/countryCode';
import Modal from 'components/Modal';
import Button from 'components/Button';
import IconAlert from 'components/IconAlert';
import CancellationPolicyInformation from 'components/CancellationPolicyInformation';
import cn from 'classnames';
import PostResRenterInformation from 'components/PostRes/PostResRenterInformation';
import EditModalContent from '../EditModalContent';
import { propTypes } from './rentalDetailsPropTypes';

const asterisk = true;
const { LIMITED_INVENTORY } = COMPONENTS.INDIVIDUAL_EXTRA_ALERT;

class ReviewRentalDetails extends Component {
  static propTypes = propTypes;

  state = {
    taxesDrawerExpanded: this.props.isSTCFlow || false,
    altAfterHoursPolicyContent: null,
    isExpanded: false,
  };

  componentDidMount() {
    this.props.reservation && this.setAfterHoursPolicy();
  }

  // there are cases when the after hours policy is not in the reservation
  // this grabs the policy from the reservation, or figures out which location to grab it from
  // policy content is store in component state
  setAfterHoursPolicy = () => {
    const { policies, reservation } = this.props;
    const { after_hours_pickup_selected, after_hours_return_selected, pickup_location, return_location } = reservation;
    this.props
      .getAfterHoursPolicy({
        policies,
        afterHoursReturn: after_hours_return_selected,
        afterHoursPickup: after_hours_pickup_selected,
        pickupLocation: pickup_location,
        returnLocation: return_location,
      })
      .then((altAfterHoursPolicyContent) => {
        altAfterHoursPolicyContent && this.setState({ altAfterHoursPolicyContent });
      });
  };

  // placeholder method for universally handling the edit buttons
  handleEdit = (sectionPath) => () => {
    const { history, fetchCurrentSession, isTrueModify, setProgressBarLocationInitiateState } = this.props;
    const path = RESERVATIONS?.RESFLOW_PATHS_CONFIG?.[sectionPath][0];
    if (path) {
      setProgressBarLocationInitiateState(true);
      history.push(path);

      if (isTrueModify && path === '/car_select') {
        fetchCurrentSession();
      }
    }
  };

  handlePrepayErrorStateEdit = (sectionPath) => () => {
    const { showEditModal } = this.props;
    showEditModal(sectionPath);
  };

  handleToggleTaxesDrawer = () => {
    const { isSTCFlow } = this.props;
    this.setState(({ taxesDrawerExpanded }) => ({ taxesDrawerExpanded: !taxesDrawerExpanded }));

    analyticsInteraction(
      ANALYTICS.DRAWER,
      isSTCFlow ? 'taxes and fees info' : 'taxes and fees',
      !this.state.taxesDrawerExpanded ? ANALYTICS.OPEN : ANALYTICS.CLOSE
    );
  };

  onPaymentModalClose = () => {
    // Analytics
    utils.analytics.interaction(
      ANALYTICS.MODAL, // type
      utils.analytics.prepareKey(MODAL.PREPAY_EDIT_MODAL), // key
      ANALYTICS.CLOSE // value
    );
  };

  render() {
    const {
      reservation,
      extras,
      vehicleRate,
      showLearnMoreModal,
      showViewDetailsModal,
      showAHViewDetailsModal,
      prepayReservationsError,
      cancellation_details,
      resHasLimitedInventoryItemOrVehicle,
      isVehicleLimitedInventory,
      allowLimitedInventoryIconExtra,
      selectedBundleWithExtras,
      extrasWithoutBundles,
      numberOfExtrasSelected,
      isGuaranteedRes,
      coupons,
      hideEditButtons,
      stcModifyButton,
      showModifyModal,
      breakpoint,
      isSTCFlow,
      stepTwo,
      reservationContractAndCoupons,
      history,
    } = this.props;

    if (!reservation || !vehicleRate) {
      return null;
    }

    const { altAfterHoursPolicyContent, taxesDrawerExpanded, isExpanded } = this.state;
    const {
      one_way_rental,
      pickup_location,
      return_location,
      car_class_details,
      pickup_time,
      return_time,
      after_hours_pickup_selected,
      after_hours_return_selected,
      prepay_selected,
    } = reservation;
    const {
      estimated_total_vehicle_view,
      estimated_total_vehicle_payment,
      estimated_total_payment,
      estimated_total_view,
      payment_line_items,
    } = vehicleRate.price_summary;

    const chargedRate = payment_line_items?.VEHICLE_RATE || [];
    const savings = payment_line_items?.SAVINGS || [];
    const { features, images, make_model_or_similar_text, name, mileage_info } = car_class_details;
    const transmission = utils.getTransmissionDescriptionFromFeaturesArray(features);
    const carImage = utils.getImageFromImagesObject(images);
    const prepaymentErrorState = prepay_selected && prepayReservationsError?.length;
    const handleEditByState = prepaymentErrorState ? this.handlePrepayErrorStateEdit : this.handleEdit;
    const freeUpgradeApplied = utils.isFreeUpgradeAvailable(coupons);

    const openCloseResDetailsDrawer = () => {
      this.setState({ isExpanded: !isExpanded });

      analyticsInteraction(ANALYTICS.DRAWER, 'reservation details', !isExpanded ? ANALYTICS.OPEN : ANALYTICS.CLOSE);
    };

    const isFrDomainWithFrLanguage = isDomainCodeLanguageCodeEqual(COUNTRY_CODE.FR, COUNTRY_CODE.FR.toLowerCase());

    return (
      <section className='review-rental-details rental-details'>
        {resHasLimitedInventoryItemOrVehicle && (
          <div className='rental-details__card rental-details__limited-inventory-container'>
            <IconAlert hideToolTip typeOfAlert={LIMITED_INVENTORY} additionalClass='icon-alert__review-page' />
            <p className='rental-details__limited-inventory-header'>{utils.i18n('review_page_limited_inventory')}</p>
          </div>
        )}

        <div
          className={
            breakpoint.isTabletOrMobile
              ? 'rental-details__title rental-details__title--mobile'
              : 'rental-details__title'
          }
        >
          {breakpoint.isTabletOrMobile && isSTCFlow ? (
            <div className='rental-details__heading'>
              <Button
                onClick={() => openCloseResDetailsDrawer()}
                className={cn('rental-details__drawer rental-details__heading--stc--mobile', {
                  'rental-details__drawer--expanded': isExpanded,
                })}
                button={false}
                ariaExpanded={isExpanded.toString()}
              >
                {utils.i18n('confirmation_reservation_details')}
              </Button>
            </div>
          ) : (
            <>
              {!stcModifyButton || stepTwo ? (
                <div className='rental-details__heading'>{utils.i18n('rental_details_title')}</div>
              ) : (
                <div className='rental-details__section-label rental-details__section-label--stc'>
                  <span className='rental-details__heading rental-details__heading--stc'>
                    {utils.i18n('confirmation_reservation_details')}
                  </span>
                  <Button link onClick={showModifyModal}>
                    {utils.i18n('confirmation_reservation_modify_button')}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>

        <div
          className={cn('rental-details__content', {
            'rental-details__content--open': breakpoint.isTabletOrMobile && isSTCFlow ? isExpanded : true,
          })}
        >
          <div className='rental-details__card'>
            <DatesTimes
              pickupTime={pickup_time}
              returnTime={return_time}
              handleEdit={isSTCFlow ? () => showModifyModal : handleEditByState}
              hideEditButtons={hideEditButtons}
              stepTwo={stepTwo}
              isTabletOrMobile={breakpoint.isTabletOrMobile}
              afterHoursReturn={after_hours_return_selected}
              afterHoursPickup={after_hours_pickup_selected}
              showAHViewDetailsModal={showAHViewDetailsModal}
              dateTimeInLowerCase={isFrDomainWithFrLanguage}
            />

            <Location
              oneWayRental={one_way_rental}
              pickupLocation={pickup_location}
              returnLocation={return_location}
              handleEdit={isSTCFlow ? () => showModifyModal : handleEditByState}
              hideEditButtons={hideEditButtons}
              stepTwo={stepTwo}
              isTabletOrMobile={breakpoint.isTabletOrMobile}
              isSTCFlow={isSTCFlow}
            />

            <CouponContract
              handleEdit={!isSTCFlow && handleEditByState}
              hideEditButtons={hideEditButtons}
              stepTwo={stepTwo}
              isTabletOrMobile={breakpoint.isTabletOrMobile}
              reservationContractAndCoupons={reservationContractAndCoupons}
              history={history}
            />

            {stepTwo && (
              <div className='rental-details__section'>
                <PostResRenterInformation leftSideSTC />
              </div>
            )}
          </div>

          <Mileage mileageInfo={mileage_info} className='rental-details__mileage-review-rental-details' />

          <div className='rental-details__card'>
            <Vehicle
              name={name}
              makeModelOrSimilarText={make_model_or_similar_text}
              transmission={transmission}
              carImage={carImage}
              chargedRate={chargedRate}
              estimatedTotalVehicleView={estimated_total_vehicle_view}
              estimatedTotalVehiclePayment={estimated_total_vehicle_payment}
              isVehicleLimitedInventory={isVehicleLimitedInventory}
              asterisk={asterisk}
              handleEdit={handleEditByState}
              hideEditButtons={hideEditButtons}
              selectedBundleWithExtras={selectedBundleWithExtras}
              isGuaranteedRes={isGuaranteedRes}
              freeUpgradeApplied={freeUpgradeApplied}
              shouldDisplayUpgradeOption
              features={features}
              isSTCFlow={isSTCFlow}
            />

            <Extras
              extras={extras}
              allowLimitedInventoryIconExtra={allowLimitedInventoryIconExtra}
              numberOfExtrasSelected={numberOfExtrasSelected}
              handleEdit={handleEditByState}
              hideEditButtons={hideEditButtons}
              asterisk={asterisk}
              selectedBundleWithExtras={selectedBundleWithExtras}
              extrasWithoutBundles={extrasWithoutBundles}
            />

            <AdditionalDrivers />

            <TaxesFees
              isReview
              taxesDrawerExpanded={taxesDrawerExpanded}
              priceSummary={vehicleRate.price_summary}
              handleViewDetailsModal={showViewDetailsModal}
              handleToggleTaxesDrawer={this.handleToggleTaxesDrawer}
              asterisk={asterisk}
            />

            <TotalPrice
              isReview
              estimatedTotalView={estimated_total_view}
              estimatedTotalPayment={estimated_total_payment}
              showLearnMoreModal={showLearnMoreModal}
              adjustments={savings}
              freeUpgradeApplied={freeUpgradeApplied}
              asterisk={asterisk}
              isPayLater={!prepay_selected}
              stepTwo={stepTwo}
            />

            {!isSTCFlow && !isGuaranteedRes && (
              <CancellationPolicyInformation numberOfHoursFullRefund={cancellation_details?.fee_dead_line_in_hours} />
            )}
          </div>
        </div>

        <Modal
          modalKey={MODAL.RENTAL_DETAILS_AFTER_HOURS_MODAL}
          header={utils.i18n('rental_details_after_hours_modal_header')}
          theme={MODAL_THEMES.WHITE}
        >
          <div
            className='modal-themed__section modal-themed__gbo-content'
            dangerouslySetInnerHTML={utils.sanitize(altAfterHoursPolicyContent)}
          />
        </Modal>

        <Modal
          modalKey={MODAL.PREPAY_EDIT_MODAL}
          header={utils.i18n('prepayment_edit_modal_header')}
          theme={MODAL_THEMES.WHITE}
          onClose={this.onPaymentModalClose}
        >
          <EditModalContent handleEdit={this.handleEdit} />
        </Modal>
      </section>
    );
  }
}

export default ReviewRentalDetails;
