import React from 'react';
import { useSelector } from 'react-redux';
import { actions } from '@ehi/global-marketing-interface';
import PropTypes from 'prop-types';
import utils from 'utils';
import { i18n } from 'utils/i18n';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import RESERVATIONS from 'constants/reservations';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import ANALYTICS from 'constants/analytics';
import Modal from 'components/Modal';
import Button from 'components/Button';
import useActions from 'hooks/useActions';
import { setExpandedTab, setMobileExpanded } from 'actions/progressBar';
import { showSoldOutNearbyLocations, setLastValidLocationSearched } from 'actions/locationFinder';
import { breakpointSelector } from 'selectors/breakpoint';
import { getProgressBarExpandedTab } from 'selectors/reservationFlowSelectors';
import {
  getInitialPickupLocation,
  isInitiateDateTimeisChanged,
  dateTimeInitiateRequestValuesSelector,
} from 'selectors/progressBarSelectors';

const { EXCHANGE_TYPE_PICKUP, EXCHANGE_TYPE_RETURN } = RESERVATIONS;

import './styles/sold-out-modal.scss';

/**
 * SoldOutModal
 * Modal that displays the sold out message for a specific location.
 *
 * @param {object} props - React Props
 *
 * @return {JSX} SoldOutModal component
 */
const SoldOutModal = ({ currentPage, onDateChange, onTimeChange, resetPickuplocationToMatchWithGBO }) => {
  const dispatchSetExpandedTab = useActions(setExpandedTab);
  const dispatchSetMobileExpanded = useActions(setMobileExpanded);
  const dispatchShowNearbyLocations = useActions(showSoldOutNearbyLocations);
  const dispatchLastValidLocationSearched = useActions(setLastValidLocationSearched);
  const cleanMessagesAction = useActions(actions.cleanMessages);
  const { isMobile } = useSelector(breakpointSelector);
  const expandedTab = useSelector(getProgressBarExpandedTab);
  const gmaPickupLocation = useSelector(getInitialPickupLocation);

  const getInitiateDateTimeisChanged = useSelector(isInitiateDateTimeisChanged);
  const getDateTimeInitiateRequestValuesSelector = useSelector(dateTimeInitiateRequestValuesSelector);

  const handleAdjustDateTime = (handleClose) => () => {
    // check if it's inside reservation flow (not on the booking widget)
    if (currentPage) {
      utils.goTo(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESERVATION_INFLOW), { hash: 'start' });
      cleanMessagesAction();
    }
    utils.analytics.interaction(
      ANALYTICS.MODAL,
      utils.analytics.prepareKey(MODAL.SOLD_OUT_MODAL),
      ANALYTICS.ADJUST_DATE_TIME
    );
    handleClose({ skipAnalytics: true });
  };

  const handleSeeNearbyLocations = (handleClose) => () => {
    const locationSelectPage = RESERVATIONS.RESFLOW_PATHS_CONFIG.location[0];

    // If it's not location select page, redirect to location select page
    if (currentPage !== locationSelectPage) {
      utils.goTo(utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.RESERVATION_INFLOW), { hash: locationSelectPage });
    }
    // If it's inside reservation flow (not on the booking widget), on close modal should make sure it closes progress bar
    if (currentPage) {
      !!expandedTab && dispatchSetExpandedTab(null);
      !!expandedTab && isMobile && dispatchSetMobileExpanded(false);
      dispatchShowNearbyLocations(true);
      dispatchLastValidLocationSearched(gmaPickupLocation?.id);
    }
    // used to check if filter by available locations should be selected by default
    sessionStorage.setItem('soldOutLocation', true);
    utils.analytics.interaction(
      ANALYTICS.MODAL,
      utils.analytics.prepareKey(MODAL.SOLD_OUT_MODAL),
      ANALYTICS.SEE_NEARBY_LOCATIONS
    );
    handleClose({ skipAnalytics: true });
    // pickup/return datetime is different than from GBO, should update with GBO info
    // reset gmi > ui, to match what's in gbo
    if (getInitiateDateTimeisChanged && getDateTimeInitiateRequestValuesSelector) {
      const { pickupDate, pickupTime, returnDate, returnTime } = getDateTimeInitiateRequestValuesSelector;
      onDateChange(pickupDate, EXCHANGE_TYPE_PICKUP);
      onTimeChange(pickupTime, EXCHANGE_TYPE_PICKUP);
      onDateChange(returnDate, EXCHANGE_TYPE_RETURN);
      onTimeChange(returnTime, EXCHANGE_TYPE_RETURN);
    }
  };

  const handleCustomClose = () => {
    const locationSelectPage = RESERVATIONS.RESFLOW_PATHS_CONFIG.location[0];

    // If it's on vehicle select page, reset progress bar location to original selected location
    if (currentPage && currentPage !== locationSelectPage) {
      !!expandedTab && dispatchSetExpandedTab(null);
      !!expandedTab && isMobile && dispatchSetMobileExpanded(false);
      resetPickuplocationToMatchWithGBO?.();
    }
    cleanMessagesAction();
  };

  return (
    <Modal
      modalKey={MODAL.SOLD_OUT_MODAL}
      customClass='sold-out-modal'
      header={i18n('sold_out_modal_title')}
      theme={MODAL_THEMES.WHITE}
      customActionOnClose={handleCustomClose}
    >
      {({ handleClose }) => (
        <div className='modal-themed__section'>
          <div className='modal-themed__section-decline'>
            <p className='modal-themed__paragraph'>{i18n('sold_out_keep_this_location')}</p>
            <Button onClick={handleAdjustDateTime(handleClose)}>{i18n('sold_out_adjust_date')}</Button>
          </div>

          <div className='modal-themed__section-seperator'>
            <span className='modal-themed__section-seperator-text'>{i18n('store_finder_divider_text')}</span>
          </div>

          <div className='modal-themed__section-accept'>
            <p className='modal-themed__paragraph'>{i18n('sold_out_browse_nearby_locations')}</p>
            <Button onClick={handleSeeNearbyLocations(handleClose)}>{i18n('sold_out_see_nearby_locations')}</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

SoldOutModal.propTypes = {
  currentPage: PropTypes.string,
  resetPickuplocationToMatchWithGBO: PropTypes.func,
};

export default SoldOutModal;
