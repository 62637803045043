import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';
import ConfirmationContainer from './ConfirmationContainer';

const ConfirmationEditableConfig = {
  emptyLabel: 'Confirmation Component',
  isEmpty: (props) => !props, // TODO: what determines this?
};
export default MapTo('aweb/components/content/confirmation')(
  spreadModelProps(ConfirmationContainer),
  ConfirmationEditableConfig
);
