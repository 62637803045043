import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Form from 'components/Form/Form';
import utils from 'utils';
import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { ASSETS, RESERVATIONS, GLOBAL, MODAL_THEMES, MODAL, SCROLL, PROFILE } from 'constants';
import PoliciesComponent from 'components/PoliciesComponent';
import PrepayTermsModal from 'components/PrepayTermsModal';
import ServiceErrors from 'components/ServiceErrors';
import FormErrors from 'components/FormErrors';
import Title from 'components/Title';
import SignInModal from 'components/modals/SignInModal';
import AdditionalInfoQuestions from 'components/AdditionalInfoQuestions/AdditionalInfoQuestions';
import Button from 'components/Button';
import Modal from 'components/Modal';
import PrePayPaymentSection from 'components/ReservationFlow/Review/PaymentSection/PrePayPaymentSection';
import KeyRentalFacts from 'components/KeyRentalFacts';
import GuaranteedResIframeModal from 'components/modals/GuaranteedResIframeModal';
import InPathVehicleUpgradeReview from 'components/ReservationFlow/InPathVehicleUpgrade/InPathVehicleUpgradeReview';
import AdditionalDrivers from 'components/AdditionalDrivers';
import InResAcceleratedCheckinWizardModal from 'components/modals/InResAcceleratedCheckinWizardModal';
import InResAcceleratedCheckinEditModal from 'components/modals/InResAcceleratedCheckinEditModal';
import InResAcceleratedCheckinPartialProfileModal from 'components/modals/InResAcceleratedCheckinPartialProfileModal';
import LearnMoreAboutOCIInactiveModal from 'components/modals/LearnMoreAboutOCIInactiveModal';
import DoNotRentModal from 'components/modals/DoNotRentModal';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import ReviewRentalDetails from './ReviewRentalDetails';
import DriverDetailsCard from './DriverDetailsCard';
import FieldGroupCard from './FieldGroupCard';
import PrepaymentReadyDriverCard from './PrepaymentReadyDriverCard';
import BillingAccountCard from './BillingAccountCard';
import PartnerRewardsCard from './PartnerRewardsCard';
import { PayLaterConfirmationCard, PrepayConfirmationCard } from './ConfirmationCard';
import AirlineDetailsCard from './AirlineDetailsCard';
import GenricPolicyContentModal from './GenricPolicyContentModal/GenricPolicyContentModal';
import GuaranteedReservationAddCard from './GuaranteedReservationAddCard';
import CheckInCard from './CheckInCard/CheckInCard';

const { EXISTING_PREPAY, CONFIRM_PREPAY, CARD_FORM, CARD_ALERT } = RESERVATIONS.PREPAY_UI_STEPS;

/**
 * Complete component for Review Page
 *
 * @param {object}    props - React Props
 * @param {boolean}   props.status - Selected car class status
 * @param {boolean}   props.hasLimitedInventoryExtra - If any limited invetory extras was added to the reservation
 * @param {array}     props.extras - List of selected extras
 * @param {boolean}   props.reservationReady - Flag for when there is a reservation object
 * @param {object}    props.breakpoint - Selectors for windows breakpoint
 * @param {boolean}   props.isAirport - Flag for pickup location with type airport
 * @param {boolean}   props.isMultiTerminalAirport - Flag for airports with multiple Alamo locations
 * @param {boolean}   props.isPayLater - Flag for pay later selected
 * @param {boolean}   props.isAfterHoursPickup - Flag if the pickup is set for a after hour for the selected location
 * @param {object}    props.commitState - Commit object of the reservation
 * @param {object}    props.vehicleRate - Selected car rates
 * @param {array}     props.policies - Available policies for the reservation
 * @param {array}     props.airlineOptions - List of airlines options
 * @param {function}  props.reviewCommit - Commit reviewed reservation
 * @param {function}  props.showLearnMoreModal - Function to open modal with more details about the reservation
 * @param {object}    props.oppositeVehicleRate - Car rates for the opposite payment type (prepay/paylater)
 * @param {function}  props.showPrePayTermsModal - Function to open modal with prepay terms
 * @param {function}  props.setPostRateAdditionalInformation - Function to set reservation additional informations
 * @param {object}    props.contractDetails - contractDetails data object created by contractDetailsSelector
 * @param {boolean}   props.contractDetails.contractAcceptsBilling - Flag for applied contracts with billing payment method
 * @param {string}    props.contractDetails.contractBillingAccount - Account for billing to a applied contract
 * @param {array}     props.contractDetails.contractRequirements - Reservation contract additional informations
 * @param {boolean}   props.contractDetails.defaultLoyaltyContract - indicates whether current contract is Alamo Insider
 * @param {function}  props.clearBillingAccount - Clear billing information
 * @param {boolean}   props.isTrueModify - Flag for reservation in true modify state
 * @param {function}  props.prepayFlopCurrentSession - Toggle between payment methods (prepay/paylater)
 * @param {function}  props.removeInflightPaymentDetails - Remove payment details from GMI state
 * @param {object}    props.paymentCardData - Credit card payment information
 * @param {array}     props.additionalInformation - Reservation additional information
 * @param {string}    props.reservationEmail - Driver information email from reservation
 * @param {object}    props.reservationBillingAccount - Selected billing account
 * @param {string}    props.reservationBillingAccount.billing_account_number - Billing account number
 * @param {string}    props.reservationBillingAccount.billing_account_type - Billing account type
 * @param {object}    props.partner_reward_program - Object with Partner Rewards Program details
 * @param {function}  props.updateCommitState - Function to set reservation commit state
 * @param {function}  props.setDriveProfileState - Function (action) to set profile state
 * @param {object}    props.airlineInfo - Reservation fligh informations
 * @param {string}    props.reservationPhone - Reservation phone number
 * @param {object}    props.preselectedDriverInfo - Pre selected driver informations
 * @param {array}     props.modalRequiredPolicies - Required Policies modal informations
 * @param {boolean}   props.collectNewPaymentCard - Flag if a new payment card should be collected or not
 * @param {function}  props.openRequiredModal - opens REQUIRED_MODAL_POLICY passing in relevant policy props
 * @param {boolean}   props.guaranteedResCardRequired - determines whether to show guaranteed res section based on session flags
 * @param {function}  props.showCouponDoesNotApplyModal - Function to open coupon does not apply modal
 * @param {array}     props.selectedCarCoupons - Selected car class array of coupons
 * @param {boolean}   props.isDeeplink - flag to check if it is a deeplink
 * @param {function}  props.showDoNotRentModal - Function to open modal for Profile with Do Not Rent Indicator: true
 * @return {JSX}      Review component
 */
class Review extends Component {
  state = {
    addFlightInfo: false,
    airlineNotListed: false,
    hasNoFlight: false,
    initialFormValues: {
      driver_info: {
        first_name: null,
        last_name: null,
        email_address: null,
        request_email_promotions: null,
        phone_number: null,
      },

      country: utils.config.getDomainCountry(),
      country_subdivision: null,
      issuing_authority: null,
      driver_license_number: null,
      license_info: {
        country_code: null,
        country_subdivision_code: null,
        issuing_authority: null,
        license_number: null,
      },
      license_issue_date: null,
      license_expiration_date: null,
      contact_info: {
        email: null,
        phone_set: null,
      },
      address_info: {
        address_line_1: null,
        address_line_2: null,
        country_code: null,
        country_subdivision_code: null,
        city: null,
        postal: null,
      },
      sortBy: this.props.isSelectedInResOCISaveTime ? 'yes' : this.props.isSelectedInResOCISaveTime,
      auto_comms_consent_opt_in: utils.config.isNADomain() ? 'true' : null,
    },
    prepayStep: null,
    sourceCode: null,
    prepayFailed: false,
    isEditing: false,
    showFormError: true,
    showUpdatedExpeditedEmailValue: null,
  };

  // `<Form />` ref...
  _form = createRef();

  _formError = createRef();

  _formSection = createRef();

  pageStatus = document.getElementById('pageStatus');

  formState = {};

  componentDidMount() {
    const {
      reservationReady,
      modalRequiredPolicies,
      gmaAdditionalDrivers,
      gboAdditionalDrivers,
      addAdditionalDriversOnReservation,
      getInPathVehicleUpgradeDetails,
      isTrueModify,
      showSMSConsent,
      showEuImpliedConsent,
      autoCommsSmsOptInValue,
      updateCommitState,
    } = this.props;

    // Adds the additional driver info into the GBO session object if there's drivers on GMA session and no drivers on GBO
    if (gmaAdditionalDrivers.length && !gboAdditionalDrivers.length) {
      addAdditionalDriversOnReservation(gmaAdditionalDrivers);
    }

    if (reservationReady) {
      this.setupInitialFormState();
      this.setInitialPrepayStep();

      if (utils.gmi.isArrayNotEmpty(modalRequiredPolicies)) {
        this.openRequiredModals();
      }

      // when there were changes in a reservation, refetch vehicle upgrade details
      // to get the latest available vehicles to upgrade for the new reservation information
      if (isTrueModify) {
        getInPathVehicleUpgradeDetails();
      }
    }

    // To ensure assistive technology users or to notify that the page has been updated, within Reservation flow
    this.pageStatus.setAttribute('aria-live', 'polite');
    this.pageStatus.innerText = utils.i18n('review_page_title');

    setTimeout(() => {
      this.pageStatus.innerText = '';
      this.pageStatus.setAttribute('aria-live', 'off');
    }, 15000);

    // auto_comms_consent_opt_in  updating initial commit state
    if (!isTrueModify) {
      if (showSMSConsent) {
        const value = utils.gmi.types(autoCommsSmsOptInValue)?.isDefined
          ? autoCommsSmsOptInValue
          : utils.config.isNADomain();
        updateCommitState(RESERVATIONS.AUTO_COMMS_CONSENT_OPT_IN, value);
      }

      if (showEuImpliedConsent) {
        updateCommitState(RESERVATIONS.AUTO_COMMS_CONSENT_OPT_IN, true);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      reservationReady,
      isPayLater,
      modalRequiredPolicies,
      collectNewPaymentCard,
      reservationEmail,
      reservationPhone,
      pickupLocation,
      returnLocation,
      reservationBillingAccount,
      isTrueModify,
      contractDetails,
      commitState,
      profile,
      showEmailSpecials,
      requestEmailSpecialState,
      updateCommitState,
    } = this.props;

    const reservationLoaded = reservationReady && !prevProps.reservationReady;
    /* flop is our nickname for payment method toggle, this condition is true when user calls the prepayFlop service
     * which updates the session prepay_selected flag, when reservation initially loads as prepay or when user makes
     * a change in the reservation at the review page (changing dates in progress bar)
     */
    const flop = isPayLater !== prevProps.isPayLater || collectNewPaymentCard !== prevProps.collectNewPaymentCard;

    if (reservationLoaded) {
      this.setupInitialFormState();
      // `openRequiredModals` is called in two cases (and places):
      // 1. Normal resflow when you land on the Review page (this is covered by the `componentDidMount`)
      // 2. Page refresh (covered here). In this case the component mounts but the needed data is not here, so we need to
      //    wait until the data comes back but _after_ the reservation has also loaded...
      if (utils.gmi.isArrayNotEmpty(modalRequiredPolicies)) {
        this.openRequiredModals();
      }
    }
    if (flop) {
      this.setInitialPrepayStep();
    }

    if (prevProps.profile?.license_profile?.license_number !== this.props.profile?.license_profile?.license_number) {
      this.setupInitialFormState();
    }

    if (
      (reservationBillingAccount !== prevProps.reservationBillingAccount ||
        (isTrueModify && !reservationBillingAccount)) &&
      !this.state.initialFormValues?.billing
    ) {
      const { contractBillingAccount } = contractDetails || {};
      let billingDetails = {};
      if (!isTrueModify && contractBillingAccount) {
        billingDetails = { selection: 'billing-number' };
      }
      if (reservationBillingAccount) {
        billingDetails = {
          selection: 'billing-number',
          billing_account_number: reservationBillingAccount?.billing_account_number,
        };
      }

      if (isTrueModify && !reservationBillingAccount) {
        billingDetails = { ...billingDetails, selection: 'pay-at-pickup' };
      }

      this.setState({
        initialFormValues: {
          ...this.state.initialFormValues,
          billing: { ...billingDetails },
        },
      });
    }

    if (reservationEmail !== prevProps.reservationEmail || reservationPhone !== prevProps.reservationPhone) {
      this.setState({
        initialFormValues: {
          ...this.state.initialFormValues,
          driver_info: {
            ...this.state.initialFormValues.driver_info,
            email_address: reservationEmail,
            phone_number: reservationPhone,
          },
        },
      });
    }
    // comparing  pickup_location with props whenever location is updated.
    if (
      pickupLocation &&
      prevProps.pickupLocation &&
      (prevProps.pickupLocation?.name !== pickupLocation?.name ||
        prevProps.returnLocation?.name !== returnLocation?.name)
    ) {
      this.setState({
        addFlightInfo: false,
        initialFormValues: {
          ...this.state.initialFormValues,
          airline_information: null,
        },
      });
    }

    const { errors } = this.formState?.getState() || {};
    if (!gmiUtils.isObjectEmpty(errors) && Object.keys(errors).length > 1 && !this.state.showFormError) {
      this.handleShowFormError();
    }
    // If driver email address is updated during expedited check in flow , updating driver email address
    const { initialFormValues } = this.state;
    if (
      reservationEmail &&
      initialFormValues?.driver_info?.email_address &&
      commitState?.driver_info?.email_address &&
      profile?.contact_profile?.email &&
      initialFormValues.driver_info.email_address !== commitState.driver_info.email_address
    ) {
      this.setState({
        initialFormValues: {
          ...initialFormValues,
          driver_info: {
            ...this.state.initialFormValues.driver_info,
            email_address: commitState.driver_info.email_address,
          },
        },
        showUpdatedExpeditedEmailValue: commitState.driver_info.email_address,
      });
    }

    const requestEmailPromitionsInitialValue = !showEmailSpecials ? null : requestEmailSpecialState;

    if (
      isTrueModify &&
      !showEmailSpecials &&
      requestEmailSpecialState &&
      commitState?.driver_info?.request_email_promotions !== requestEmailPromitionsInitialValue
    ) {
      updateCommitState('request_email_promotions', requestEmailPromitionsInitialValue);
    }
  }

  openRequiredModals = () => {
    const { modalRequiredPolicies, openRequiredModal } = this.props;
    modalRequiredPolicies.forEach((policy) => {
      openRequiredModal({
        policy_text: policy?.policy_text,
        header: policy?.description,
      });
    });
  };

  setInitialPrepayStep = () => {
    const { isPayLater, paymentCardData, collectNewPaymentCard } = this.props;
    if (isPayLater && this.state.prepayStep) {
      this.setState({ prepayStep: null });
    } else if (!isPayLater) {
      this.setState({
        prepayStep: paymentCardData && !collectNewPaymentCard ? EXISTING_PREPAY : CONFIRM_PREPAY,
      });
    }
  };

  setupInitialFormState = (formValues) => {
    // This method checks session values needed for True Modify from existing session object and hydrates form state after
    // retrieving reservation object
    const {
      airlineInfo,
      contractDetails,
      reservationEmail,
      reservationPhone,
      preselectedDriverInfo,
      reservationBillingAccount,
      isTrueModify,
      partner_reward_program,
      updateCommitState,
      profile,
      commitState,
      additionalInfoDetails,
      requestEmailSpecialState,
      showEmailSpecials,
    } = this.props;
    const { contractBillingAccount } = contractDetails || {};
    const {
      country_code,
      license_number,
      country_subdivision_code,
      issuing_authority,
      license_issue_date,
      license_expiration_date,
      birth_date,
    } = profile.license_profile;

    const { email, phones } = profile.contact_profile;

    const {
      street_addresses,
      country_subdivision_code: address_country_subdivision_code,
      city,
      postal,
      country_code: address_country_code,
    } = profile.address_profile;

    const requestEmailPromitionsInitialValue = !showEmailSpecials ? null : requestEmailSpecialState;

    // Placing hydrate logic into a timeout to delay it so that state and form values after mount are available
    // and setting initialFormState here does not overwrite any default values set by individual fields on mount
    setTimeout(() => {
      const newState = {
        initialFormValues: formValues || this.formState?.getState().values,
      };

      // default value for request_email_promotions as true if is an US domain
      if (requestEmailSpecialState) {
        updateCommitState('request_email_promotions', requestEmailPromitionsInitialValue);
      }

      if (reservationEmail || reservationPhone) {
        // True modify only shows email and phone fields so checking both values and hydrating form state with them.
        const driver_info = {};
        const { initialFormValues } = newState;
        if (reservationEmail) {
          driver_info.email_address =
            reservationEmail &&
            initialFormValues?.driver_info?.email_address &&
            initialFormValues?.driver_info?.email_address !== reservationEmail
              ? initialFormValues?.driver_info?.email_address
              : reservationEmail;
        }

        if (reservationPhone) {
          const { phone: { phone_number } = {} } = commitState?.driver_info || {};

          driver_info.phone_number = reservationPhone;
          // initial state to set phone number field
          if (
            phone_number &&
            phone_number.length > 0 &&
            ((!reservationPhone && phone_number.length > 0) ||
              (utils.fieldValidation.isMasked(reservationPhone) && !utils.fieldValidation.isMasked(phone_number)) ||
              reservationPhone !== phone_number)
          ) {
            driver_info.phone_number = phone_number;
          }
        }

        newState.initialFormValues.driver_info = driver_info;
      } else if (preselectedDriverInfo) {
        const { first_name, last_name, email_address, phone } = preselectedDriverInfo;
        newState.initialFormValues.driver_info = preselectedDriverInfo;
        newState.initialFormValues.driver_info.phone_number = phone?.phone_number || null;
        // Preselected driver info must be set in Commit State as well
        first_name && updateCommitState('first_name', first_name);
        last_name && updateCommitState('last_name', last_name);
        email_address && updateCommitState('email_address', email_address);
        phone?.phone_number && updateCommitState('phone', { phone_number: phone?.phone_number });
      }
      if (airlineInfo) {
        newState.addFlightInfo = true;
        newState.initialFormValues.airline_information = {
          code: airlineInfo.code,
          flight_number: airlineInfo.flight_number,
        };
      }
      if (partner_reward_program) {
        newState.initialFormValues.partner_reward_program = {
          member_id: partner_reward_program.member_id,
          code: partner_reward_program.code,
        };
      }
      if (!isTrueModify && contractBillingAccount) {
        newState.initialFormValues.billing = { selection: 'billing-number' };
      }
      if (additionalInfoDetails) {
        newState.initialFormValues = { ...newState.initialFormValues, ...additionalInfoDetails };
      }
      if (reservationBillingAccount) {
        const { billing_account_number } = reservationBillingAccount;
        newState.initialFormValues.billing = {
          selection: 'billing-number',
          account: billing_account_number,
        };
      }
      if (isTrueModify && !reservationBillingAccount) {
        newState.initialFormValues.billing = { selection: 'pay-at-pickup' };
      }

      if (profile) {
        newState.initialFormValues.first_name = profile?.basic_profile?.first_name;
        newState.initialFormValues.last_name = profile?.basic_profile?.last_name;
        newState.initialFormValues.country = country_code || utils.config.getDomainCountry();
        newState.initialFormValues.country_subdivision = utils.fieldValidation.getIfNotMasked(
          profile?.license_profile.country_subdivision_code
        );
        newState.initialFormValues.issuing_authority = utils.fieldValidation.getIfNotMasked(
          profile?.license_profile.issuing_authority
        );
        newState.initialFormValues.driver_license_number = utils.fieldValidation.getIfNotMasked(
          profile?.license_profile.license_number
        );
      }

      if (profile?.license_profile) {
        newState.initialFormValues.license_info = {
          country_code,
          country_subdivision_code,
          issuing_authority,
          license_number,
        };
        newState.initialFormValues.license_issue_date = utils.gmi.parseGBODate(license_issue_date);
        newState.initialFormValues.license_expiration_date = utils.gmi.parseGBODate(license_expiration_date);
        newState.initialFormValues.birth_date = utils.gmi.parseGBODate(birth_date);
      }
      if (profile?.contact_profile) {
        newState.initialFormValues.contact_info = {
          email: email.length > 0 ? email : commitState?.driver_info?.email_address,
          phone_set: phones.length > 0 ? phones : [{ ...commitState?.driver_info?.phone }],
        };
      }
      if (profile?.address_profile) {
        newState.initialFormValues.address_info = {
          address_line_1: street_addresses[0],
          address_line_2: street_addresses[1],
          country_code: address_country_code || profile?.license_profile?.country_code,
          country_subdivision_code:
            address_country_subdivision_code || profile?.license_profile?.country_subdivision_code,
          city,
          postal,
        };
      }
      this.setState(newState);
    });
  };

  reservationCommit = () => {
    const { isPayLater, reviewCommit } = this.props;
    const { prepayStep } = this.state;
    const sourceCode = PROFILE.PREFERENCE_SOURCE_CODE.RES_PATH;

    if (isPayLater || prepayStep === CARD_ALERT || prepayStep === EXISTING_PREPAY || isPayLater) {
      reviewCommit({ sourceCode });
    } else if (prepayStep === CONFIRM_PREPAY) {
      this.setState({
        sourceCode,
        prepayStep: CARD_FORM,
      });
    }
  };

  handlePaymentIframeSuccess = () => {
    const { reviewCommit } = this.props;
    const { sourceCode } = this.state;
    this.setState({ prepayFailed: false }, () => {
      reviewCommit({ sourceCode }).then(() => {
        // if we reach "then", the redirect didn't happen
        // if we secured payment, block navigation
        // TODO: we always redirect, need to stop that and confirm that payment was secured DDIAZ
        this.setState({ prepayStep: CARD_ALERT });
      });
    });
  };

  handlePaymentIframeFailure = () => {
    const { removeInflightPaymentDetails } = this.props;
    this.setState({ prepayFailed: true });
    removeInflightPaymentDetails({ cardSubmissionUrl: true });
  };

  handleToggleFlightInfo = () => this.setState(({ addFlightInfo }) => ({ addFlightInfo: !addFlightInfo }));

  handleToggleAirlineNotListed = () =>
    this.setState(({ airlineNotListed }) => ({ airlineNotListed: !airlineNotListed }));

  flopCurrentSession = (form) => {
    const { prepayFlopCurrentSession, isPayLater } = this.props;
    const { errors } = form.getState();
    const keys = Object.keys(errors);
    const showError = !(!isPayLater && keys.length === 1 && Object.hasOwn(errors?.payment_info, 'accept_prepay_terms'));
    prepayFlopCurrentSession();
    // This will manually reset the value of the T&C checkbox, in case it was checked before swapping
    form?.change('payment_info.accept_prepay_terms', false);
    this.setState({ prepayFailed: false, showFormError: showError });
  };

  handleTogglePrepay = (form) => {
    const { isPayLater, selectedCarCoupons, showCouponDoesNotApplyModal } = this.props;
    // Should show modal if current rate is pay-later with coupon applied and will change to pre-pay
    const shouldShowCouponModal = isPayLater && selectedCarCoupons.length > 0;

    shouldShowCouponModal
      ? showCouponDoesNotApplyModal((continueWithoutCoupon) => {
          continueWithoutCoupon && this.flopCurrentSession(form);
        })
      : this.flopCurrentSession(form);
  };

  returnToConfirmPrepay = () => {
    this.setState({ prepayStep: CONFIRM_PREPAY });
  };

  curriedUpdateCommitState = (stateKey) => (e) => {
    const { updateCommitState } = this.props;

    // The value is either e.target.value for inputs or just the param in the case of the ResponsiveListBox airline selector
    const value = e?.target ? e.target.value : e;

    updateCommitState(stateKey, value);

    if (stateKey === 'phone') {
      this.setState({
        initialFormValues: {
          ...this.state.initialFormValues,
          driver_info: {
            ...this.state.initialFormValues.driver_info,
            phone_number: value?.phone_number,
          },
        },
      });
    }
  };

  updateDriverProfileState = (stateKey) => (e) => {
    const { setDriveProfileState } = this.props;
    const value = e.target ? e.target.value : e;
    const newPhones = [
      {
        ...this.state.initialFormValues.contact_info.phone_set[0],
        phone_number: value,
      },
    ];
    this.formState.change('contact_info.phone_set[0].phone_number', value);
    setDriveProfileState(stateKey, newPhones);
    this.setState({
      ...this.state.initialFormValues,
      contact_info: {
        ...this.state.initialFormValues.contact_info,
        phoneSet: newPhones,
      },
    });
  };

  handleValidSubmit = (values) => {
    this.reservationCommit(values);
  };

  handleInvalidSubmit = () => {
    const {
      breakpoint: { isTabletOrMobile },
    } = this.props;
    const { errors } = this.formState && this.formState.getState();

    // Only scroll if there's an error on driver details form
    if (errors.driver_info) {
      // scroll needs to be in a setTimeout to wait for the invalid submit to render the error
      const currentTarget = isTabletOrMobile ? this._formSection.current : this._formError.current;
      if (currentTarget) {
        setTimeout(() => utils.dom.scrollPage(0, currentTarget.offsetTop - SCROLL.HEADER_CLEARANCE), 500);
      }
    }

    if (Object.keys(errors).length > 0) {
      this.handleShowFormError();
    }
  };

  showPrePayTermsModal = (e) => {
    const { showPrePayTermsModal } = this.props;
    //  We need prevent default otherwise we check the check box when clicking to open
    // the modal
    e.preventDefault();
    showPrePayTermsModal();
  };

  finalFormMutators = {
    handleSetAirline:
      ([code], state) =>
      () => {
        // if passed a code, set code in Redux and clear flight_number. If code is null, user has switched back to manual
        // entry and need to rehydrate flight_number from final form state. Airline code gets rehydrated automatically by
        // airline code field (ResponsiveListBox) on mount
        if (code) {
          this.props.updateCommitState('code', code);
          this.props.updateCommitState('flight_number', undefined); // eslint-disable-line no-undefined
        }
        if (code === null) {
          this.props.updateCommitState('flight_number', state.formState.values.airline_information?.flight_number);
        }
        this.handleToggleAirlineNotListed();
        this.setState({ hasNoFlight: code === RESERVATIONS.AIRLINE_NO_FLIGHT });
      },
  };

  isEditingProfile = (value) => {
    this.setState({
      isEditing: value,
    });
  };

  handleShowFormError = () => {
    this.setState({
      showFormError: true,
    });
  };

  handleAdditionalInfoUpdates = (values) => {
    if (values) {
      this.props.setGMIUICommitAdditionalInformation(values);
    }
  };

  render() {
    const {
      reservationReady,
      breakpoint,
      isAirport,
      isMultiTerminalAirport,
      isPayLater,
      isAfterHoursPickup,
      policies,
      vehicleRate,
      oppositeVehicleRate,
      airlineOptions,
      commitState,
      contractDetails,
      clearBillingAccount,
      status,
      hasLimitedInventoryExtra,
      extras,
      isTrueModify,
      paymentCardData,
      additionalInformation,
      reservationBillingAccount = {},
      partner_reward_program,
      collectNewPaymentCard,
      guaranteedResCardRequired,
      isDeeplink,
      destinationCurrencySelected,
      showCurrencyConversionModal,
      isSelectedInResOCISaveTime,
      onlineCheckInStatus,
      reservationFirstName,
      reservationLastName,
      showInResOCIActivatedProfile,
      isLoggedIn,
      showDoNotRentModal,
      isOpenInResAcceleratedWizardModal,
      additionalInfoDetails,
      updateCommitState,
      showSMSConsent,
      getSmsOptInEnabled,
      showEuImpliedConsent,
      isEmailUpdateRequired,
      showEmailSpecials,
      hasDefaultLoyaltyContractProfile,
      isDeeplinkWithBilling,
      isGuaranteedReservation,
      resetPaymentIDs,
      setSelectedBillingDetails,
      isSkipTheCounterCompleted,
    } = this.props;

    const { contractRequirements = [], contractAcceptsBilling, contractBillingAccount } = contractDetails || {};

    const {
      addFlightInfo,
      airlineNotListed,
      hasNoFlight,
      prepayStep,
      prepayFailed,
      isEditing,
      showUpdatedExpeditedEmailValue,
      showFormError,
    } = this.state;
    const estimatedTotal = destinationCurrencySelected
      ? vehicleRate?.price_summary?.estimated_total_payment
      : vehicleRate?.price_summary?.estimated_total_view;
    const estimatedTotalPayment = vehicleRate?.price_summary?.estimated_total_payment;
    const oppositeEstimatedTotal = destinationCurrencySelected
      ? oppositeVehicleRate?.price_summary?.estimated_total_payment
      : oppositeVehicleRate?.price_summary?.estimated_total_view;
    const { isTabletOrMobile, isDesktop } = breakpoint;
    const isForeignCurrency = estimatedTotal?.code !== estimatedTotalPayment?.code;
    const shouldShowPolicyComponent = policies.length !== 0;
    const shouldShowPreRateQuestions = contractRequirements.length !== 0;
    const isVehicleLimitedInventory = status?.indexOf(GLOBAL.LIMITED_INVENTORY_CAR_CODE) !== -1;
    const resHasLimitedInventoryItemOrVehicle = isVehicleLimitedInventory || hasLimitedInventoryExtra;
    const { billing_account_number, billing_account_type } = reservationBillingAccount;
    const isAdditionalRequired = shouldShowPreRateQuestions && contractRequirements.some((field) => field.required);

    const prepayTermsLink = (
      <Button link className='link--text' onClick={this.showPrePayTermsModal}>
        {utils.i18n('review_form_prepay_terms_and_conditions_link')}
      </Button>
    );

    const disablePrepayPaylaterButton = (isSelectedInResOCISaveTime && !showInResOCIActivatedProfile) || isEditing;

    const onDNRModalClose = () => utils.loadingRedirect(WINDOW_OBJECT_KEYS.HOME_PAGE_URL);

    // setting initial state values for additionalQuestions
    const additionalInfoInitialDetails = {};
    if (additionalInfoDetails) {
      Object.keys(additionalInfoDetails).forEach((nameKey) => {
        if (!this.state.initialFormValues?.[nameKey]) {
          additionalInfoInitialDetails[nameKey] = additionalInfoDetails[nameKey];
        }
      });
    }

    return (
      <>
        {/* editing Form key dynamically to force re-mount and reset form internal state when reservation is ready
         * this is an accepted react pattern:
         * https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
         */}
        <Form
          key={`review_form${reservationReady ? '--ready' : ''}`}
          mutators={this.finalFormMutators}
          initialValues={{ ...this.state.initialFormValues, ...additionalInfoInitialDetails }}
          subscription={{ submitFailed: true, values: true }}
          onSubmit={this.handleValidSubmit}
          onEveryInvalidSubmit={this.handleInvalidSubmit}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, form, submitFailed, values }) => {
            this.formState = form;
            return (
              <div className={cn('review', isPayLater ? 'review--pay-later' : 'review--prepay')}>
                {/* This ServiceErrors has to specify prepayStep !== CARD_FORM because in CARD_FORM there is another
              ServiceErrors inside <PrepaymentReadyDriverCard/> and having two on one page at the same time should be
              avoided */}
                {isTabletOrMobile && prepayStep !== CARD_FORM && <ServiceErrors />}

                {submitFailed && isTabletOrMobile && showFormError && <FormErrors />}

                <Title
                  type='h1'
                  className='review__title'
                  text={utils.i18n(isTrueModify ? 'modify_reservation_review_title' : 'review_page_title')}
                />

                <div className='review__content'>
                  <div className='review__aside'>
                    <div className='review__rental-details'>
                      <ReviewRentalDetails
                        resHasLimitedInventoryItemOrVehicle={resHasLimitedInventoryItemOrVehicle}
                        policies={policies}
                        extras={extras}
                        isVehicleLimitedInventory={isVehicleLimitedInventory}
                        allowLimitedInventoryIconExtra
                        isGuaranteedRes={guaranteedResCardRequired}
                        isTrueModify={isTrueModify}
                      />
                    </div>
                  </div>

                  <section className='review__main' ref={this._formSection}>
                    <form className='review__form' onSubmit={handleSubmit} autoComplete='off' noValidate>
                      {prepayStep !== CARD_FORM && (
                        <>
                          {/* Review Form Header */}
                          <div className='review__section-header'>
                            <h2 className='review__section-header__title'>
                              {isPayLater
                                ? utils.i18n('review_information_section_title')
                                : utils.i18n('review_prepayment_section_title_step_one')}
                            </h2>
                            <span className='review__section-header__legend'>
                              {utils.i18n('review_required_indication')}
                            </span>
                          </div>

                          {isDesktop && <ServiceErrors />}
                          {submitFailed && isDesktop && showFormError && <FormErrors />}

                          {/* Driver Details */}
                          <DriverDetailsCard
                            curriedUpdateCommitState={this.curriedUpdateCommitState}
                            resHasLimitedInventoryItemOrVehicle={resHasLimitedInventoryItemOrVehicle}
                            isAfterHoursPickup={isAfterHoursPickup}
                            isLoggedIn={isLoggedIn}
                            onlineCheckInStatus={onlineCheckInStatus}
                            showInResOCIActivatedProfile={showInResOCIActivatedProfile}
                            form={form}
                            formValues={values}
                            setupInitialFormState={this.setupInitialFormState}
                            initialValues={this.state.initialFormValues}
                            isSelectedInResOCISaveTime={isSelectedInResOCISaveTime}
                            reservationFirstName={reservationFirstName}
                            isEditingProfile={this.isEditingProfile}
                            isPayLater={isPayLater}
                            setAutoCommsConsent={updateCommitState}
                            showSMSConsent={showSMSConsent}
                            showEuImpliedConsent={showEuImpliedConsent}
                            getSmsOptInEnabled={getSmsOptInEnabled}
                            handleShowFormError={this.handleShowFormError}
                            isEmailUpdateRequired={isEmailUpdateRequired}
                            showUpdatedExpeditedEmailValue={showUpdatedExpeditedEmailValue}
                            showEmailSpecials={showEmailSpecials}
                          />

                          {/* Accelerated Check-In */}
                          {(!isLoggedIn || (isLoggedIn && !showInResOCIActivatedProfile)) && (
                            <FieldGroupCard hideTitle>
                              <CheckInCard
                                form={form}
                                formValues={values}
                                setupInitialFormState={this.setupInitialFormState}
                                initialValues={this.state.initialFormValues}
                                onlineCheckInStatus={onlineCheckInStatus}
                                isSelectedInResOCISaveTime={isSelectedInResOCISaveTime}
                                reservationFirstName={reservationFirstName}
                                reservationLastName={reservationLastName}
                                showInResOCIActivatedProfile={showInResOCIActivatedProfile}
                                isEditingProfile={this.isEditingProfile}
                                isPayLater={isPayLater}
                                showDoNotRentModal={showDoNotRentModal}
                                handleShowFormError={this.handleShowFormError}
                                isEmailUpdateRequired={isEmailUpdateRequired}
                                curriedUpdateCommitState={this.curriedUpdateCommitState}
                                isTrueModify={isTrueModify}
                                updateDriverProfileState={this.updateDriverProfileState}
                              />
                            </FieldGroupCard>
                          )}
                          {/* Additional Drivers */}
                          <AdditionalDrivers isTrueModify={isTrueModify} />

                          {/* Additional Info Section */}
                          {isPayLater && shouldShowPreRateQuestions && contractRequirements && (
                            <>
                              <FieldGroupCard
                                title={utils.i18n('review_page_additional_details')}
                                optionalLabel={!isAdditionalRequired}
                              >
                                <div className='review__additional-info-questions'>
                                  <AdditionalInfoQuestions
                                    contractRequirements={contractRequirements}
                                    additionalInformation={additionalInformation}
                                    initialFormValues={this.state.initialFormValues}
                                    handleAdditionalInfoUpdates={this.handleAdditionalInfoUpdates}
                                    formValues={values}
                                  />
                                </div>
                              </FieldGroupCard>
                            </>
                          )}

                          {/* Airline Details */}
                          {isAirport && (
                            <AirlineDetailsCard
                              isAfterHoursPickup={isAfterHoursPickup}
                              airlineNotListed={airlineNotListed}
                              hasNoFlight={hasNoFlight}
                              handleSetAirline={form.mutators.handleSetAirline}
                              addFlightInfo={addFlightInfo}
                              airlineOptions={airlineOptions}
                              curriedUpdateCommitState={this.curriedUpdateCommitState}
                              handleToggleFlightInfo={this.handleToggleFlightInfo}
                              isMultiTerminalAirport={isMultiTerminalAirport}
                            />
                          )}

                          <InPathVehicleUpgradeReview />

                          {/* Partner Rewards Details */}
                          {partner_reward_program && (
                            <PartnerRewardsCard
                              partner_reward_program={partner_reward_program}
                              defaultLoyaltyContract={hasDefaultLoyaltyContractProfile}
                              curriedUpdateCommitState={this.curriedUpdateCommitState}
                            />
                          )}

                          {/* Billing Account Card */}
                          {contractAcceptsBilling && (
                            <BillingAccountCard
                              contractBillingAccount={contractBillingAccount}
                              billingAccountType={billing_account_type}
                              updateBillingAccount={this.curriedUpdateCommitState('billing_account')}
                              updateBillingAccountType={this.curriedUpdateCommitState('billing_account_type')}
                              updatePaymentIds={isTrueModify ? this.curriedUpdateCommitState('payment_ids') : null}
                              clearBillingAccount={clearBillingAccount}
                              isDeeplink={isDeeplink}
                              contractAcceptsBilling={contractAcceptsBilling}
                              isDeeplinkWithBilling={isDeeplinkWithBilling}
                              isGuaranteedReservation={isGuaranteedReservation}
                              commitState={commitState}
                              isTrueModify={isTrueModify}
                              resetPaymentIDs={resetPaymentIDs}
                              form={form}
                              billingAccountInitialValue={
                                (billing_account_type === RESERVATIONS.BILLING_TYPE_CUSTOM && billing_account_number) ||
                                (isDeeplink && billing_account_number)
                              }
                              setSelectedBillingDetails={setSelectedBillingDetails}
                            />
                          )}

                          {guaranteedResCardRequired && isPayLater && <GuaranteedReservationAddCard />}

                          {/* Confirmation Tile - Pay Later */}
                          {isPayLater && (
                            <PayLaterConfirmationCard
                              estimatedTotalPayment={estimatedTotalPayment}
                              estimatedTotal={estimatedTotal}
                              oppositeEstimatedTotal={oppositeEstimatedTotal}
                              isForeignCurrency={isForeignCurrency}
                              breakpoint={breakpoint}
                              showLearnMoreModal={showCurrencyConversionModal}
                              handleTogglePrepay={() => this.handleTogglePrepay(form)}
                              isTrueModify={isTrueModify}
                              prepayTotal={paymentCardData && utils.getFormattedPrice(paymentCardData?.amount)}
                              disablePrepayPaylaterButton={disablePrepayPaylaterButton}
                              isSkipTheCounterCompleted={isSkipTheCounterCompleted}
                            />
                          )}

                          {/* Confirmation Tile - Prepay */}
                          {prepayStep === CONFIRM_PREPAY && (
                            <PrepayConfirmationCard
                              oppositeEstimatedTotal={oppositeEstimatedTotal}
                              prepayTermsLink={prepayTermsLink}
                              onlineCheckInStatus={onlineCheckInStatus}
                              isSelectedInResOCISaveTime={isSelectedInResOCISaveTime}
                              handleTogglePrepay={() => this.handleTogglePrepay(form)}
                              disablePrepayPaylaterButton={disablePrepayPaylaterButton}
                              checked={values?.payment_info?.accept_prepay_terms}
                            />
                          )}
                        </>
                      )}

                      {prepayStep === CARD_FORM && (
                        <PrepaymentReadyDriverCard
                          commitState={commitState}
                          airlineOptions={airlineOptions}
                          returnToConfirmPrepay={this.returnToConfirmPrepay}
                        />
                      )}

                      {/* Step 2 - Prepay */}
                      {!isPayLater && (
                        <PrePayPaymentSection
                          paymentCardData={paymentCardData}
                          estimatedTotal={estimatedTotal}
                          estimatedTotalPayment={estimatedTotalPayment}
                          isForeignCurrency={isForeignCurrency}
                          showLearnMoreModal={showCurrencyConversionModal}
                          oppositeEstimatedTotal={oppositeEstimatedTotal}
                          prepayTermsLink={prepayTermsLink}
                          isTrueModify={isTrueModify}
                          handleTogglePrepay={() => this.handleTogglePrepay(form)}
                          prepayStep={prepayStep}
                          collectNewPaymentCard={collectNewPaymentCard}
                          breakpoint={breakpoint}
                          onIframeSubmit={this.handlePaymentIframeSuccess}
                          onPaymentFailure={this.handlePaymentIframeFailure}
                          prepayFailed={prepayFailed}
                          form={form}
                          disablePrepayPaylaterButton={disablePrepayPaylaterButton}
                        />
                      )}
                    </form>
                  </section>
                </div>
              </div>
            );
          }}
        </Form>

        <section className='review__policies-background'>
          <div className='review'>
            <KeyRentalFacts />
            {shouldShowPolicyComponent && (
              <PoliciesComponent
                policies={policies}
                backgroundArtURL={ASSETS.RENTAL_POLICIES_PLACEHOLDER}
                backgroundArtAlt={utils.i18n('policy_background_image_alt')}
              />
            )}
          </div>
        </section>

        <PrepayTermsModal />
        <GuaranteedResIframeModal />
        {isOpenInResAcceleratedWizardModal && <InResAcceleratedCheckinWizardModal />}
        <InResAcceleratedCheckinEditModal />
        <InResAcceleratedCheckinPartialProfileModal />
        <LearnMoreAboutOCIInactiveModal />
        <SignInModal />
        <DoNotRentModal onModalClose={onDNRModalClose} />
        <Modal modalKey={MODAL.REQUIRED_MODAL_POLICY} theme={MODAL_THEMES.WHITE}>
          <GenricPolicyContentModal />
        </Modal>
      </>
    );
  }
}

Review.propTypes = {
  status: PropTypes.string,
  hasLimitedInventoryExtra: PropTypes.bool,
  extras: PropTypes.array,
  reservationReady: PropTypes.bool,
  breakpoint: PropTypes.object,
  isAirport: PropTypes.bool,
  isMultiTerminalAirport: PropTypes.bool,
  isPayLater: PropTypes.bool,
  isAfterHoursPickup: PropTypes.bool,
  commitState: PropTypes.object,
  vehicleRate: PropTypes.object,
  policies: PropTypes.array,
  airlineOptions: PropTypes.array,
  reviewCommit: PropTypes.func,
  showLearnMoreModal: PropTypes.func,
  oppositeVehicleRate: PropTypes.object,
  showPrePayTermsModal: PropTypes.func,
  setPostRateAdditionalInformation: PropTypes.func,
  contractDetails: PropTypes.shape({
    contractAcceptsBilling: PropTypes.bool,
    contractBillingAccount: PropTypes.string,
    contractRequirements: PropTypes.array,
    defaultLoyaltyContract: PropTypes.bool,
  }),
  clearBillingAccount: PropTypes.func,
  isTrueModify: PropTypes.bool,
  prepayFlopCurrentSession: PropTypes.func,
  removeInflightPaymentDetails: PropTypes.func,
  getInPathVehicleUpgradeDetails: PropTypes.func,
  paymentCardData: PropTypes.object,
  additionalInformation: PropTypes.array,
  reservationEmail: PropTypes.string,
  reservationBillingAccount: PropTypes.shape({
    billing_account_number: PropTypes.string,
    billing_account_type: PropTypes.string,
  }),
  partner_reward_program: PropTypes.object,
  updateCommitState: PropTypes.func,
  setDriveProfileState: PropTypes.func,
  airlineInfo: PropTypes.object,
  reservationPhone: PropTypes.string,
  preselectedDriverInfo: PropTypes.object,
  modalRequiredPolicies: PropTypes.array,
  collectNewPaymentCard: PropTypes.bool,
  openRequiredModal: PropTypes.func,
  guaranteedResCardRequired: PropTypes.bool,
  showCouponDoesNotApplyModal: PropTypes.func,
  selectedCarCoupons: PropTypes.array,
  isDeeplink: PropTypes.bool,
  gmaAdditionalDrivers: PropTypes.array,
  gboAdditionalDrivers: PropTypes.array,
  addAdditionalDriversOnReservation: PropTypes.func,
  showCurrencyConversionModal: PropTypes.func,
  showDoNotRentModal: PropTypes.func,
  resetPaymentIDs: PropTypes.func,
};

export default Review;
