import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { i18n } from 'utils/i18n';
import Storage from 'utils/storageManager';
import { SESSION_STORAGE } from 'constants/session';
import { breakpointSelector } from 'selectors/breakpoint';
import { skipTheCounterStart } from 'actions/skipTheCounter';
import { hasGmaErrorMessagesSelector } from 'selectors/errorSelectors';
import { isCustomPathReservationSelector } from 'selectors/customPathCheckInSelectors';
import { stepConfigSelector } from 'selectors/skipTheCounterSelectors';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import useActions from 'hooks/useActions';
import useLoadingOverlay from 'hooks/useLoadingOverlay';
import ServiceErrors from 'components/ServiceErrors';
import Button from 'components/Button';
import LinkModalGroupCustomPath from 'components/LinkModalGroupCustomPath';
import PostResLayout from 'components/PostRes/PostResLayout';
import PostResRentalInfoLine from 'components/PostRes/PostResRentalInfoLine';
import { showDiscardReservationModal } from 'actions/modal/showDiscardReservationModal';
import FormErrors from 'components/FormErrors';
import SkipTheCounterSubHeader from './SkipTheCounterSubHeader';
import SkipTheCounterLeftSide from './SkipTheCounterLeftSide';
import SkipTheCounterStepCount from './SkipTheCounterStepCount';

const { SKIP_THE_COUNTER_ORIGIN } = SESSION_STORAGE;

/**
 * Skip the Counter Flow Template Component
 * Wrapper for the skip the counter flow SPA pages that share the same layout with small differences.
 *
 * @param {object} props - React Props
 * @param {function} props.submitAction - Function called when the confirm/submit button is pressed - usually form submit
 * @param {boolean} props.withHeaderAction - Should the flow header render a confirm action button?
 * @param {boolean} props.withRequiredNote - Should the Required Fields note appear above content?
 * @param {string} props.submitButtonKey - i18n key for submit button (default to 'common_submit')
 * @param {string} props.submitDTMTrack - DTM track value for submit button
 * @param {string} props.submitFootNote - Foot note to be rendered below the submit button
 * @param {array|node} props.children - JSX Components passed onto this component to be rendered within it
 *
 * @return {JSX} SkipTheCounterFlowTemplate jsx component
 */
const SkipTheCounterFlowTemplate = ({
  submitAction,
  withRequiredNote,
  submitButtonKey,
  submitDTMTrack,
  submitFootNote,
  children,
  stepTwo,
  submitFailed,
}) => {
  const [skipTheCounterState, setSkipTheCounterState] = useState(false);

  const hasServiceErrors = useSelector(hasGmaErrorMessagesSelector);
  const isCustomPathReservation = useSelector(isCustomPathReservationSelector);
  const { currentStepCounter, stcOrigin } = useSelector(stepConfigSelector);
  const discardReservationModal = useActions(() => showDiscardReservationModal({ skipTheCounter: true }));
  const skipTheCounterStartAction = useActions(skipTheCounterStart);
  const breakpoints = useSelector(breakpointSelector);
  const isFetchingSession = useSelector((state) => isFetchingEndpointSelector(state, { endpoint: 'session/current' }));

  const { isTabletOrMobile } = breakpoints;
  useLoadingOverlay({ endpoint: 'session/current' });

  // skip_the_counter_origi n will store the value from the page which it is originated,
  // which we will be using to start the stc flow, when the user comes through browser back button to stc pages.

  const getSkipTheCounterOriginFromSession = Storage.SessionStorage.get(SKIP_THE_COUNTER_ORIGIN);
  if (!isFetchingSession && stcOrigin && stcOrigin !== getSkipTheCounterOriginFromSession) {
    Storage.SessionStorage.set(SKIP_THE_COUNTER_ORIGIN, stcOrigin);
  }

  const startActionSkipTheCounter =
    !isFetchingSession && !stcOrigin && !skipTheCounterState && getSkipTheCounterOriginFromSession;

  useEffect(() => {
    if (startActionSkipTheCounter) {
      skipTheCounterStartAction({ origin: getSkipTheCounterOriginFromSession });
      setSkipTheCounterState(!skipTheCounterState);
    }
  }, [!isFetchingSession]);

  return (
    <PostResLayout>
      <div className='skip-the-counter-template__header'>
        <div className='skip-the-counter-template__header-content'>
          <h1 className='skip-the-counter-template__heading'>
            {isCustomPathReservation ? i18n('check_in_title') : i18n('skip_the_counter_flow_title')}
          </h1>
          {isTabletOrMobile && !isCustomPathReservation && !!currentStepCounter && <SkipTheCounterStepCount />}
        </div>
        <div className='skip-the-counter-template__header-content'>
          <PostResRentalInfoLine isSTCFlow />
          {!isTabletOrMobile && !isCustomPathReservation && !!currentStepCounter && <SkipTheCounterStepCount />}
        </div>
      </div>

      <SkipTheCounterSubHeader />

      <section
        className={cn('skip-the-counter-template__wrapper', {
          'skip-the-counter-template__wrapper--with-required-note': withRequiredNote,
        })}
      >
        <SkipTheCounterLeftSide stepTwo={stepTwo} />

        <section className='skip-the-counter-template__content-area'>
          {/* the '* Indicates required field' note is hidden when service errors are displayed */}
          {!hasServiceErrors && withRequiredNote && (
            <span className='skip-the-counter-template__required-note' aria-hidden={true}>
              {i18n('check_in_required_fields_note')}
            </span>
          )}
          <ServiceErrors />
          {submitFailed && <FormErrors />}
          <article>{children}</article>
          <div
            className={cn('skip-the-counter-template__buttons-wrapper', {
              'skip-the-counter-template__buttons-wrapper--margin-bottom': submitFootNote,
            })}
          >
            {!!submitAction && (
              <div className='skip-the-counter-template__confirm-button-wrapper'>
                <Button
                  className='skip-the-counter-template__confirm-button'
                  onClick={submitAction}
                  data-dtm-track={`check_in|counter|${submitDTMTrack || 'submit'}`}
                >
                  {i18n(submitButtonKey || 'common_submit')}
                </Button>
                {submitFootNote && <p className='skip-the-counter-template__submit-footnote'>{submitFootNote}</p>}
              </div>
            )}
            <Button
              link
              linkText
              className='skip-the-counter-template__discard-button'
              onClick={discardReservationModal}
              data-dtm-track={'check_in|counter|skip'}
            >
              {i18n('common_nevermind')}
            </Button>
          </div>
        </section>
      </section>
      {isCustomPathReservation && isTabletOrMobile && <LinkModalGroupCustomPath />}
    </PostResLayout>
  );
};

SkipTheCounterFlowTemplate.propTypes = {
  submitAction: PropTypes.func,
  withHeaderAction: PropTypes.bool,
  withRequiredNote: PropTypes.bool,
  submitButtonKey: PropTypes.string,
  submitDTMTrack: PropTypes.string,
  submitFootNote: PropTypes.string,
  stepTwo: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  submitFailed: PropTypes.bool,
};

export default SkipTheCounterFlowTemplate;
