import React, { useState, useEffect, useMemo, useRef } from 'react';
import utils from 'utils';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { actions } from '@ehi/global-marketing-interface';
import { useSelector } from 'react-redux';
import { authenticatedSelector, isNonLoyaltyProfileSelector } from 'selectors/profileSelectors';
import useActions from 'hooks/useActions';
import { SCROLL } from 'constants';
import GMA_RESPONSE_CODES from 'constants/gmaResponseCodes';
import useWizardSteps from 'hooks/useWizardSteps';
import { analyticsInteraction, analyticsError, analyticsFormKey } from 'utils/analytics';
import { isObject } from 'utils/object';
import ANALYTICS from 'constants/analytics';
import MODAL from 'constants/modals';
import COUNTRY_CODE from 'constants/countryCode';
import { getMaskedString } from 'utils/profile';
import { searchProfileByDriverLicense } from 'actions/profile';
import { updateProfileInRes, onlineCheckinProfileAbort } from 'actions/checkin';
import { isLoyaltyReservationSelector } from 'selectors/reservationSelectors';
import {
  isSkipTheCounterCompletedReservationSelector,
  isCheckedInReservationSelector,
} from 'selectors/reservationFlowSelectors';

import {
  getProfileFound,
  getOnlineCheckInProfileOciEligible,
  getHasOnlineCheckInProfile,
  isUnauthUserWithLoyaltyProfileSelector,
  isUnauthPartialProfileSelector,
  isResFirstNameDifferentThanOciProfileSelector,
  onlineCheckInProfileSelector,
} from 'selectors/checkinSelectors';

import { inResAccelerateCheckInRadioButton } from 'actions/reservation/inResAccelerateCheckInRadioButton';
import { openModalWithProps } from 'actions/modal/openModalWithProps';
import InResDriverLookupForm from './InResDriverLookupForm';
import AcceleratedCheckInTile from '../AcceleratedCheckInTile';
import InResProfileForm from './InResProfileForm';
import InResOCITemplate from './InResOCITemplate';

/**
 * stepMap
 * Holds all possible configuration objects for steps in this wizard modal.;
 */
const stepMap = {
  driversLicenseStep: {
    title: utils.i18n('check_in_step_driver_lookup'),
    text: `${utils.i18n('check_in_step_driver_lookup_text_1')} ${utils.i18n('check_in_step_driver_lookup_text_2')}`,
    Form: InResDriverLookupForm,
    stepId: 'driversLicenseStep',
  },
  noMatchStep: {
    title: utils.i18n('check_in_step_profile_add_info'),
    text: `${utils.i18n('check_in_step_profile_not_found_text')} ${utils.i18n(
      'check_in_step_profile_pickup_info_text'
    )}`,
    Form: InResProfileForm,
    stepId: 'noMatchStep',
  },
  matchStep: {
    title: utils.i18n('in_res_oci_profile_confirm_info'),
    text: `${utils.i18n('check_in_step_profile_found_text')} ${utils.i18n('check_in_step_profile_pickup_info_text')}`,
    Form: InResProfileForm,
    stepId: 'matchStep',
  },
  unauthLoyaltyProfileMatchStep: {
    title: utils.i18n('in_res_oci_profile_confirm_info'),
    text: `${utils.i18n('check_in_step_profile_loyalty_found_text')} ${utils.i18n(
      'check_in_step_profile_pickup_info_text'
    )}`,
    Form: InResProfileForm,
    stepId: 'unauthLoyaltyProfileMatchStep',
  },
  // Unauth partial profile found steps
  unauthPartialProfileMatchStep: {
    analyticsKey: 'check in renter information modal',
    title: utils.i18n('check_in_step_profile_renter_info'),
    text: utils.i18n('check_in_step_partial_profile_found_sign_in_oci_in_res_description'),
    Form: InResProfileForm,
    id: 'unauthPartialProfileMatchStep',
  },
};

const getSteps = ({
  profileMatch,
  isUnauthPartialProfileLogic,
  isUnauthUserWithLoyaltyProfile,
  isResFirstNameDifferentThanOciProfile,
}) => {
  const steps = [stepMap.driversLicenseStep];
  if (isUnauthPartialProfileLogic && !isResFirstNameDifferentThanOciProfile) {
    steps.push(stepMap.unauthPartialProfileMatchStep);
    return steps;
  }

  if (isUnauthUserWithLoyaltyProfile) {
    steps.push(stepMap.unauthLoyaltyProfileMatchStep);
    return steps;
  }
  steps.push(profileMatch ? stepMap.matchStep : stepMap.noMatchStep);
  return steps;
};

const ociStepWizardList = [
  {
    value: 1,
    key: utils.i18n('trip_card_oci_step_driver_lookup'),
  },
  {
    value: 2,
    key: utils.i18n('trip_card_oci_step_verify_complete_details'),
  },
];

const CheckInCard = ({
  form,
  isSelectedInResOCISaveTime,
  onlineCheckInStatus,
  initialValues,
  setupInitialFormState,
  reservationFirstName,
  reservationLastName,
  showInResOCIActivatedProfile,
  isLoggedIn,
  isEditingProfile,
  isPayLater,
  showDoNotRentModal,
  formValues,
  handleShowFormError,
  curriedUpdateCommitState,
  isTrueModify,
  updateDriverProfileState,
}) => {
  const checkInResFormSection = useRef();

  const { ociComplete, driverProfileComplete, contactProfileComplete } = onlineCheckInStatus;

  const [selectedValue, setSelectedValue] = useState(isSelectedInResOCISaveTime);
  const [isEditingLicenseInfo, setEditingLicenseInfo] = useState(false);
  const [isEditingContactInfo, setEditingContactInfo] = useState(false);
  const searchProfileByDriverLicenseAction = useActions(searchProfileByDriverLicense);
  const cleanMessagesAction = useActions(actions.cleanMessages);
  const abortOCI = useActions(onlineCheckinProfileAbort);
  const updateProfileAction = useActions(updateProfileInRes);
  const inResAccelerateCheckInSaveTimeRadioButton = useActions(inResAccelerateCheckInRadioButton);
  const openSignInModal = useActions(() =>
    openModalWithProps(MODAL.SIGN_IN_MODAL, {
      stashAnalytics: false,
    })
  );

  const isSkippingTheCounter = useSelector(isSkipTheCounterCompletedReservationSelector);
  const ociEligible = useSelector(getOnlineCheckInProfileOciEligible);
  const isResOCIActivated = useSelector(isCheckedInReservationSelector);
  const isLoyaltyReservation = useSelector(isLoyaltyReservationSelector);
  const hasOnlineCheckInProfile = useSelector(getHasOnlineCheckInProfile);
  const isProfileFound = useSelector(getProfileFound);
  const profile = useSelector(onlineCheckInProfileSelector);
  const isUnauthUserWithLoyaltyProfile = useSelector(isUnauthUserWithLoyaltyProfileSelector);
  const isUnauthPartialProfileFound =
    isProfileFound &&
    ((driverProfileComplete && !contactProfileComplete) || (contactProfileComplete && !driverProfileComplete));

  const foundCompleteProfile = driverProfileComplete && contactProfileComplete;
  const isCompletedDriverContactInfo = foundCompleteProfile && ociComplete;
  // Check In Activated and driver details updated
  const isProfileCompleteConfirmed = isCompletedDriverContactInfo && showInResOCIActivatedProfile;
  const isProfileCompletedOciNotConfirmed = foundCompleteProfile && !ociComplete;
  // found completed profile but driver details not updated
  const hasNotConfirmedProfileCompletedInfo = isCompletedDriverContactInfo && !showInResOCIActivatedProfile;

  const isAuth = useSelector(authenticatedSelector);

  const showDriverLookupForm =
    (selectedValue !== null && selectedValue && !showInResOCIActivatedProfile) ||
    (selectedValue && (hasNotConfirmedProfileCompletedInfo || !foundCompleteProfile));

  // checking for state values if contact or driver license are in edit mode
  const isEditingProfileDetails = isEditingContactInfo || isEditingLicenseInfo;

  const isUnauthPartialProfile = useSelector(isUnauthPartialProfileSelector);
  const isUnauthPartialProfileLogic = isUnauthPartialProfile && !foundCompleteProfile;
  const isResFirstNameDifferentThanOciProfile = useSelector(isResFirstNameDifferentThanOciProfileSelector);
  const isNonLoyaltyProfileFound = useSelector(isNonLoyaltyProfileSelector);

  const inResOciSteps = useMemo(
    () =>
      getSteps({
        profileMatch: driverProfileComplete,
        isUnauthUserWithLoyaltyProfile,
        isUnauthPartialProfileLogic,
        isResFirstNameDifferentThanOciProfile,
      }),
    [driverProfileComplete]
  );

  const stepsConfig = useWizardSteps(inResOciSteps);
  const { steps, currentStep, resetSteps, addStep } = stepsConfig;

  const { Form, title, text, stepId } =
    steps[
      hasNotConfirmedProfileCompletedInfo ||
      isProfileCompleteConfirmed ||
      isProfileCompletedOciNotConfirmed ||
      isUnauthPartialProfileFound
        ? 2 - 1
        : currentStep - 1
    ] || {};
  const isLastStep =
    hasNotConfirmedProfileCompletedInfo || isProfileCompletedOciNotConfirmed || isUnauthPartialProfileFound
      ? true
      : currentStep === ociStepWizardList.length;

  let nextButtonLabel = utils.i18n('common_next');
  if (
    (isLoyaltyReservation && showInResOCIActivatedProfile) ||
    showInResOCIActivatedProfile ||
    (isLastStep && (!foundCompleteProfile || (foundCompleteProfile && isEditingProfileDetails)))
  ) {
    nextButtonLabel = utils.i18n('common_save');
  } else if (isLastStep && foundCompleteProfile && !isEditingProfileDetails) {
    nextButtonLabel = utils.i18n('common_confirm');
  }

  const scrollToTop = () => {
    if (!window.pageXOffset) {
      // scroll needs to be in a setTimeout to wait for the invalid submit to render the error
      const currentTarget = checkInResFormSection.current;
      if (currentTarget) {
        setTimeout(() => utils.dom.scrollPage(0, currentTarget.offsetTop - SCROLL.HEADER_CLEARANCE), 200);
      }
    }
  };

  // resets steps when profile data comes in
  useEffect(() => {
    resetSteps(inResOciSteps);
  }, [inResOciSteps]);

  // function to handle analytics for Checkin inRes and Reserve page
  const handleAnalyticsForCheckInRes = (action, key = null, value = null) => {
    const data = { key, value };
    // Analytics for Profile Lookup
    if (!isLastStep && !driverProfileComplete && action === ANALYTICS.OPEN) {
      // Clicked on Radio Yes
      data.key = ANALYTICS.PROFILE_LOOKUP;
      data.value = action;
    } else if (!isLastStep && !driverProfileComplete && action === ANALYTICS.NEVERMIND) {
      data.key = ANALYTICS.PROFILE_LOOKUP;
      data.value = ANALYTICS.NEVERMIND;
    } else if (!isLastStep && !driverProfileComplete && action === utils.i18n('common_next')) {
      // Clicked on Next
      data.key = ANALYTICS.PROFILE_LOOKUP;
      data.value = ANALYTICS.NEXT;
    } else if (isLastStep && !foundCompleteProfile && action === ANALYTICS.NEVERMIND) {
      data.key = ANALYTICS.PROFILE_NOT_FOUND;
      data.value = action;
    } else if (isLastStep && !foundCompleteProfile && action === utils.i18n('common_save')) {
      // Clicked Save (If DL not found and new data save)
      data.key = ANALYTICS.PROFILE_NOT_FOUND;
      data.value = action;
    } else if (isLastStep && hasNotConfirmedProfileCompletedInfo && action === ANALYTICS.NEVERMIND) {
      // Analytics for Profile Found
      data.key = ANALYTICS.PROFILE_FOUND;
      data.value = action;
    } else if (
      isLastStep &&
      hasNotConfirmedProfileCompletedInfo &&
      action === utils.i18n('common_confirm') &&
      currentStep === 2
    ) {
      // Clicked on Next(DL Profile found)
      data.key = ANALYTICS.PROFILE_FOUND;
      data.value = ANALYTICS.OPEN;
    } else if (
      isLastStep &&
      hasNotConfirmedProfileCompletedInfo &&
      action === utils.i18n('common_confirm') &&
      !currentStep === 2
    ) {
      data.key = ANALYTICS.PROFILE_FOUND;
      data.value = action;
    } else if (hasNotConfirmedProfileCompletedInfo && currentStep === 2 && action === utils.i18n('common_confirm')) {
      data.key = ANALYTICS.PROFILE_FOUND;
      data.value = action;
    } else if (
      isLastStep &&
      foundCompleteProfile &&
      !isEditingContactInfo &&
      !isEditingLicenseInfo &&
      action === utils.i18n('common_save')
    ) {
      // Click on Confirm(DL profile found and checkin complete [Great! Thanks....])
      data.key = ANALYTICS.CHECK_IN_COMPLETE;
      data.value = action;
    } else if (
      (isProfileCompleteConfirmed || foundCompleteProfile) &&
      (isEditingContactInfo || isEditingLicenseInfo) &&
      action === utils.i18n('common_save')
    ) {
      // Edit DL details and clicked save
      data.key = isEditingContactInfo ? ANALYTICS.EDIT_CONTACT_INFORMATION : ANALYTICS.EDIT_DRIVER_LICENSE;
      data.value = action;
    } else if (isProfileCompleteConfirmed && action === ANALYTICS.OPEN) {
      data.key = ANALYTICS.CHECK_IN_COMPLETE;
      data.value = action;
    } else if (
      (isProfileCompleteConfirmed || foundCompleteProfile) &&
      isEditingContactInfo &&
      action === ANALYTICS.CANCEL
    ) {
      data.key = ANALYTICS.EDIT_CONTACT_INFORMATION;
      data.value = action;
    } else if (
      (isProfileCompleteConfirmed || foundCompleteProfile) &&
      isEditingLicenseInfo &&
      action === ANALYTICS.CANCEL
    ) {
      data.key = ANALYTICS.EDIT_DRIVER_LICENSE;
      data.value = action;
    }

    return data.key && analyticsInteraction(ANALYTICS.SUB_PAGE, data.key, data.value.toLowerCase());
  };

  useEffect(() => {
    if (driverProfileComplete || showInResOCIActivatedProfile) {
      handleAnalyticsForCheckInRes(nextButtonLabel);
    }
  }, [driverProfileComplete, showInResOCIActivatedProfile]);

  useEffect(() => {
    setSelectedValue(isSelectedInResOCISaveTime);
  }, [isSelectedInResOCISaveTime]);

  const handleEditProfile = (value) => {
    if (value === 'driver' || value === 'contact') {
      const licenseEditing = value === 'driver';
      const contactEditing = value === 'contact';
      const isEditing = licenseEditing || contactEditing;
      setEditingLicenseInfo(licenseEditing);
      setEditingContactInfo(contactEditing);
      isEditingProfile(isEditing);
    } else {
      setEditingLicenseInfo(value);
      setEditingContactInfo(value);
      isEditingProfile(value);
    }
  };

  const onSubmitSuccess = (formData) => {
    if (
      (!showInResOCIActivatedProfile && foundCompleteProfile) ||
      !foundCompleteProfile ||
      isEditingContactInfo ||
      isEditingLicenseInfo
    ) {
      setupInitialFormState?.(formData);
      if (isLastStep || isEditingContactInfo || isEditingLicenseInfo) {
        scrollToTop();
        const formState = form.getState();
        formState?.submitFailed && form.reset({ submitFailed: false });
      }
    }
    addStep();
    // AWR-8350 - Per QA, the renter is already expedited, they're just potentially editing their contact info.
    // which doesn't make sense to fire here analyticsRenterExpedited custom event
    // if (isFinalStep) {
    //   // dispatches analyticsRenterExpedited event
    //   utils.analytics.dispatchEventWithDOM('RenterExpedited');
    // }
    cleanMessagesAction();

    isEditingProfile(false);
  };

  const handleSortUpdate = (inputValue) => {
    const value = inputValue === 'yes';
    inResAccelerateCheckInSaveTimeRadioButton(value);
    setSelectedValue(value);
    setEditingContactInfo(false);
    setEditingLicenseInfo(false);
    form.change('sortBy', inputValue);

    inputValue.toLowerCase() === 'yes' && handleAnalyticsForCheckInRes(ANALYTICS.OPEN);
  };

  const handleNeverMindStepUpdates = () => {
    resetSteps();
    handleSortUpdate('no');
    scrollToTop();
    cleanMessagesAction();
    handleAnalyticsForCheckInRes(ANALYTICS.NEVERMIND);
  };

  const handleNeverMind = (buttonLabel) => {
    if (
      showInResOCIActivatedProfile ||
      (hasOnlineCheckInProfile && buttonLabel === utils.i18n('common_cancel') && !showInResOCIActivatedProfile)
    ) {
      handleEditProfile(false);
      scrollToTop();
      cleanMessagesAction();
      const action = buttonLabel === utils.i18n('common_cancel') ? ANALYTICS.CANCEL : ANALYTICS.NEVERMIND;
      handleAnalyticsForCheckInRes(action);
    } else if (
      hasOnlineCheckInProfile &&
      buttonLabel !== utils.i18n('common_cancel') &&
      !showInResOCIActivatedProfile
    ) {
      abortOCI();
      handleNeverMindStepUpdates();
    } else {
      handleNeverMindStepUpdates();
    }
  };

  const onReset = () => {
    resetSteps();
  };

  // on submit form When errors occurs, saves the analytics error object into _analytics.errors.
  const handleAnalyticsErrorsForCheckInRes = (errors) => {
    // Loop over each form section and each error inside.
    Object.entries(errors).forEach(([sectionErrorKey, sectionErrorValue]) => {
      if (isObject(sectionErrorValue)) {
        Object.entries(sectionErrorValue).forEach(([formErrorKey, formErrorValue]) =>
          analyticsError(
            window.location.href,
            ANALYTICS.FE_FORM_ERROR(analyticsFormKey(`${sectionErrorKey}_${formErrorKey}`)),
            formErrorValue
          )
        );
        if (sectionErrorValue?.phone_number) {
          analyticsError(window.location.href, ANALYTICS.FE_PHONE, ANALYTICS.PHONE_INVALID);
        }
      } else {
        analyticsError(
          window.location.href,
          `${ANALYTICS.FE_FORM_ERROR(analyticsFormKey(sectionErrorKey))}`,
          sectionErrorValue
        );
      }
    });
  };

  // handling Profile details submission
  const handleSubmit = () => {
    handleAnalyticsForCheckInRes(nextButtonLabel);
    const formState = form.getState();
    const { errors, values } = formState;
    const newValues = { ...values };
    const { license_profile } = profile;

    const phoneData = utils.profile.contactInfoPayloadParser(values);
    newValues.contact_info.phone_set = phoneData.phones;

    // .CO.UK only - In the OCI flow when a NL driver profile is found during Driver Lookup,
    // sending the 'issuing authority' value as masked in the Update Profile call to GBO.
    if (
      newValues?.license_info?.issuing_authority?.length &&
      license_profile?.country_code === COUNTRY_CODE.GB &&
      isNonLoyaltyProfileFound &&
      isUnauthPartialProfileFound
    ) {
      newValues.license_info.issuing_authority = getMaskedString(newValues.license_info.issuing_authority);
    }

    if (
      errors?.address_info ||
      errors?.contact_info ||
      errors?.license_expiration_date ||
      errors?.license_info ||
      errors?.driver_info
    ) {
      handleAnalyticsErrorsForCheckInRes(errors);
      handleShowFormError();
      return form.submit();
    }

    const formData =
      !showInResOCIActivatedProfile &&
      isProfileFound &&
      foundCompleteProfile &&
      (!isEditingContactInfo || !isEditingLicenseInfo)
        ? {}
        : newValues;

    return updateProfileAction(formData, values?.driver_info.first_name || reservationFirstName).then((resp) => {
      if (!resp.messages) {
        if (isAuth && !utils.fieldValidation.isMasked(newValues.contact_info.email)) {
          curriedUpdateCommitState('email_address')(newValues.contact_info.email);
        }
        onSubmitSuccess?.();
        isEditingLicenseInfo && setEditingLicenseInfo(false);
        isEditingContactInfo && setEditingContactInfo(false);
        inResAccelerateCheckInSaveTimeRadioButton(null);
        form.change('sortBy', null);
        utils.phoneInput.selectedCountryDetails.remove();
      }
    });
  };

  // Handling Driver lookup details
  const handleDriverDetails = () => {
    handleAnalyticsForCheckInRes(nextButtonLabel);
    const formState = form.getState();
    const { errors, values } = formState;
    if (
      Object.keys(errors).some((key) =>
        ['country', 'country_subdivision', 'driver_license_number', 'driver_info'].includes(key)
      ) ||
      errors?.address_info ||
      errors?.contact_info ||
      errors?.license_expiration_date
    ) {
      handleAnalyticsErrorsForCheckInRes(errors);
      handleShowFormError();
      return form.submit();
    }
    const isOci = true;
    const payload = {
      country: values?.country,
      country_subdivision: values?.country_subdivision,
      issuing_authority: values?.issuing_authority,
      driver_license_number: values?.driver_license_number,
      last_name: values?.driver_info?.last_name || values?.last_name || reservationLastName,
    };

    return searchProfileByDriverLicenseAction(payload, {
      isOci,
    }).then((resp) => {
      if (!resp.messages) {
        // No error messages returned, proceed to next step;
        onSubmitSuccess(formValues);
      } else {
        resp.messages.some((msg) => msg.code === GMA_RESPONSE_CODES.GMA_PROFILE_DO_NOT_RENT) && showDoNotRentModal();
      }
    });
  };

  const renderOCIForm = () => (
    <InResOCITemplate
      form={form}
      currentStep={currentStep}
      isEditingLicenseInfo={isEditingLicenseInfo}
      isEditingContactInfo={isEditingContactInfo}
      isLastStep={isLastStep}
      hideFooter={isProfileCompleteConfirmed && !isEditingProfileDetails}
      isLoggedIn={isLoggedIn}
      showInResOCIActivatedProfile={showInResOCIActivatedProfile}
      nextButton={{
        label: nextButtonLabel,
        action: () => {
          (ociComplete && contactProfileComplete) ||
          (!ociComplete && foundCompleteProfile) ||
          isUnauthPartialProfileFound ||
          currentStep === 2
            ? handleSubmit()
            : handleDriverDetails();
        },
      }}
      handleNeverMind={handleNeverMind}
    >
      {isUnauthUserWithLoyaltyProfile && isLastStep && title && text && (
        <p id={`${stepId}_desc`} className='in-res-oci-modal__inres-description'>
          {text}
        </p>
      )}
      <Form
        isLastStep={isLastStep}
        initialValues={initialValues}
        form={form}
        onlineCheckInStatus={onlineCheckInStatus}
        onSubmitSuccess={onSubmitSuccess}
        resetSteps={onReset}
        setEditingLicenseInfo={handleEditProfile}
        setEditingContactInfo={handleEditProfile}
        editingLicense={isEditingLicenseInfo}
        editingContactInfo={isEditingContactInfo}
        ociEligible={ociEligible}
        isResOCIActivated={isResOCIActivated}
        isSkippingTheCounter={isSkippingTheCounter}
        isProfileCompleteConfirmed={isProfileCompleteConfirmed}
        hasNotConfirmedProfileCompletedInfo={hasNotConfirmedProfileCompletedInfo}
        updateInitialState={setupInitialFormState}
        updateDriverProfileState={updateDriverProfileState}
      />
    </InResOCITemplate>
  );

  useEffect(() => {
    if (isUnauthPartialProfileLogic && !isResFirstNameDifferentThanOciProfile && !isTrueModify) {
      openSignInModal();
      abortOCI();
      handleNeverMindStepUpdates();
    }
  }, [isUnauthPartialProfileLogic, isResFirstNameDifferentThanOciProfile]);

  return (
    <section ref={checkInResFormSection}>
      <AcceleratedCheckInTile
        handleEditProfile={handleEditProfile}
        handleSortUpdate={handleSortUpdate}
        isSelectedInResOCISaveTime={isSelectedInResOCISaveTime}
        showInResOCIActivatedProfile={showInResOCIActivatedProfile}
        ociComplete={ociComplete}
        isPayLater={isPayLater}
        isEditingProfileDetails={isEditingProfileDetails}
        isOciFormOpen={showDriverLookupForm && !showInResOCIActivatedProfile}
      />
      {showInResOCIActivatedProfile && isEditingProfileDetails && renderOCIForm()}
      {showDriverLookupForm && !showInResOCIActivatedProfile && (
        <React.Fragment>
          <ol className='in-res-oci-save-time__step-header-wrapper'>
            {ociStepWizardList.map(({ value, key }) => (
              <li
                key={value}
                className={cn('step-wizard-item', {
                  'current-item':
                    hasNotConfirmedProfileCompletedInfo ||
                    isProfileCompletedOciNotConfirmed ||
                    isUnauthPartialProfileFound
                      ? value === 2
                      : currentStep === value,
                })}
              >
                <span className={cn('in-res-oci-save-time__step-wizard-indicator', 'progress-count')}>{value}</span>
                <span className='title-text'>{key}</span>
              </li>
            ))}
          </ol>
          {renderOCIForm()}
        </React.Fragment>
      )}
    </section>
  );
};

CheckInCard.propTypes = {
  handleShowFormError: PropTypes.func,
};

export default CheckInCard;
