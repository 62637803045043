import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import utils from 'utils';
import ANALYTICS from 'constants/analytics';
import useActions from 'hooks/useActions';
import { setShowPartnersLocations } from 'actions/uiState';
import { composeFilters, skipAnalyticsForLocationTypeFilter } from 'actions/locationFinder';
import Button from 'components/Button';
import './styles/toggle-partner-locations.scss';

/**
 * This component allows users to toggle the visibility of partner
 * locations in the location search page.
 *
 * @param {object} props - React props
 * @param {boolean} props.isShowingPartners - Flag if partners are being displayed
 * @param {function} props.resetTimeStamp - Callback that resets the map timestamp and forces a re-render
 */
const TogglePartnerLocations = ({ isMinimalist, isShowingPartners, resetTimeStamp, isResFlow }) => {
  const setShowPartnerLocations = useActions(() => setShowPartnersLocations(!isShowingPartners));
  const updateLocationFinderFilters = useActions(() => composeFilters('location_finder', isResFlow));
  const skipAnalyticsLocationFinder = useActions((bool) => skipAnalyticsForLocationTypeFilter(bool));

  const togglePartnerLocations = useCallback(() => {
    skipAnalyticsLocationFinder(true); // Used to remove extra analytics call on button click
    setShowPartnerLocations();
    updateLocationFinderFilters();
    resetTimeStamp?.();
  }, [setShowPartnerLocations, skipAnalyticsLocationFinder, updateLocationFinderFilters, resetTimeStamp]);

  const actionContentKey = isShowingPartners
    ? 'cross_sell_hide_partner_locations'
    : 'cross_sell_view_partner_locations';

  return (
    <div className={cn('toggle-partner-locations', { 'toggle-partner-locations--minimalist': isMinimalist })}>
      {!isMinimalist && (
        <>
          <h4 className='toggle-partner-locations__title'>
            {isShowingPartners
              ? utils.i18n('cross_sell_showing_partner')
              : utils.i18n('cross_sell_need_something_closer')}
          </h4>
          <p className='toggle-partner-locations__content'>
            {isShowingPartners ? utils.i18n('cross_sell_check_partners') : utils.i18n('cross_sell_we_found_locations')}
          </p>
        </>
      )}
      <Button
        link
        data-track-dtm={
          isMinimalist
            ? utils.analytics.dtm(ANALYTICS.LOCATION_PAGE, ANALYTICS.SOLD_OUT, ANALYTICS.VIEW_PARTNER_LOCATIONS)
            : utils.analytics.dtm(ANALYTICS.STORE_FINDER, ANALYTICS.LOCATIONS, ANALYTICS.VIEW_PARTNER_LOCATIONS)
        }
        onClick={togglePartnerLocations}
        className={cn('toggle-partner-locations__action', {
          'toggle-partner-locations__action--hide': isShowingPartners,
          'toggle-partner-locations__action--minimalist': isMinimalist,
        })}
      >
        {utils.i18n(actionContentKey)}
      </Button>
    </div>
  );
};

TogglePartnerLocations.propTypes = {
  isMinimalist: PropTypes.bool,
  isShowingPartners: PropTypes.bool,
  resetTimeStamp: PropTypes.func,
  isResFlow: PropTypes.bool,
};

export default TogglePartnerLocations;
