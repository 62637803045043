import { MapTo } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';
import PrepayTerms from './PrepayTermsContainer';

const PrepayTermsEditableConfig = {
  emptyLabel: 'Prepay Terms',
  isEmpty: (props) => !props,
};

export default MapTo('aweb/components/content/prepay-terms')(spreadModelProps(PrepayTerms), PrepayTermsEditableConfig);
