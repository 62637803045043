import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const LoadingWidget = ({ darkTheme = false, animatedComponentLoading = false }) => (
  <div
    className={cn('loading-widget', {
      'loading-widget--dark-theme': darkTheme,
      'loading-widget--animated': animatedComponentLoading,
    })}
  />
);

LoadingWidget.propTypes = {
  darkTheme: PropTypes.bool,
};

export default LoadingWidget;
