import React, { useEffect, useState } from 'react';
import { utils as globalUtils, actions } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import { capitalize } from 'utils/string';
import useActions from 'hooks/useActions';
import { rentalRetrieveAndConfirm, modifyRental } from 'actions/modifyRental';
import PostResLayout from 'components/PostRes/PostResLayout';
import Title from 'components/Title';
import RentalTripCard from 'components/RentalFlow/RentalTripCard';
import ServiceErrors from 'components/ServiceErrors';
import LoadingWidget from 'components/LoadingWidget';
import RentalNotEligibleForModificationsModal from 'components/modals/RentalNotEligibleForModificationsModal';

const ConfirmReturn = ({ firstName, returnLocation, returnTime, ticketNumber, showRentalNotEligibleModal }) => {
  const rentalRetrieveAndConfirmAction = useActions(rentalRetrieveAndConfirm);
  const modifyRentalAction = useActions(modifyRental);
  const cleanMessagesAction = useActions(actions.cleanMessages);

  const [isLoading, setIsLoading] = useState(true);

  const formattedReturnTime = returnTime ? globalUtils.getDateTimeObjFromTs(returnTime).format('llll') : false;

  useEffect(() => {
    // clean gmi message actions
    cleanMessagesAction();

    // hide overlay once formattedReturnTime | returnTime once the data is ready
    if (formattedReturnTime) {
      setIsLoading(false);
    }

    // auto disable loader after few seconds, if no data found.
    if (isLoading && !formattedReturnTime) {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }

    return () => {
      setIsLoading(false); // overlay on unmount
    };
  }, [cleanMessagesAction, returnTime, formattedReturnTime]);

  return (
    <React.Fragment>
      <PostResLayout noMinHeight>
        <>
          <ServiceErrors />
          <div className='confirm-return-header'>
            <Title
              type='h1'
              className='rental-updated__title'
              text={i18n('confirm_return_heading', [capitalize(firstName)])}
            />
            <p className='rental-updated__title-subheading-title-copy'>{i18n('confirm_return_heading_details')}</p>
          </div>

          <div className='theme--dark-blue'>
            {isLoading ? (
              <LoadingWidget animatedComponentLoading={true} />
            ) : (
              <RentalTripCard
                rentalRetrieveAndConfirmAction={rentalRetrieveAndConfirmAction}
                modifyRentalAction={modifyRentalAction}
                ticketNumber={ticketNumber}
                returnLocation={returnLocation}
                dateOfRentInteraction={formattedReturnTime}
                showRentalNotEligibleModal={showRentalNotEligibleModal}
              />
            )}
          </div>
        </>
      </PostResLayout>
      <RentalNotEligibleForModificationsModal ticketNumber={ticketNumber} />
    </React.Fragment>
  );
};

export default ConfirmReturn;
