import React from 'react';
import { i18n } from 'utils/i18n';
import FormattedPrice from 'components/FormattedPrice';

const BundlePrice = ({ priceAmount, title }) => (
  <>
    {i18n(title, [<FormattedPrice key='0'>{priceAmount}</FormattedPrice>], {
      jsx: true,
    })}
  </>
);

export default BundlePrice;
