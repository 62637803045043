import React, { Component } from 'react';
import utils from 'utils';
import Picture from 'components/Picture';
import LoadingWidget from 'components/LoadingWidget';
import { propTypes } from './deeplinkPropTypes';
import WINDOW_OBJECT_KEYS from "../../constants/windowObjectKeys";

const NO_REDIRECT = 'noredirect';
const GO_TO = 'go_to';

class Deeplink extends Component {
  static propTypes = propTypes;

  componentDidMount() {
    const { deeplinkIntoReservationFlow } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const noRedirect = searchParams.has(NO_REDIRECT);
    const goTo = searchParams.get(GO_TO);

    // If we have a go_to param, skip all other deep link functionality.
    if (goTo) {
        // Get redirect URL for the go_to value
        const redirectUrl = utils.config.getRedirectUrl(goTo);

        // If there is no redirect found or redirects are prevented by noRedirect, log a warning to the console, then redirect to home
        if (noRedirect) {
            // eslint-disable-next-line no-console
            console.log(`Would have redirected to ${goTo}: ${redirectUrl}`);
        } else {
            if (!redirectUrl) {
                // eslint-disable-next-line no-console
                console.warn(`Redirect url not found for go_to value ${goTo}`);
                utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.HOME_PAGE_URL);
            }

            window.location.assign(redirectUrl);
        }
    } else {
        const options = {
            redirect: !noRedirect
        };

        deeplinkIntoReservationFlow && location.search && deeplinkIntoReservationFlow(location.search, options);
    }
  }

  render() {
    return (
      <div className='deep-link theme--light'>
        <Picture
          className='deep-link__logo'
          src={utils.config.getSiteLogoImage()}
          alt={utils.i18n('progress_bar_alamo_image_alt')}
        />
        <h1 className='deep-link__heading'>{utils.i18n('deeplink_header')}</h1>
        <p className='deep-link__copy'>{utils.i18n('deeplink_copy')}</p>
        <LoadingWidget />
      </div>
    );
  }
}

export default Deeplink;
